import { getFixedClassRef } from "gather-common/dist/src/public/getFixedClassRef"
import { ga } from "gather-common/dist/src/public/mobx/decorators"
import { just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { NonEmptyArray } from "gather-common-including-video/dist/src/public/tsUtils"
import { BaseValueObject } from "gather-state-sync/dist/src/public/BaseValueObject"
import { s, SchemaInfer } from "../framework/schema/schema"
import { Map } from "../models/Map"
import { TILE_SIZE } from "../models/mapEntity/constants"
import { Dimensions } from "./Dimensions"
import { Direction, MoveDirection } from "./Direction"

export const positionSchema = s.object({
  x: s.number(),
  y: s.number(),
})

type PositionData = SchemaInfer<typeof positionSchema>
export type PositionHash = string
export type PositionsPairHash = string

const EPSILON = 0.0001

@ga.observableClass
export class Position extends BaseValueObject(positionSchema.modelZod) {
  constructor(data: PositionData)
  constructor(x: number, y: number)
  constructor(xOrData: number | PositionData, y?: number) {
    super(typeof xOrData === "number" ? { x: xOrData, y: just(y) } : xOrData)
  }

  static isEqual(a: Readonly<Position>, b: Readonly<Position>) {
    return Math.abs(a.x - b.x) < EPSILON && Math.abs(a.y - b.y) < EPSILON
  }

  static fromHash(hash: PositionHash): Position {
    const parsedNumbers = hash.split("/").map(Number)
    const [x, y] = parsedNumbers
    if (parsedNumbers.length !== 2 || x === undefined || isNaN(x) || y === undefined || isNaN(y))
      throw new Error(`Invalid Position hash: ${hash}`)

    const Position_ = getFixedClassRef(this, Position)
    return new Position_(x, y)
  }

  hash(): PositionHash {
    return getFixedClassRef(this, Position).hashOf(this)
  }

  hashPair(other: Readonly<Position>): PositionsPairHash {
    return getFixedClassRef(this, Position).pairHashes(this.hash(), other.hash())
  }

  static hashOf(args: { x: number; y: number }): PositionHash {
    return `${args.x}/${args.y}`
  }

  static pairHashes(hash1: PositionHash, hash2: PositionHash): PositionsPairHash {
    return `${hash1}:${hash2}`
  }

  static manhattanDistance(a: Readonly<Position>, b: Readonly<Position>) {
    return Math.abs(a.x - b.x) + Math.abs(a.y - b.y)
  }

  static medianCenter(positions: NonEmptyArray<Readonly<Position>>) {
    const xCoordinates = positions.map((p) => p.x).sort((a, b) => a - b)
    const yCoordinates = positions.map((p) => p.y).sort((a, b) => a - b)

    // Get the median of the x and y coordinates
    const medianX = xCoordinates[Math.floor(xCoordinates.length / 2)]
    const medianY = yCoordinates[Math.floor(yCoordinates.length / 2)]

    if (medianX === undefined || medianY === undefined)
      throw new Error("Could not calculate median center")

    const Position_ = getFixedClassRef(this, Position)
    return new Position_(medianX, medianY)
  }

  static centroid(positions: Readonly<Position>[]) {
    const Position_ = getFixedClassRef(this, Position)
    return positions
      .reduce(
        (accumulatedPositions, position) => accumulatedPositions.add(position),
        new Position_(0, 0),
      )
      .divide(positions.length)
  }

  euclideanDistance(b: Readonly<Position>) {
    return Math.sqrt(this.euclideanDistanceSqr(b))
  }

  euclideanDistanceSqr(b: Readonly<Position>) {
    return Math.pow(this.x - b.x, 2) + Math.pow(this.y - b.y, 2)
  }

  manhattanDistance(b: Readonly<Position>) {
    return Math.abs(this.x - b.x) + Math.abs(this.y - b.y)
  }

  /**
   * Manhattan area is the area of the rectangle between 2 given Positions.
   *
   * Returns the largest manhattan area between `start` and any goal in `goals`.
   */
  manhattanArea(positions: NonEmptyArray<Readonly<Position>>) {
    return Math.max(
      ...positions.map((position) => Math.abs((position.x - this.x) * (position.y - this.y))),
    )
  }

  equals(other: Readonly<Position>) {
    return getFixedClassRef(this, Position).isEqual(this, other)
  }

  /**
   * Returns true if the position is within the area defined by the given position and dimensions.
   * Note that the low boundary is inclusive, while the high boundary is exclusive. This is because
   * the "dimensions" represent the total width and height (including the starting position).
   */
  isWithin(position: Readonly<Position>, dimensions: Dimensions) {
    return (
      this.x >= position.x &&
      this.x < position.x + dimensions.width &&
      this.y >= position.y &&
      this.y < position.y + dimensions.height
    )
  }

  isBlockedBy(map: Map, previousPos: Readonly<Position> | null) {
    if (
      this.isWithin(map.baseArea.absolutePosition, map.baseArea.dimensionsInTiles) &&
      !map.collisions.blockedAtPosition(this) &&
      (!previousPos || map.collisions.canPassThrough(previousPos, this))
    ) {
      return false
    }

    return true
  }

  move(dx: number, dy: number): Position {
    return this.updatedCopy(this.x + dx, this.y + dy)
  }

  add(other: Readonly<Position>): Position {
    return this.move(other.x, other.y)
  }

  multiply(factor: number): Position {
    return this.updatedCopy(this.x * factor, this.y * factor)
  }

  divide(divisor: number): Position {
    if (divisor === 0) throw new Error("Cannot divide by zero")

    return this.updatedCopy(this.x / divisor, this.y / divisor)
  }

  round(): Position {
    return this.updatedCopy(Math.round(this.x), Math.round(this.y))
  }

  moveInDirection(dir: Direction | null, dist: number = 1): Position {
    switch (dir?.value) {
      case MoveDirection.Left:
        return this.move(-dist, 0)
      case MoveDirection.Right:
        return this.move(dist, 0)
      case MoveDirection.Up:
        return this.move(0, -dist)
      case MoveDirection.Down:
        return this.move(0, dist)
      default:
        return this
    }
  }

  // Maps an X/Y coordinate to a one-dimensional array, given a predefined grid width.
  // (If you're trying to reference world positions, you should use the map's width.)
  toRowMajorIndex(width: number): number {
    return this.y * width + this.x
  }

  getNeighbors(includeDiagonalNeighbors = false): Position[] {
    const Position_ = getFixedClassRef(this, Position)
    const result = [
      new Position_(this.x - 1, this.y),
      new Position_(this.x + 1, this.y),
      new Position_(this.x, this.y + 1),
      new Position_(this.x, this.y - 1),
    ]
    if (includeDiagonalNeighbors) {
      result.push(
        new Position_(this.x - 1, this.y - 1),
        new Position_(this.x - 1, this.y + 1),
        new Position_(this.x + 1, this.y + 1),
        new Position_(this.x + 1, this.y - 1),
      )
    }
    return result
  }

  /**
   * Returns the Direction needed to move from the current position to `targetPos`,
   * or `null` if it's not possible to move directly to `targetPos`.
   */
  positionToDirection(targetPos: Readonly<Position>): Direction | null {
    if (Math.abs(targetPos.x - this.x) < Number.EPSILON) {
      if (targetPos.y < this.y) {
        return new Direction(MoveDirection.Up)
      } else if (targetPos.y > this.y) {
        return new Direction(MoveDirection.Down)
      }
    } else if (Math.abs(targetPos.y - this.y) < Number.EPSILON) {
      if (targetPos.x < this.x) {
        return new Direction(MoveDirection.Left)
      } else if (targetPos.x > this.x) {
        return new Direction(MoveDirection.Right)
      }
    }
    return null
  }

  positionToDirectionIgnoringAxis(targetPos: Readonly<Position>): Direction | null {
    const deltaX = targetPos.x - this.x
    const deltaY = targetPos.y - this.y

    if (deltaX === 0 && deltaY === 0) return null

    return Math.abs(deltaX) >= Math.abs(deltaY)
      ? new Direction(deltaX > 0 ? MoveDirection.Right : MoveDirection.Left)
      : new Direction(deltaY > 0 ? MoveDirection.Down : MoveDirection.Up)
  }

  static floor(position: Position): Position {
    const Position_ = getFixedClassRef(this, Position)
    return new Position_(Math.floor(position.x), Math.floor(position.y))
  }

  static fromPixels(x: number, y: number) {
    const Position_ = getFixedClassRef(this, Position)
    return new Position_(x / TILE_SIZE, y / TILE_SIZE)
  }

  fromPixels() {
    return getFixedClassRef(this, Position).fromPixels(this.x, this.y)
  }

  static toPixels(x: number, y: number) {
    const Position_ = getFixedClassRef(this, Position)
    return new Position_(x * TILE_SIZE, y * TILE_SIZE)
  }

  toPixels() {
    return getFixedClassRef(this, Position).toPixels(this.x, this.y)
  }

  copy(data: PositionData): Position {
    this.x = data.x
    this.y = data.y
    return this
  }

  private updatedCopy(x: number, y: number): Position {
    const Position_ = getFixedClassRef(this, Position)
    return new Position_(x, y)
  }
}
