import { Box, Button, Divider, TextField, Typography } from "@mui/material"
import MultipleSelectChip from "components/inputs/MultipleSelectChip"
import { Formik } from "formik"
import React, { FC } from "react"
import { toast } from "react-hot-toast"
import * as Yup from "yup"

import { guaranteedError } from "gather-common/dist/src/public/utils"
import { AdminUser } from "gather-http-common/dist/src/public/superAdminUsers"
import { AdminRolePrisma } from "gather-prisma-types/dist/src/public/client"
import * as AdminUsersAPI from "../../../../api/superAdminUsers"

interface Props {
  onCancel: () => void
  onAddComplete: () => void
  onCreate: (newUser: AdminUser) => void
}

const NewUserForm: FC<Props> = ({ onCancel, onAddComplete, onCreate }) => (
  <Formik
    initialValues={{
      email: "",
      roles: [],
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Email is required")
        .test(
          "email-includes-gather-town",
          "Email must include @gather.town",
          (value) => value !== undefined && value.includes("@gather.town"),
        ),
      roles: Yup.array(Yup.string()).required("Must select at least one role."),
    })}
    onSubmit={async ({ email, roles }, { resetForm, setStatus, setSubmitting }) => {
      try {
        const newUser = await AdminUsersAPI.createSuperAdminUser(email, roles)
        onCreate(newUser)
        resetForm()
        setStatus({ success: true })
        setSubmitting(false)
        toast.success("User was successfully added!")
        onAddComplete()
      } catch (e) {
        const error = guaranteedError(e)
        setStatus({ success: false })
        setSubmitting(false)
        console.warn(error)
        toast.error(error.message)
      }
    }}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{ p: 3 }}>
          <Typography align="center" color="textPrimary" gutterBottom variant="h5">
            Add User
          </Typography>
        </Box>

        <Box sx={{ pl: 3, pr: 3 }}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            variant="outlined"
          />

          <Box sx={{ mt: 2 }}>
            <MultipleSelectChip
              options={Object.keys(AdminRolePrisma).sort()}
              value={values.roles}
              onBlur={handleBlur}
              onChange={handleChange}
              label="Roles"
              name="roles"
              error={Boolean(touched.roles && errors.roles)}
              helperText={touched.roles && errors.roles}
              sx={{ mb: 3 }}
              fullWidth
            />
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            p: 2,
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Button color="primary" onClick={onCancel} variant="text">
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
          >
            Confirm
          </Button>
        </Box>
      </form>
    )}
  </Formik>
)

export default NewUserForm
