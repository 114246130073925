import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { UserAccountResource } from "gather-common/dist/src/public/v2/resources/users"
import { UserAccountPrisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export type AdminDashboardUserResource = {
  firebaseAuthId: UserAccountPrisma["firebaseAuthId"]
  email: UserAccountPrisma["email"]
}

export const users = c.router({
  delete: {
    method: RequestMethod.DELETE,
    path: HttpV2Paths.AdminUserAccounts,
    body: z.object({
      emails: z.array(z.string().email()),
    }),
    responses: { 200: c.type<{ emails: string[] }>() },
  },
  getByEmailOrId: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminUserAccount,
    responses: {
      200: c.type<UserAccountResource>(),
    },
  },
})
