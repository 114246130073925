import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HTTPGSStateResponse } from "gather-game-logic/dist/src/public/gameLogicTypes"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const meetingJoinInfo = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.MeetingJoinInfoLink,
    pathParams: z.object({ linkId: z.string() }),
    responses: {
      200: c.type<HTTPGSStateResponse<"MeetingJoinInfo">>(),
      404: c.type<{ error: string }>(),
    },
  },
})
