import { decode, encode, ExtensionCodec } from "@gathertown/msgpack"
import { DateTimeExt } from "gather-common/dist/src/public/valueObjects/DateTimeExt"
import { just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { VALUE_OBJECTS } from "../../valueObjects"

/**
 * A custom msgpack extension codec used in our GS to support custom objects
 *
 * BE CAREFUL OF PERFORMANCE! Most things in this file are perf-sensitive and should be written
 * optimally for perf.
 */
export const codec = new ExtensionCodec()

/**
 * A map of all value objects that should be serialized via the custom `type: 0x00` codec below.
 * Consider definining a separate `type` for frequently-used or perf-sensitive classes
 * (see 0x01, etc below).
 */
export const SERIALIZABLE_VALUE_OBJECTS = VALUE_OBJECTS

/**
 * The input format passed to the `type: 0x00` codec below.
 */
type ValueObjectCodecInput = { k: keyof typeof SERIALIZABLE_VALUE_OBJECTS; v: object }

// Register custom codec for value objects in SERIALIZABLE_VALUE_OBJECTS
const valueObjEntries = Object.entries(SERIALIZABLE_VALUE_OBJECTS)
codec.register({
  type: 0x00,
  encode: (input: unknown) => {
    for (const [k, ValueObject] of valueObjEntries) {
      if (input instanceof ValueObject) return encode({ k, v: input.serialize() })
    }
    return null
  },
  decode: (data: Uint8Array) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const { k, v } = decode(data) as ValueObjectCodecInput
    const ValueObject = just(SERIALIZABLE_VALUE_OBJECTS[k])
    // We're trusting the codec to give us a valid `v` here
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    return ValueObject.deserialize(v as any)
  },
})

// Register custom codec for DateTimes
codec.register({
  type: 0x01,
  encode: (input: unknown) => {
    if (DateTimeExt.isDateTime(input)) return encode(+input)
    return null
  },
  decode: (data: Uint8Array) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const timestamp = decode(data) as number
    return DateTimeExt.fromMillis(timestamp)
  },
})

// Register custom codec for Sets
codec.register({
  type: 0x02,
  encode: (input: unknown) => {
    if (input instanceof Set) return encode(Array.from(input))
    return null
  },
  decode: (data: Uint8Array) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const array = decode(data) as unknown[]
    return new Set(array)
  },
})

codec.register({
  type: 0x04,
  encode: (input: unknown) => {
    if (typeof input === "undefined") return new Uint8Array()
    return null
  },
  decode: (_data: Uint8Array) => undefined,
})
