import loadable from "@loadable/component"
import AuthGuard from "components/authentication/AuthGuard"
import PermittedRouteGuard from "components/authentication/PermittedRouteGuard"
import DashboardLayout from "components/layout/DashboardLayout"
import LoadingScreen from "components/LoadingScreen"
import AdminUsersIndexPage from "pages/dashboard/adminUsers/IndexPage"
import BulkUsersIndexPage from "pages/dashboard/bulkUsers/IndexPage"
import ClientStateIndexPage from "pages/dashboard/clientState/IndexPage"
import ClientStateViewerPage from "pages/dashboard/clientState/ViewerPage"
import SpacesIndexPage from "pages/dashboard/spaces/IndexPage"
import UsersIndexPage from "pages/dashboard/users/IndexPage"
import WearablesIndexPage from "pages/dashboard/wearables/IndexPage"
import React from "react"

import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types"
import CatalogItemsIndexPage from "../pages/dashboard/catalogItems/IndexPage"
import VariantsPage from "../pages/dashboard/catalogItems/VariantsPage"
import SpaceTemplatesIndexPage from "../pages/dashboard/spaceTemplate/IndexPage"

const LOADABLE_OPTIONS = {
  fallback: <LoadingScreen />,
}

const Placeholder = loadable(() => import("pages/dashboard/Placeholder"), LOADABLE_OPTIONS)

const SPACE_ROUTES = [
  {
    path: "spaces",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ViewSpaces, Can.ManageSpaces]}>
            <SpacesIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const SPACE_TEMPLATE_ROUTES = [
  {
    path: "space-templates",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageSpaceTemplates]}>
            <SpaceTemplatesIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const WEARABLE_ROUTES = [
  {
    path: "wearables",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageWearables]}>
            <WearablesIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const CATALOG_ITEM_ROUTES = [
  {
    path: "catalog-items",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageCatalogItems]}>
            <CatalogItemsIndexPage />
          </PermittedRouteGuard>
        ),
      },
      {
        path: ":catalogItemId",
        element: (
          <PermittedRouteGuard required={[Can.ManageCatalogItems]}>
            <VariantsPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const USERS_ROUTES = [
  {
    path: "users",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageUsers]}>
            <UsersIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const BULK_USERS_ROUTES = [
  {
    path: "bulk-users",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageUsers]}>
            <BulkUsersIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const ADMIN_USERS_ROUTES = [
  {
    path: "admin-users",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ManageAdminUsers]}>
            <AdminUsersIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

// const ROLE_ROUTES = [
//   {
//     path: "roles",
//     children: [
//       {
//         path: "",
//         element: (
//           <PermittedRouteGuard required={[Can.ManageRoles]}>
//             <Placeholder />
//           </PermittedRouteGuard>
//         ),
//       },
//       {
//         path: "new",
//         element: (
//           <PermittedRouteGuard required={[Can.ManageRoles]}>
//             <Placeholder />
//           </PermittedRouteGuard>
//         ),
//       },
//       {
//         path: ":roleId",
//         element: (
//           <PermittedRouteGuard required={[Can.ManageRoles]}>
//             <Placeholder />
//           </PermittedRouteGuard>
//         ),
//       },
//     ],
//   },
// ];

const AV_ROUTES = [
  {
    path: "client-state",
    children: [
      {
        path: "",
        element: (
          <PermittedRouteGuard required={[Can.ViewClientStateSessions]}>
            <ClientStateIndexPage />
          </PermittedRouteGuard>
        ),
      },
    ],
  },
]

const routes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Placeholder />,
      },
      ...ADMIN_USERS_ROUTES,
      ...SPACE_ROUTES,
      ...SPACE_TEMPLATE_ROUTES,
      ...USERS_ROUTES,
      ...BULK_USERS_ROUTES,
      ...WEARABLE_ROUTES,
      ...AV_ROUTES,
      ...CATALOG_ITEM_ROUTES,
      // ...ROLE_ROUTES,
    ],
  },
  {
    path: "dashboard/client-state/:sessionId",
    element: (
      <AuthGuard>
        <ClientStateViewerPage />
      </AuthGuard>
    ),
  },
]

export default routes
