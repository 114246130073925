import { Info, Interval } from "luxon"

import { getFixedClassRef } from "gather-common/dist/src/public/getFixedClassRef"
import { ga } from "gather-common/dist/src/public/mobx/decorators"
import { DateTimeExt } from "gather-common/dist/src/public/valueObjects/DateTimeExt"
import { isUndefined, just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { BaseValueObject } from "gather-state-sync/dist/src/public/BaseValueObject"
import { s, SchemaInfer } from "../framework/schema/schema"

export const dateRangeSchema = s.object({
  start: s.date(),
  end: s.date(),
})

type DateRangeData = SchemaInfer<typeof dateRangeSchema>

type SerializedDateRange = [number, number] // [startMs, endMs]

@ga.observableClass
export class DateRange extends BaseValueObject<
  typeof dateRangeSchema.modelZod,
  SerializedDateRange
>(dateRangeSchema.modelZod) {
  constructor(start: DateTimeExt, end: DateTimeExt)
  constructor(data: DateRangeData)
  constructor(startOrData: DateTimeExt | DateRangeData, end?: DateTimeExt) {
    super(
      DateTimeExt.isDateTime(startOrData) ? { start: startOrData, end: just(end) } : startOrData,
    )
  }

  // DateRange value objects are a bit annoying for the GS b/c they need "recursive" serialization:
  // both DateRanges and their nested DateTime objects need custom serialization. GS doesn't support this
  // automatically [yet], so we work around it by defining custom serialization for DateRange here.
  serialize(): SerializedDateRange {
    return [this.start.toMillis(), this.end.toMillis()]
  }
  static deserialize([start, end]: SerializedDateRange) {
    const DateRange_ = getFixedClassRef(this, DateRange)
    return new DateRange_(DateTimeExt.fromMillis(start), DateTimeExt.fromMillis(end))
  }

  overlaps(other: DateRange): boolean {
    const otherStartsInThis = this.start <= other.start && other.start <= this.end
    const otherEndsInThis = this.start <= other.end && other.end <= this.end
    const thisInRange = other.start <= this.start && this.end <= other.end

    return otherStartsInThis || otherEndsInThis || thisInRange
  }

  contains(date: DateTimeExt) {
    return this.start <= date && date <= this.end
  }

  isStartingBefore(date: DateTimeExt) {
    return this.start < date
  }

  isStartingAfter(date: DateTimeExt) {
    return this.start > date
  }

  isEndingBefore(date: DateTimeExt) {
    return this.end < date
  }

  isEndingAfter(date: DateTimeExt) {
    return this.end > date
  }

  isMultipleDays() {
    return !this.start.hasSame(this.end, "day")
  }

  durationMs() {
    return this.end.diff(this.start).milliseconds
  }

  toHumanTimeString() {
    // Note: Luxon's `Interval` provides a handy toLocaleString(DateTime.TIME_SIMPLE), but it shows
    // the dates if the start and end are on different days. We don't want this, so we have to parse
    // the date string ourselves.

    const startTimeString = this.start.toLocaleString(DateTimeExt.TIME_SIMPLE)
    const endTimeString = this.end.toLocaleString(DateTimeExt.TIME_SIMPLE)

    // Get the meridiems (AM/PM) from the current locale.
    const meridiems = Info.meridiems()
    const [amMeridiem, pmMeridiem] = meridiems

    // Fall back to Luxon's default formatting if the locale uses an unexpected set of meridiems.
    // In practice, this shouldn't happen since all known locales have 2 meridiems.
    if (meridiems.length !== 2 || isUndefined(amMeridiem) || isUndefined(pmMeridiem))
      return Interval.fromDateTimes(
        this.start.toLuxonDateTime(),
        this.end.toLuxonDateTime(),
      ).toLocaleString(DateTimeExt.TIME_SIMPLE)

    const startTimeHasAMMeridiem = startTimeString.includes(amMeridiem)
    const startTimeHasPMMeridiem = startTimeString.includes(pmMeridiem)
    const endTimeHasAMMeridiem = endTimeString.includes(amMeridiem)
    const endTimeHasPMMeridiem = endTimeString.includes(pmMeridiem)

    const timesAreMissingMeridiems =
      !startTimeHasAMMeridiem &&
      !startTimeHasPMMeridiem &&
      !endTimeHasAMMeridiem &&
      !endTimeHasPMMeridiem

    const startAndEndMeridiemsAreTheSame =
      (startTimeHasAMMeridiem && endTimeHasAMMeridiem) ||
      (startTimeHasPMMeridiem && endTimeHasPMMeridiem)

    // If the meridiem (AM/PM) is missing in the formatted strings or the meridiems are different, display them as is.
    if (timesAreMissingMeridiems || !startAndEndMeridiemsAreTheSame)
      return `${startTimeString} – ${endTimeString}`.toLocaleLowerCase()

    const startTimeWithoutMeridiem = startTimeString
      .replace(amMeridiem, "")
      .replace(pmMeridiem, "")
      .trim()

    // Otherwise, display the start time without the meridiem and the end time with the meridiem.
    return `${startTimeWithoutMeridiem} – ${endTimeString}`.toLocaleLowerCase()
  }

  toHumanMonthAndYearString() {
    return Interval.fromDateTimes(
      this.start.toLuxonDateTime(),
      this.end.toLuxonDateTime(),
    ).toLocaleString({ month: "long", year: "numeric" })
  }
}
