import { initContract } from "@ts-rest/core"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const users = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceUsers,
    responses: { 200: c.type<never>() },
  },

  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceUser,
    responses: { 200: c.type<never>() },
  },
})
