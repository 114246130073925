import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { CatalogItemVariantResource } from "gather-common/dist/src/public/v2/resources/catalogItems"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()
export const catalogItemVariants = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminCatalogItemVariants,
    responses: { 200: c.type<CatalogItemVariantResource[]>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminCatalogItemVariant,
    body: z.object({ isPublished: z.boolean() }).partial(),
    responses: { 200: c.type<CatalogItemVariantResource>() },
  },
})
