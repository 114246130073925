import { toast } from "react-hot-toast"

import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { getErrorMessage } from "gather-common/dist/src/public/utils"
import { UserAccountResource } from "gather-common/dist/src/public/v2/resources/users"

export const getUserDetails = async (emailOrUserId: string): Promise<UserAccountResource> => {
  try {
    const response = tsrAPI.admin.users.getByEmailOrId({
      params: { userId: emailOrUserId },
    })

    return response
  } catch (error) {
    const message = getErrorMessage(error, "Failed to retrieve user details.")
    toast.error(message)
    return Promise.reject(message)
  }
}
export const deleteUsers = async (emails: string[]): Promise<string[]> => {
  try {
    const response = await tsrAPI.admin.users.delete({
      body: { emails },
    })

    return response.emails
  } catch (error) {
    const message = getErrorMessage(error, "Failed to delete users.")
    toast.error(message)
    return Promise.reject(message)
  }
}
