/**
 * Ramda is great, but it's not always great with TS, or being readable. This is a grab-bag
 * of utility functions that add aliases, handy extensions of Ramda, or functions that better
 * wrangle the type requirements on TS side.
 */
import {
  always,
  apply,
  both,
  complement,
  compose,
  equals,
  identity,
  indexBy,
  is,
  isEmpty,
  maxBy,
  memoizeWith,
  move,
  Ord,
  pipe,
  pipeWith,
  pluck,
  prop,
  reduce,
  reduceRight,
  reject,
  sort,
  subtract,
  symmetricDifference,
  trim,
} from "ramda"

import { Logger } from "./Logger"
import {
  assertNonEmpty,
  assertNotNil,
  FixedSizeArray,
  NonEmptyArray,
  OmitParameters,
} from "./tsUtils"

/**
 * Allows you to partially apply arguments to a function and create a specialized function:
 *
 *     const add = (a, b) => a + b
 *     const add5 = partial<typeof add, 1>([5], add)
 *     add5(3) // => 8
 *
 * Very useful when you want to use the same function, but with some arguments already provided.
 * Here's a pretty decent writeup, if you're unfamiliar with partial application:
 * https://www.digitalocean.com/community/tutorials/javascript-functional-programming-explained-partial-application-and-currying
 *
 * We must use `any` (as opposed to `unknown` for example) for correct typing here. You should always
 * explicitly specify the `T` and `N` generics - relying on inference typically doesn't work, unfortunately.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const partial = <T extends (...args: any[]) => any, N extends number>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  staticArgs: FixedSizeArray<any, N>,
  f: T,
): ((...restArgs: Parameters<OmitParameters<T, N>>) => ReturnType<T>) =>
  // We *could* use Ramda's partial function here, but to provide stronger typing we basically
  // have to re-implement the function, so we don't gain much by delegating in the final return.
  function (...restArgs: Parameters<OmitParameters<T, N>>): ReturnType<T> {
    return f(...[...staticArgs, ...restArgs])
  }

/**
 * Evaluates the function lazily, and then always returns that result. A specialized form
 * of memoization.
 */
export const lazily = <R, T extends () => R>(fn: T): T => memoizeWith(always("1"), fn)

/**
 * Checks if it's "just" the value, and turns a maybe type into a definite type. Naming convention
 * borrowed from FP concept of Maybe/Just:
 * https://engineering.dollarshaveclub.com/typescript-maybe-type-and-module-627506ecc5c8
 */
export const just = <T>(
  x?: T | null,
  message = "Expected something, got nothing",
): NonNullable<T> => {
  assertNotNil(x, message)
  return x
}

export const getId = prop("id")

/**
 * Conditionally returns props to an object if a condition is met. Useful for adding props to an object
 * in a minimal way.
 *
 * Usage:
 * const someObj = {
 *  id: "123",
 *  name: "Alex",
 *  ...maybeReturnProps(hasEmail(user), { email: user.email }),
 * }
 */
export const maybeReturnProps = <T extends Record<string, unknown>>(
  condition: boolean,
  props: T,
): {} => (condition ? props : {})

/**
 * Converts an array of elements into an object with each key as an element's id.
 * Basically, takes an array and "indexes by" the ID. Seems tautological but it's hard to put
 * differently.
 */
export const indexById = <T extends Record<"id", string | number>>(
  xs: T[],
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): Record<string | number, T> => indexBy(getId as (x: T) => string | number, xs)

/**
 * Pulls out a slice of data from array of objects. Commonly used in tests, but applicable
 * anywhere. Feel free to add other common props as they come up.
 */
export const idsOf = pluck("id")
export const namesOf = pluck("name")

// Exists as a very slightly faster (because it doesn't support currying) impl compared to ramda's.
// Using ours vs. ramda's will almost never matter.
export const isNil = (value: unknown): value is null | undefined => value == null
// Same as `isNil` above.
export const isNotNil = <T>(value: T | null | undefined): value is T => value != null

export const isUndefined = (value: unknown): value is undefined => value === undefined
export const isNotUndefined = <T>(value: T | undefined): value is T => value !== undefined

export const isNotEmpty = complement(isEmpty)

/**
 * Useful for type refinements when you have an array/object/string that maybe exists, and you only
 * want to do something if (a) it's not nil, and (b) it (is or is not) empty. This function does that.
 *
 *   isNotNilAndNotEmpty([1, 2, 3]) => true
 *   isNotNilAndNotEmpty({ a: 1 }) => true
 *   isNotNilAndNotEmpty("hello") => true

 *   isNotNilAndEmpty([]) => true
 *   isNotNilAndEmpty({}) => true
 *   isNotNilAndEmpty("") => true
 *
 *   isNotNilAndEmpty(null) => false
 *   isNotNilAndNotEmpty(undefined) => false
 *
 * Why does this exist? First, to help with type refinements:
 *
 *   const foo: { a?: number } | undefined = getFoo()
 *   if (isNotNilAndNotEmpty(foo)) {
 *     // foo is now { a?: number }
 *     // and we know it's not empty
 *   }
 *   if (isNotNilAndEmpty(foo)) {
 *     // foo is now { a?: number }
 *     // and we know it's empty
 *   }
 *
 * The "empty vs not empty" is hard to impossible to represent cleanly in TypeScript, so we call
 * that "business logic refinement". It's not perfect, but it gets us most of the way there.
 *
 * **CAVEAT**
 *
 * The `else` block kind of lies:
 *
 *   ```ts
 *   if (isNotNilAndNotEmpty(foo)) {
 *   } else {
 *     // foo is `undefined`, which is not necessarily true;
 *     // it might be defined but be empty
 *   }
 *   ```
 *
 * However, the "long hand" form of this doesn't do much better. So if you need behavior where
 * you care about acting on `foo` either when it's empty or not, then create nested if statements.
 *
 *   ```ts
 *   if (!isNil(foo)) {
 *     // do your emptiness checks here
 *   }
 *   ```
 *
 * You can refer to the tests for examples of the type refinement in action.
 *
 * Most people make the mistake of thinking that `isEmpty(null) === true`, but it's not. See more
 * here: https://github.com/ramda/ramda/issues/2507. While I disagree from the perspective of ease
 * of use, I can see the technical soundness of their point. So we have a helper here to do what
 * people would "normally expect", and we're explicit about it. Could've called it `isReallyEmpty`
 * but that seems subjective.
 *
 * See tests for example values.
 *
 * You could use these, but isBlank / isPresent will often capture what you want, and is
 * more terse.
 */
export const isNotNilAndNotEmpty = <T>(x: T | undefined | null): x is T =>
  both(isNotNil, isNotEmpty)(x)
// We need an anonymous function to get around some TS limitations.
export const isNotNilAndEmpty = <T>(x: T | undefined | null): x is T => both(isNotNil, isEmpty)(x)
export const isNilOrEmpty = (x: unknown): x is undefined | null | Record<string, never> | [] | "" =>
  !isNotNilAndNotEmpty(x)

/**
 * Alternative to Array.isArray that correctly narrows the type for arrays.
 */
export const isArray = <T>(arg: unknown): arg is readonly T[] => Array.isArray(arg)

/**
 * There was a useful method in Rails called `.blank?` that made some smart choices about blank
 * strings and boolean values. It's the same as `isNilOrEmpty`, except:
 *
 *   isBlank(null) => true
 *   isBlank(undefined) => true
 *   isBlank("  ") => true
 *   isBlank(true) => false
 *   isBlank(false) => true
 *   isBlank({}) => true
 *   ... and so on, the rest mirror the `emptiness` check from before.
 *
 * The inverse holds for `isPresent`.
 *
 * `isBlank` doesn't provide type refinement for Maybe types, because it might still be
 * `null | undefined`. We could've chosen to have `isBlank` be false for those values, but it
 * didn't make sense from the perspective of "would an engineer expect `null` to be blank?" It
 * seemed less confusing to have null/undefined be "blank" and not provide type refinement.
 * If you care about type refinement, use the `isNotNilAndEmpty` or `isNotNilAndNotEmpty` options
 * above.
 *
 * See tests for example values and type refinements.
 */
export const isBlank = <T>(x: T): boolean =>
  is(String, x)
    ? isNotNilAndEmpty(trim(x))
    : is(Boolean, x)
    ? !x
    : x === undefined || x === null
    ? true
    : isNotNilAndEmpty(x)
export const isPresent = <T>(x: T): x is Exclude<T, null | undefined | "" | false> =>
  complement(isBlank)(x)

/**
 * Drops false and nil values (null, undefined) from an iterable collection.
 */
export function compact<T>(x: readonly T[]): Exclude<T, null | undefined | false>[]
export function compact<T>(
  x: Record<string, T>,
): Record<string, Exclude<T, null | undefined | false>>
export function compact<T>(xs: readonly T[] | Record<string, T>): readonly T[] | Record<string, T> {
  return reject((x) => x === false || isNil(x), xs)
}

/**
 * Drops nil values (but not false values) from an iterable collection.
 */
export const compactNil = reject(isNil)

/**
 * Very simple helper that expresses an IIFE without the awkward syntax (which requires you to
 * notice the easy-to-miss trailing () invocation)
 *
 * It's meant as a stand-in for do-expressions (https://github.com/tc39/proposal-do-expressions)
 * until that proposal is ratified into JS syntax.
 */
export const doIt = <T>(fn: () => T): T => fn()

/**
 * Moves the given element to the front of the array. If the element is not found,
 * the array remains the same.
 */
export const moveElementToFrontOfArray = <T>(element: T, arr: T[]): T[] => {
  const elementIndex = arr.findIndex((e) => e === element)

  if (elementIndex === -1) return arr

  return move(elementIndex, 0, arr)
}

/**
 * Moves the given list of elements to the front of the array if they are present.
 * The order of the elements will be retained.
 */
export const moveElementsToFrontOfArray = <T>(elements: T[], arr: T[]): T[] =>
  reduceRight(moveElementToFrontOfArray, arr, elements)

/**
 * Checks if two arrays have the same values, regardless of order.
 */
export const eqValues = <T>(list1: T[], list2: T[]): boolean =>
  compose<[T[], T[]], T[], boolean>(isEmpty, symmetricDifference)(list1, list2)

export const maxOf = (xs: Ord[]): Ord => maxByOf(identity, xs)
export const minOfWithIndex = (xs: Ord[]): { min: Ord; index: number } =>
  minByOfWithIndex(identity, xs)

/**
 * Expects xs to be non-empty or throws a runtime error. We don't use NonEmptyArray to make it
 * easier for DX.
 */
export const maxByOf = <T>(fn: (x: T) => Ord, xs: T[]): T => {
  assertNonEmpty(xs)
  return reduce((acc, elem) => maxBy(fn, acc, elem), xs[0], xs)
}
export const minByOfWithIndex = <T>(fn: (x: T) => Ord, xs: T[]): { min: T; index: number } => {
  assertNonEmpty(xs)
  return xs.reduce<{ min: T; index: number }>(
    (acc, elem, index) => {
      const minValue = fn(elem)
      return minValue < fn(acc.min) ? { min: elem, index } : acc
    },
    { min: xs[0], index: 0 },
  )
}

/**
 * Run each function awaiting the result of the previous one.
 * */
export const pipeAsync = pipeWith(async (f, res) => f(await res))

/**
 * Checks if a value is a string.
 */
export const isString = (value: unknown): value is string => typeof value === "string"

/**
 * Wraps in an array if it isn't already an array. `null` or `undefined` returns
 * an empty array.
 */
export function asArray(input: null | undefined): []
export function asArray<T>(input: T | T[]): T[]
export function asArray<T>(input: null | undefined | T | T[]): [] | T[] {
  return isNil(input) ? [] : Array.isArray(input) ? input : [input]
}

/**
 * Sorts numbers.
 */
export const sortNumbers = sort(subtract)

export function safePipe<T>(...args: NonEmptyArray<(arg: T) => T>): (arg: T) => T
export function safePipe<T>(...args: NonEmptyArray<(arg: T) => T>) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const pipeline = args.map((f) => safelyTransformOrReturnPreviousValue(f)) as [(arg: T) => T]
  return apply(pipe)(pipeline)
}

const safelyTransformOrReturnPreviousValue =
  <T>(f: (v: T) => T) =>
  (previousValue: T): T => {
    try {
      return f(previousValue)
    } catch (e) {
      Logger.error(`Error occurred in transformer ${f.name}: ${e}`)
    }
    return previousValue
  }

/**
 * Creates a Record by transforming an array of keys into key-value pairs.
 *
 * Rejected names (in case people search for them):
 *   - mapKeysIntoObject
 *   - mapReduceListToObject
 *   - mapListIntoObjectWithKeys
 *
 * @param keys Array of keys to use
 * @param transform Function that takes a key and returns its corresponding value
 */
export function mapReduceKeysIntoObject<K extends string | symbol, V>(
  keys: readonly K[],
  transform: (key: K) => V,
): Record<K, V> {
  return keys.reduce<Record<K, V>>((acc, key) => {
    acc[key] = transform(key)
    return acc
    // Can't do this without a cast:
    // https://gather-town.slack.com/archives/C01QLNAQ76W/p1734723796219959
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as Record<K, V>)
}

/**
 * Creates a map indexed by unique values in the map. If any values are not unique, the last value
 * will be used.
 */
export const createMapIndexedByUniqueValue = <T, K extends keyof T>(arr: T[], key: K) =>
  arr.reduce<Map<T[K], T>>((indexedMap, item) => {
    indexedMap.set(item[key], item)

    return indexedMap
  }, new Map())

/**
 * Given an object, mutate any values that have changed in the update object to the updated
 * values.
 */
export const assignChangedValues =
  // `any` is type that JSON.stringify takes in, so we mirror that here. `unknown` seems to have type
  // incompatibilities with certain stringifyable values.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (obj: { [key: string]: any }, update: { [key: string]: any }) => {
    for (const key in update) {
      if (!equals(update[key], obj[key])) {
        obj[key] = update[key]
      }
    }
  }

/**
 * Given an object and an update object, return a new object with key/value pairs where the values
 * have been changed or the key is new.
 */
export const getChangedValuesOrNewKeyValues =
  // `any` is type that JSON.stringify takes in, so we mirror that here. `unknown` seems to have type
  // incompatibilities with certain stringifyable values.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (obj: { [key: string]: any }, update: { [key: string]: any }) =>
    Object.entries(update).reduce<Partial<typeof update>>((acc, [key, value]) => {
      if (!equals(value, obj[key])) {
        acc[key] = value
      }
      return acc
    }, {})
