
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
  skip
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 6.4.1
 * Query Engine version: a9055b89e58b4b5bfb59600785423b1db3d0e75d
 */
Prisma.prismaVersion = {
  client: "6.4.1",
  engine: "a9055b89e58b4b5bfb59600785423b1db3d0e75d"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}



/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.BrowserVideoClientStateSessionScalarFieldEnum = {
  id: 'id',
  userAccountId: 'userAccountId',
  spaceId: 'spaceId',
  spaceUserId: 'spaceUserId',
  sessionId: 'sessionId',
  session: 'session',
  hwGpuPowerLevel: 'hwGpuPowerLevel',
  hwGpuPerformance: 'hwGpuPerformance',
  hwMemory: 'hwMemory',
  hwConcurrency: 'hwConcurrency',
  hwMeetsMinSpecs: 'hwMeetsMinSpecs',
  connectionType: 'connectionType',
  connectionEffectiveType: 'connectionEffectiveType',
  domain: 'domain',
  env: 'env',
  version: 'version',
  commitHash: 'commitHash',
  buildTimestamp: 'buildTimestamp',
  electron: 'electron',
  userAgent: 'userAgent',
  userAgentBrowser: 'userAgentBrowser',
  userAgentOs: 'userAgentOs',
  userAgentVersion: 'userAgentVersion',
  operatingSystem: 'operatingSystem',
  operatingSystemVersion: 'operatingSystemVersion',
  avStrategy: 'avStrategy',
  message: 'message',
  translatedMessage: 'translatedMessage',
  liveKitSessionId: 'liveKitSessionId',
  liveKitParticipantId: 'liveKitParticipantId',
  agoraVideoId: 'agoraVideoId',
  agoraScreenId: 'agoraScreenId',
  agoraVideoChannelName: 'agoraVideoChannelName',
  agoraScreenChannelName: 'agoraScreenChannelName',
  participants: 'participants',
  affectedEveryone: 'affectedEveryone',
  isPaidSpace: 'isPaidSpace',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RelationLoadStrategy = {
  query: 'query',
  join: 'join'
};

exports.Prisma.SpaceWorkOSConnectionScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  workOSOrganizationId: 'workOSOrganizationId',
  state: 'state',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOSettingsScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  emailDomainVerificationCode: 'emailDomainVerificationCode',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOEmailDomainScalarFieldEnum = {
  id: 'id',
  domain: 'domain',
  spaceSSOSettingsId: 'spaceSSOSettingsId',
  verificationState: 'verificationState',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOLinkedSpaceScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  verified: 'verified',
  spaceSSOSettingsId: 'spaceSSOSettingsId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserDeletionForStagingScalarFieldEnum = {
  id: 'id',
  firebaseAuthId: 'firebaseAuthId',
  email: 'email',
  completed: 'completed',
  attempts: 'attempts',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserFeedbackScalarFieldEnum = {
  id: 'id',
  feedback: 'feedback',
  name: 'name',
  email: 'email',
  spaceUrl: 'spaceUrl',
  reason: 'reason',
  device: 'device',
  gates: 'gates',
  isMobile: 'isMobile',
  deviceInfo: 'deviceInfo',
  performanceProblem: 'performanceProblem',
  sessionId: 'sessionId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.BaseCombinedCalendarEventScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  externalEventId: 'externalEventId',
  provider: 'provider',
  title: 'title',
  description: 'description',
  rawLocation: 'rawLocation',
  startDateTime: 'startDateTime',
  startDate: 'startDate',
  startTimeZone: 'startTimeZone',
  endDateTime: 'endDateTime',
  endDate: 'endDate',
  endTimeZone: 'endTimeZone',
  attendees: 'attendees',
  recurrence: 'recurrence',
  recurringEventId: 'recurringEventId',
  organizerEmail: 'organizerEmail',
  isDeleted: 'isDeleted',
  meetingJoinInfoId: 'meetingJoinInfoId'
};

exports.Prisma.BaseCombinedCalendarEventRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CatalogItemScalarFieldEnum = {
  id: 'id',
  category: 'category',
  family: 'family',
  type: 'type',
  description: 'description',
  tags: 'tags',
  version: 'version',
  lastSyncAuthoredAt: 'lastSyncAuthoredAt',
  order: 'order',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CatalogItemVariantScalarFieldEnum = {
  id: 'id',
  color: 'color',
  orientation: 'orientation',
  originX: 'originX',
  originY: 'originY',
  isDefault: 'isDefault',
  catalogItemId: 'catalogItemId',
  collision: 'collision',
  sittable: 'sittable',
  isPublished: 'isPublished',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  dimensionsInPixels__width: 'dimensionsInPixels__width',
  dimensionsInPixels__height: 'dimensionsInPixels__height',
  offsetInPixels__left: 'offsetInPixels__left',
  offsetInPixels__top: 'offsetInPixels__top',
  mainRenderable__imageUrl: 'mainRenderable__imageUrl',
  mainRenderable__fold: 'mainRenderable__fold'
};

exports.Prisma.ChatMessageScalarFieldEnum = {
  id: 'id',
  message: 'message',
  spaceId: 'spaceId',
  spaceUserId: 'spaceUserId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ConnectionScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  authUserId: 'authUserId',
  spaceUserId: 'spaceUserId',
  entered: 'entered',
  target: 'target'
};

exports.Prisma.ConnectionRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  name: 'name',
  externalId: 'externalId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionScalarFieldEnum = {
  id: 'id',
  externalId: 'externalId',
  spaceId: 'spaceId',
  spaceUserId: 'spaceUserId',
  type: 'type',
  lastSyncedAt: 'lastSyncedAt',
  lastSyncTokenOrUrl: 'lastSyncTokenOrUrl',
  syncing: 'syncing',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionAccessScalarFieldEnum = {
  id: 'id',
  externalCalendarConnectionId: 'externalCalendarConnectionId',
  externalCalendarId: 'externalCalendarId',
  primary: 'primary',
  color: 'color',
  visible: 'visible',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionAccessRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarConnectionSecretsScalarFieldEnum = {
  id: 'id',
  calendarConnectionId: 'calendarConnectionId',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalCalendarRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FloorScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  previewFilePath: 'previewFilePath',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FloorMapScalarFieldEnum = {
  id: 'id',
  status: 'status',
  baseAreaId: 'baseAreaId',
  lastSyncAt: 'lastSyncAt',
  lastEditedAt: 'lastEditedAt',
  sourceTemplateFloorId: 'sourceTemplateFloorId',
  floorId: 'floorId',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FloorRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GoogleCalendarEventScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  title: 'title',
  description: 'description',
  externalCalendarId: 'externalCalendarId',
  baseCombinedCalendarEventId: 'baseCombinedCalendarEventId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  googleCalendarExternalEventId: 'googleCalendarExternalEventId',
  googleCalendarStatus: 'googleCalendarStatus',
  googleCalendarLocation: 'googleCalendarLocation',
  googleCalendarStartDateTime: 'googleCalendarStartDateTime',
  googleCalendarStartDate: 'googleCalendarStartDate',
  googleCalendarStartTimeZone: 'googleCalendarStartTimeZone',
  googleCalendarEndDateTime: 'googleCalendarEndDateTime',
  googleCalendarEndDate: 'googleCalendarEndDate',
  googleCalendarEndTimeZone: 'googleCalendarEndTimeZone',
  googleCalendarAttendees: 'googleCalendarAttendees',
  googleCalendarRecurrence: 'googleCalendarRecurrence',
  googleCalendarRecurringEventId: 'googleCalendarRecurringEventId',
  googleCalendarEventType: 'googleCalendarEventType',
  googleCalendarVisibility: 'googleCalendarVisibility',
  googleCalendarOrganizerName: 'googleCalendarOrganizerName',
  googleCalendarOrganizerEmail: 'googleCalendarOrganizerEmail'
};

exports.Prisma.GuestPassScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  spaceUserId: 'spaceUserId',
  hostId: 'hostId',
  state: 'state',
  stateReason: 'stateReason',
  requestedAt: 'requestedAt',
  respondedAt: 'respondedAt',
  meetingId: 'meetingId',
  meetingJoinInfoLinkId: 'meetingJoinInfoLinkId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GuestPassRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MapAreaScalarFieldEnum = {
  id: 'id',
  parentAreaId: 'parentAreaId',
  parentGroupId: 'parentGroupId',
  parentObjectId: 'parentObjectId',
  relativeX: 'relativeX',
  relativeY: 'relativeY',
  deletedAt: 'deletedAt',
  spaceId: 'spaceId',
  mapId: 'mapId',
  mapEntityIdentifierId: 'mapEntityIdentifierId',
  mapAreaType: 'mapAreaType',
  capacity: 'capacity',
  name: 'name',
  doorways: 'doorways',
  wallsTexture: 'wallsTexture',
  floorTexture: 'floorTexture',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  dimensionsInTiles__width: 'dimensionsInTiles__width',
  dimensionsInTiles__height: 'dimensionsInTiles__height'
};

exports.Prisma.MapAreaRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MapEntityIdentifierScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  floorId: 'floorId',
  chatChannelUrl: 'chatChannelUrl',
  isLocked: 'isLocked'
};

exports.Prisma.MapGroupScalarFieldEnum = {
  id: 'id',
  parentAreaId: 'parentAreaId',
  parentGroupId: 'parentGroupId',
  parentObjectId: 'parentObjectId',
  relativeX: 'relativeX',
  relativeY: 'relativeY',
  deletedAt: 'deletedAt',
  spaceId: 'spaceId',
  mapId: 'mapId',
  hashIdentifier: 'hashIdentifier',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MapObjectScalarFieldEnum = {
  id: 'id',
  parentAreaId: 'parentAreaId',
  parentGroupId: 'parentGroupId',
  parentObjectId: 'parentObjectId',
  relativeX: 'relativeX',
  relativeY: 'relativeY',
  deletedAt: 'deletedAt',
  spaceId: 'spaceId',
  mapId: 'mapId',
  hashIdentifier: 'hashIdentifier',
  catalogItemVariantId: 'catalogItemVariantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MapObjectRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingScalarFieldEnum = {
  id: 'id',
  status: 'status',
  recurringMeetingId: 'recurringMeetingId',
  meetingType: 'meetingType',
  visibility: 'visibility',
  actualStartDate: 'actualStartDate',
  actualEndDate: 'actualEndDate',
  meetingRestartedAt: 'meetingRestartedAt',
  virtualCalendarEventRefId: 'virtualCalendarEventRefId',
  canInviteesInviteOthers: 'canInviteesInviteOthers',
  canInviteesModifyDetails: 'canInviteesModifyDetails',
  canInviteesSeeOtherInvitees: 'canInviteesSeeOtherInvitees',
  areaId: 'areaId',
  spaceId: 'spaceId',
  primaryHostId: 'primaryHostId',
  isCanceled: 'isCanceled',
  meetingCreationDate: 'meetingCreationDate',
  meetingJoinInfoId: 'meetingJoinInfoId',
  baseCombinedCalendarEventId: 'baseCombinedCalendarEventId',
  areaLastAutoAssignedAt_TEMP: 'areaLastAutoAssignedAt_TEMP',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingJoinInfoScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  linkId: 'linkId',
  areaId: 'areaId',
  status: 'status',
  hostId: 'hostId'
};

exports.Prisma.MeetingJoinInfoRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingJoinRequestScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId',
  meetingId: 'meetingId',
  responderId: 'responderId',
  responseStatus: 'responseStatus',
  respondedAt: 'respondedAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingJoinRequestRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingParticipantScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId',
  meetingId: 'meetingId',
  inviterId: 'inviterId',
  isHost: 'isHost',
  inviteStatus: 'inviteStatus',
  responseStatus: 'responseStatus',
  didAttend: 'didAttend',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingParticipantRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.MeetingRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.OutfitTemplateScalarFieldEnum = {
  id: 'id',
  skin: 'skin',
  hair: 'hair',
  facialHair: 'facialHair',
  top: 'top',
  bottom: 'bottom',
  shoes: 'shoes',
  hat: 'hat',
  glasses: 'glasses',
  other: 'other',
  costume: 'costume',
  mobility: 'mobility',
  jacket: 'jacket',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PermissionsGroupScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PermissionsGroupUserScalarFieldEnum = {
  id: 'id',
  groupId: 'groupId',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PermissionsRoleScalarFieldEnum = {
  id: 'id',
  name: 'name',
  roleType: 'roleType',
  permissions: 'permissions',
  spaceId: 'spaceId',
  creatorId: 'creatorId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceScalarFieldEnum = {
  id: 'id',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceInvitationScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  coreRole: 'coreRole',
  inviterId: 'inviterId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSettingsScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  guestCheckInEnabled: 'guestCheckInEnabled',
  emailDomainAuthEnabled: 'emailDomainAuthEnabled',
  allowedEmailDomains: 'allowedEmailDomains',
  gatherStaffAccessEnabled: 'gatherStaffAccessEnabled',
  showAssist: 'showAssist',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSettingsRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceTemplateScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  numberOfDesks: 'numberOfDesks',
  deskType: 'deskType',
  officeStyle: 'officeStyle',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceTemplateRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserScalarFieldEnum = {
  id: 'id',
  name: 'name',
  coreRole: 'coreRole',
  spaceId: 'spaceId',
  floorId: 'floorId',
  profilePictureId: 'profilePictureId',
  followTargetId: 'followTargetId',
  userAccountId: 'userAccountId',
  clusterId: 'clusterId',
  deskId: 'deskId',
  shouldBeInClusterWithFollowTarget: 'shouldBeInClusterWithFollowTarget',
  connected: 'connected',
  handRaisedAt: 'handRaisedAt',
  speaking: 'speaking',
  lastOnlineAt: 'lastOnlineAt',
  currentTargetMeetingAreaId: 'currentTargetMeetingAreaId',
  shouldBeInClusterWithOthersWithSameTargetMeetingArea: 'shouldBeInClusterWithOthersWithSameTargetMeetingArea',
  calculatedPrimaryCalendarEmail: 'calculatedPrimaryCalendarEmail',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  position__x: 'position__x',
  position__y: 'position__y',
  direction__value: 'direction__value',
  speed__modifier: 'speed__modifier',
  userSetAvailability__value: 'userSetAvailability__value'
};

exports.Prisma.SpaceUserClusterScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOnboardingScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId',
  completedOnboardingTasks: 'completedOnboardingTasks',
  hasDismissedOnboardingModal: 'hasDismissedOnboardingModal',
  hasDismissedGatherAssistPrompt: 'hasDismissedGatherAssistPrompt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOnboardingRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOutfitScalarFieldEnum = {
  id: 'id',
  skin: 'skin',
  hair: 'hair',
  facialHair: 'facialHair',
  top: 'top',
  bottom: 'bottom',
  shoes: 'shoes',
  hat: 'hat',
  glasses: 'glasses',
  other: 'other',
  costume: 'costume',
  mobility: 'mobility',
  jacket: 'jacket',
  spaceId: 'spaceId',
  spaceUserId: 'spaceUserId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.StagedDeskAssignmentScalarFieldEnum = {
  id: 'id',
  userMapHistoryId: 'userMapHistoryId',
  email: 'email',
  spaceUserId: 'spaceUserId',
  deskId: 'deskId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.StudioUserSessionScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  mapId: 'mapId',
  spaceUserId: 'spaceUserId',
  isDirty: 'isDirty',
  isOpen: 'isOpen',
  lastHeartbeat: 'lastHeartbeat',
  target: 'target',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.StudioUserSessionRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserAccountScalarFieldEnum = {
  id: 'id',
  firebaseAuthId: 'firebaseAuthId',
  email: 'email',
  hubSpotContactId: 'hubSpotContactId',
  selectedLanguage: 'selectedLanguage',
  externalUserId: 'externalUserId',
  hasInitiatedSSOAuth: 'hasInitiatedSSOAuth',
  externalUserDisplayName: 'externalUserDisplayName',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserAccountSecretsScalarFieldEnum = {
  id: 'id',
  userAccountId: 'userAccountId',
  adminRoles: 'adminRoles',
  otpNumTries: 'otpNumTries',
  otpSecret: 'otpSecret',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserFileScalarFieldEnum = {
  id: 'id',
  uploaderUserId: 'uploaderUserId',
  spaceId: 'spaceId',
  path: 'path',
  type: 'type',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserInvitationScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  coreRole: 'coreRole',
  inviterId: 'inviterId',
  email: 'email',
  deskId: 'deskId',
  status: 'status',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserMapHistoryScalarFieldEnum = {
  id: 'id',
  historyCommands: 'historyCommands',
  undoOffset: 'undoOffset',
  mapId: 'mapId',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserMapHistoryRoleAssignmentScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  userId: 'userId',
  groupId: 'groupId',
  roleId: 'roleId',
  instanceId: 'instanceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.WearableScalarFieldEnum = {
  id: 'id',
  color: 'color',
  name: 'name',
  type: 'type',
  previewUrl: 'previewUrl',
  lastSyncAuthoredAt: 'lastSyncAuthoredAt',
  order: 'order',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.WearablePartScalarFieldEnum = {
  id: 'id',
  spritesheetUrl: 'spritesheetUrl',
  layer: 'layer',
  animationType: 'animationType',
  wearableId: 'wearableId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};
exports.SpaceSSOConnectionState = exports.$Enums.SpaceSSOConnectionState = {
  Active: 'Active',
  Inactive: 'Inactive',
  Draft: 'Draft',
  Validating: 'Validating'
};

exports.SpaceSSOEmailDomainVerificationState = exports.$Enums.SpaceSSOEmailDomainVerificationState = {
  Pending: 'Pending',
  Verified: 'Verified'
};

exports.CalendarEventProvider = exports.$Enums.CalendarEventProvider = {
  Google: 'Google'
};

exports.CatalogItemCategory = exports.$Enums.CatalogItemCategory = {
  RoomDecor: 'RoomDecor',
  WallDecor: 'WallDecor',
  Table: 'Table',
  Seating: 'Seating',
  Nature: 'Nature',
  Machine: 'Machine',
  FoodAndDrink: 'FoodAndDrink',
  CabinetsAndShelves: 'CabinetsAndShelves',
  Interactive: 'Interactive'
};

exports.CatalogItemTag = exports.$Enums.CatalogItemTag = {
  Furniture: 'Furniture',
  Decoration: 'Decoration'
};

exports.CatalogItemOrientation = exports.$Enums.CatalogItemOrientation = {
  Down: 'Down',
  Right: 'Right',
  Up: 'Up',
  Left: 'Left'
};

exports.ConnectionTarget = exports.$Enums.ConnectionTarget = {
  Default: 'Default',
  OfficeView: 'OfficeView',
  Studio: 'Studio'
};

exports.ExternalCalendarType = exports.$Enums.ExternalCalendarType = {
  Outlook: 'Outlook',
  Google: 'Google'
};

exports.MapStatus = exports.$Enums.MapStatus = {
  Draft: 'Draft',
  Active: 'Active',
  Archived: 'Archived'
};

exports.GoogleCalendarEventStatus = exports.$Enums.GoogleCalendarEventStatus = {
  Confirmed: 'Confirmed',
  Canceled: 'Canceled',
  Default: 'Default',
  Tentative: 'Tentative'
};

exports.GoogleCalendarEventType = exports.$Enums.GoogleCalendarEventType = {
  Birthday: 'Birthday',
  Default: 'Default',
  FocusTime: 'FocusTime',
  FromGmail: 'FromGmail',
  OutOfOffice: 'OutOfOffice',
  WorkingLocation: 'WorkingLocation'
};

exports.GoogleCalendarEventVisibility = exports.$Enums.GoogleCalendarEventVisibility = {
  default: 'default',
  public: 'public',
  private: 'private'
};

exports.GuestPassState = exports.$Enums.GuestPassState = {
  Pending: 'Pending',
  Admitted: 'Admitted',
  Denied: 'Denied'
};

exports.GuestPassStateReason = exports.$Enums.GuestPassStateReason = {
  AwaitingResponse: 'AwaitingResponse',
  HostRunningLate: 'HostRunningLate',
  HostAdmitted: 'HostAdmitted',
  HostDenied: 'HostDenied',
  KickedFromSpace: 'KickedFromSpace',
  TimeoutAutoDenied: 'TimeoutAutoDenied',
  MemberRemoved: 'MemberRemoved'
};

exports.MapAreaType = exports.$Enums.MapAreaType = {
  Public: 'Public',
  Lobby: 'Lobby',
  Common: 'Common',
  MeetingRoom: 'MeetingRoom',
  Team: 'Team',
  Desk: 'Desk'
};

exports.WallTextureKey = exports.$Enums.WallTextureKey = {
  NewStyleNoWall: 'NewStyleNoWall',
  PlainBlack: 'PlainBlack',
  PlainBrown: 'PlainBrown',
  PlainGreen: 'PlainGreen',
  PlainGray: 'PlainGray',
  PlainIndigo: 'PlainIndigo',
  PlainOrange: 'PlainOrange',
  PlainPink: 'PlainPink',
  PlainPurple: 'PlainPurple',
  PlainRed: 'PlainRed',
  PlainTan: 'PlainTan',
  PlainTeal: 'PlainTeal',
  PlainWhite: 'PlainWhite',
  PlainYellow: 'PlainYellow',
  NewStyleClean: 'NewStyleClean'
};

exports.GroundTextureKey = exports.$Enums.GroundTextureKey = {
  NewStyleGrass: 'NewStyleGrass',
  CarpetAfghani: 'CarpetAfghani',
  CarpetCouture: 'CarpetCouture',
  CarpetCurls: 'CarpetCurls',
  CarpetCurlsTeal: 'CarpetCurlsTeal',
  CarpetDiamond: 'CarpetDiamond',
  CarpetFloral: 'CarpetFloral',
  CarpetFloralPurple: 'CarpetFloralPurple',
  CarpetStripe: 'CarpetStripe',
  CarpetStripeGray: 'CarpetStripeGray',
  CarpetWave: 'CarpetWave',
  CarpetWaveGray: 'CarpetWaveGray',
  Damask: 'Damask',
  StoneBrick: 'StoneBrick',
  StoneTile: 'StoneTile',
  TileHex: 'TileHex',
  TileTwotone: 'TileTwotone',
  TileTwotoneOrange: 'TileTwotoneOrange',
  WoodBasketweave: 'WoodBasketweave',
  WoodHerringbone: 'WoodHerringbone',
  WoodHerringboneLt: 'WoodHerringboneLt',
  WoodParquet: 'WoodParquet',
  WoodSlats: 'WoodSlats',
  WoodSlatsLt: 'WoodSlatsLt',
  NewStyleSquares: 'NewStyleSquares',
  NewStyleTrianglesRugDark: 'NewStyleTrianglesRugDark',
  NewStyleTrianglesRug: 'NewStyleTrianglesRug',
  NewStyleGrassBordered: 'NewStyleGrassBordered',
  NewStyleWaterBordered: 'NewStyleWaterBordered',
  NewStylePlanks: 'NewStylePlanks',
  NewStylePlain: 'NewStylePlain',
  Grass: 'Grass',
  RugBlue: 'RugBlue',
  Wood: 'Wood',
  Snow: 'Snow',
  CarpetTexture: 'CarpetTexture'
};

exports.MeetingStatus = exports.$Enums.MeetingStatus = {
  Pending: 'Pending',
  Confirmed: 'Confirmed'
};

exports.MeetingType = exports.$Enums.MeetingType = {
  Scheduled: 'Scheduled',
  Unplanned: 'Unplanned',
  Ambient: 'Ambient',
  External: 'External'
};

exports.MeetingVisibility = exports.$Enums.MeetingVisibility = {
  Public: 'Public',
  Private: 'Private'
};

exports.MeetingJoinInfoStatus = exports.$Enums.MeetingJoinInfoStatus = {
  Pending: 'Pending',
  Confirmed: 'Confirmed'
};

exports.MeetingJoinRequestResponseStatus = exports.$Enums.MeetingJoinRequestResponseStatus = {
  Accepted: 'Accepted',
  Declined: 'Declined',
  Pending: 'Pending'
};

exports.MeetingParticipantInviteStatus = exports.$Enums.MeetingParticipantInviteStatus = {
  InvitedRequired: 'InvitedRequired',
  InvitedOptional: 'InvitedOptional',
  NotInvited: 'NotInvited'
};

exports.MeetingParticipantResponseStatus = exports.$Enums.MeetingParticipantResponseStatus = {
  Accepted: 'Accepted',
  TentativelyAccepted: 'TentativelyAccepted',
  Declined: 'Declined',
  Unknown: 'Unknown'
};

exports.PermissionsRoleType = exports.$Enums.PermissionsRoleType = {
  Default: 'Default',
  Custom: 'Custom',
  Dynamic: 'Dynamic'
};

exports.Permission = exports.$Enums.Permission = {
  AccessMeeting: 'AccessMeeting',
  SendChat: 'SendChat',
  SimulateDeploy: 'SimulateDeploy',
  SyncCalendars: 'SyncCalendars',
  DisconnectCalendars: 'DisconnectCalendars',
  InitiateAuthFlow: 'InitiateAuthFlow',
  EditMap: 'EditMap',
  CanDestroy: 'CanDestroy',
  CanReset: 'CanReset',
  CanRespond: 'CanRespond',
  CanKick: 'CanKick',
  EditMapArea: 'EditMapArea',
  ChangeLockState: 'ChangeLockState',
  EditMapEntity: 'EditMapEntity',
  EditMapObject: 'EditMapObject',
  CreateMeetingFromCalendarEvent: 'CreateMeetingFromCalendarEvent',
  AcceptMeeting: 'AcceptMeeting',
  AddParticipant: 'AddParticipant',
  RemoveParticipant: 'RemoveParticipant',
  RequestToJoin: 'RequestToJoin',
  AssignMeetingArea: 'AssignMeetingArea',
  Start: 'Start',
  End: 'End',
  Cancel: 'Cancel',
  RequestToMoveCluster: 'RequestToMoveCluster',
  CancelRequestToMoveCluster: 'CancelRequestToMoveCluster',
  UseBuildTool: 'UseBuildTool',
  UseStudio: 'UseStudio',
  UpdateUserRole: 'UpdateUserRole',
  InviteAdmin: 'InviteAdmin',
  InviteMemberOrGuest: 'InviteMemberOrGuest',
  GetSpaceFile: 'GetSpaceFile',
  DeleteSpaceFile: 'DeleteSpaceFile',
  CreateSpaceFile: 'CreateSpaceFile',
  AccessCalendar: 'AccessCalendar',
  CreateMeetingJoinInfo: 'CreateMeetingJoinInfo',
  UpdateSpaceSetting: 'UpdateSpaceSetting',
  EnterSpace: 'EnterSpace',
  SaveSpaceOutfit: 'SaveSpaceOutfit',
  Move: 'Move',
  UpdateCluster: 'UpdateCluster',
  UpdateName: 'UpdateName',
  UpdateProfilePicture: 'UpdateProfilePicture',
  UpdateHandRaised: 'UpdateHandRaised',
  UpdateSpeaking: 'UpdateSpeaking',
  UpdateFollowing: 'UpdateFollowing',
  UpdateAvailability: 'UpdateAvailability',
  BroadcastEmote: 'BroadcastEmote',
  ClaimUnclaimDesk: 'ClaimUnclaimDesk',
  ForceMute: 'ForceMute',
  RequestToLead: 'RequestToLead',
  RespondToRequestToLead: 'RespondToRequestToLead',
  UpdateTargetMeetingArea: 'UpdateTargetMeetingArea',
  SendWave: 'SendWave',
  UpdateOnboardingStatus: 'UpdateOnboardingStatus',
  EditSessionData: 'EditSessionData',
  EditMapHistory: 'EditMapHistory'
};

exports.CoreRoleType = exports.$Enums.CoreRoleType = {
  Admin: 'Admin',
  Member: 'Member',
  Guest: 'Guest'
};

exports.DeskType = exports.$Enums.DeskType = {
  Open: 'Open',
  Private: 'Private',
  Hybrid: 'Hybrid'
};

exports.OfficeStyle = exports.$Enums.OfficeStyle = {
  Startup: 'Startup',
  Antique: 'Antique',
  Zen: 'Zen',
  Eco: 'Eco'
};

exports.Direction = exports.$Enums.Direction = {
  Up: 'Up',
  Down: 'Down',
  Left: 'Left',
  Right: 'Right'
};

exports.Availability = exports.$Enums.Availability = {
  Active: 'Active',
  Focused: 'Focused',
  Busy: 'Busy',
  Away: 'Away',
  Offline: 'Offline'
};

exports.SpaceUserOnboardingTask = exports.$Enums.SpaceUserOnboardingTask = {
  LearnHowGatherMeetingsWork: 'LearnHowGatherMeetingsWork',
  HallwayConversations: 'HallwayConversations',
  NavigateYourOffice: 'NavigateYourOffice',
  ClaimYourDesk: 'ClaimYourDesk',
  InviteYourTeam: 'InviteYourTeam',
  GatherEssentials: 'GatherEssentials'
};

exports.StudioUserSessionTarget = exports.$Enums.StudioUserSessionTarget = {
  Studio: 'Studio',
  BuildTool: 'BuildTool',
  DeskManager: 'DeskManager',
  Unknown: 'Unknown'
};

exports.AdminRole = exports.$Enums.AdminRole = {
  Default: 'Default',
  Admin: 'Admin',
  Art: 'Art',
  ArtLead: 'ArtLead',
  Clients: 'Clients',
  ClientLead: 'ClientLead',
  Engineering: 'Engineering',
  Finance: 'Finance',
  MobileEngineering: 'MobileEngineering'
};

exports.UploadedFileType = exports.$Enums.UploadedFileType = {
  ScreenRecording: 'ScreenRecording',
  VirtualBackground: 'VirtualBackground',
  Profile: 'Profile'
};

exports.UserInvitationStatus = exports.$Enums.UserInvitationStatus = {
  Pending: 'Pending',
  Consumed: 'Consumed',
  Invalid: 'Invalid'
};

exports.WearableType = exports.$Enums.WearableType = {
  Skin: 'Skin',
  Hair: 'Hair',
  FacialHair: 'FacialHair',
  Top: 'Top',
  Bottom: 'Bottom',
  Shoes: 'Shoes',
  Hat: 'Hat',
  Glasses: 'Glasses',
  Other: 'Other',
  Costume: 'Costume',
  Mobility: 'Mobility',
  Jacket: 'Jacket'
};

exports.WearableLayer = exports.$Enums.WearableLayer = {
  MobilityBack: 'MobilityBack',
  OtherBack: 'OtherBack',
  HatBack: 'HatBack',
  HairBack: 'HairBack',
  JacketBack: 'JacketBack',
  SkinFront: 'SkinFront',
  BottomFront: 'BottomFront',
  ShoesFront: 'ShoesFront',
  TopFront: 'TopFront',
  JacketFront: 'JacketFront',
  OtherMiddle: 'OtherMiddle',
  GlassesFront: 'GlassesFront',
  FacialHairFront: 'FacialHairFront',
  HairFront: 'HairFront',
  HatFront: 'HatFront',
  OtherFront: 'OtherFront',
  MobilityFront: 'MobilityFront',
  CostumeFront: 'CostumeFront'
};

exports.AnimationType = exports.$Enums.AnimationType = {
  Essentials: 'Essentials',
  Extras: 'Extras'
};

exports.Prisma.ModelName = {
  BrowserVideoClientStateSession: 'BrowserVideoClientStateSession',
  SpaceWorkOSConnection: 'SpaceWorkOSConnection',
  SpaceSSOSettings: 'SpaceSSOSettings',
  SpaceSSOEmailDomain: 'SpaceSSOEmailDomain',
  SpaceSSOLinkedSpace: 'SpaceSSOLinkedSpace',
  UserDeletionForStaging: 'UserDeletionForStaging',
  UserFeedback: 'UserFeedback',
  BaseCombinedCalendarEvent: 'BaseCombinedCalendarEvent',
  BaseCombinedCalendarEventRoleAssignment: 'BaseCombinedCalendarEventRoleAssignment',
  CatalogItem: 'CatalogItem',
  CatalogItemVariant: 'CatalogItemVariant',
  ChatMessage: 'ChatMessage',
  Connection: 'Connection',
  ConnectionRoleAssignment: 'ConnectionRoleAssignment',
  ExternalCalendar: 'ExternalCalendar',
  ExternalCalendarConnection: 'ExternalCalendarConnection',
  ExternalCalendarConnectionAccess: 'ExternalCalendarConnectionAccess',
  ExternalCalendarConnectionAccessRoleAssignment: 'ExternalCalendarConnectionAccessRoleAssignment',
  ExternalCalendarConnectionRoleAssignment: 'ExternalCalendarConnectionRoleAssignment',
  ExternalCalendarConnectionSecrets: 'ExternalCalendarConnectionSecrets',
  ExternalCalendarRoleAssignment: 'ExternalCalendarRoleAssignment',
  Floor: 'Floor',
  FloorMap: 'FloorMap',
  FloorRoleAssignment: 'FloorRoleAssignment',
  GoogleCalendarEvent: 'GoogleCalendarEvent',
  GuestPass: 'GuestPass',
  GuestPassRoleAssignment: 'GuestPassRoleAssignment',
  MapArea: 'MapArea',
  MapAreaRoleAssignment: 'MapAreaRoleAssignment',
  MapEntityIdentifier: 'MapEntityIdentifier',
  MapGroup: 'MapGroup',
  MapObject: 'MapObject',
  MapObjectRoleAssignment: 'MapObjectRoleAssignment',
  Meeting: 'Meeting',
  MeetingJoinInfo: 'MeetingJoinInfo',
  MeetingJoinInfoRoleAssignment: 'MeetingJoinInfoRoleAssignment',
  MeetingJoinRequest: 'MeetingJoinRequest',
  MeetingJoinRequestRoleAssignment: 'MeetingJoinRequestRoleAssignment',
  MeetingParticipant: 'MeetingParticipant',
  MeetingParticipantRoleAssignment: 'MeetingParticipantRoleAssignment',
  MeetingRoleAssignment: 'MeetingRoleAssignment',
  OutfitTemplate: 'OutfitTemplate',
  PermissionsGroup: 'PermissionsGroup',
  PermissionsGroupUser: 'PermissionsGroupUser',
  PermissionsRole: 'PermissionsRole',
  Space: 'Space',
  SpaceInvitation: 'SpaceInvitation',
  SpaceRoleAssignment: 'SpaceRoleAssignment',
  SpaceSettings: 'SpaceSettings',
  SpaceSettingsRoleAssignment: 'SpaceSettingsRoleAssignment',
  SpaceTemplate: 'SpaceTemplate',
  SpaceTemplateRoleAssignment: 'SpaceTemplateRoleAssignment',
  SpaceUser: 'SpaceUser',
  SpaceUserCluster: 'SpaceUserCluster',
  SpaceUserOnboarding: 'SpaceUserOnboarding',
  SpaceUserOnboardingRoleAssignment: 'SpaceUserOnboardingRoleAssignment',
  SpaceUserOutfit: 'SpaceUserOutfit',
  SpaceUserRoleAssignment: 'SpaceUserRoleAssignment',
  StagedDeskAssignment: 'StagedDeskAssignment',
  StudioUserSession: 'StudioUserSession',
  StudioUserSessionRoleAssignment: 'StudioUserSessionRoleAssignment',
  UserAccount: 'UserAccount',
  UserAccountSecrets: 'UserAccountSecrets',
  UserFile: 'UserFile',
  UserInvitation: 'UserInvitation',
  UserMapHistory: 'UserMapHistory',
  UserMapHistoryRoleAssignment: 'UserMapHistoryRoleAssignment',
  Wearable: 'Wearable',
  WearablePart: 'WearablePart'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
