import { initContract } from "@ts-rest/core"

import { UserFeedbackPrisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const feedback = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminAvClientStateUserFeedback,
    responses: { 200: c.type<UserFeedbackPrisma>() },
  },
})
