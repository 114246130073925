import { DateRange } from "./DateRange"
import { Dimensions } from "./Dimensions"
import { Direction } from "./Direction"
import { Position } from "./Position"
import { SpaceUserAvailability } from "./SpaceUserAvailability"
import { Speed } from "./Speed"

/**
 * The source of truth of all value objects usable in the GS.
 * Value objects must be defined here for the GS to understand them.
 */
export const VALUE_OBJECTS = {
  Position,
  DateRange,
  Dimensions,
  Speed,
  Direction,
  SpaceUserAvailability,
} as const
