import { initContract } from "@ts-rest/core"

import { avClientStateSessions } from "./contracts/admin/avClientStateSessions/avClientStateSessions"
import { feedback } from "./contracts/admin/avClientStateSessions/feedback"
import { catalogItems as adminCatalogItems } from "./contracts/admin/catalogItems"
import { catalogItemVariants as adminCatalogItemVariants } from "./contracts/admin/catalogItemVariants"
import { spaces as adminSpaces } from "./contracts/admin/spaces"
import { capacity as adminSpaceCapacity } from "./contracts/admin/spaces/capacity"
import { users as adminSpaceUsers } from "./contracts/admin/spaces/users"
import { superAdminUsers } from "./contracts/admin/superAdminUsers"
import { roles as superAdminUserRoles } from "./contracts/admin/superAdminUsers/roles"
import { spaces as adminUserSpaces } from "./contracts/admin/users/spaces"
import { users as usersFromAdminPortal } from "./contracts/admin/users/users"
import { wearables as adminWearables } from "./contracts/admin/wearables"
import { google } from "./contracts/auth/google"
import { otpRequests } from "./contracts/auth/otpRequests"
import { sso as authSSO } from "./contracts/auth/sso"
import { browserExtension } from "./contracts/browserExtension"
import { hubspot } from "./contracts/hubspot"
import { cloudflare } from "./contracts/integrations/cloudflare"
import { logs } from "./contracts/internals/logs"
import { newRelic } from "./contracts/internals/newRelic"
import { releases } from "./contracts/releases"
import { deleteRecordings } from "./contracts/scheduledTasks/deleteRecordings"
import { spaces } from "./contracts/spaces"
import { files as spaceFiles } from "./contracts/spaces/files"
import { insights } from "./contracts/spaces/insights"
import { nooks } from "./contracts/spaces/nooks"
import { sso } from "./contracts/spaces/sso"
import { emailDomains } from "./contracts/spaces/sso/emailDomains"
import { linkedSpaces } from "./contracts/spaces/sso/linkedSpaces"
import { source } from "./contracts/spaces/sso/linkedSpaces/source"
import { settings as ssoSettings } from "./contracts/spaces/sso/settings"
import { onboarding as surveyOnboarding } from "./contracts/spaces/surveys/onboarding"
import { users as spaceUsers } from "./contracts/spaces/users"
import { supportRequests } from "./contracts/supportRequests"
import { users } from "./contracts/users"
import { spaces as userSpaces } from "./contracts/users/spaces"
import { wearables } from "./contracts/wearables"
import { assemblyAiHooks } from "./contracts/webhooks/assemblyAi"
import { audioRecordingHook } from "./contracts/webhooks/audioRecording"
import { meetingSummaryHook } from "./contracts/webhooks/meetingSummary"
import { workos } from "./contracts/webhooks/workos"
import { meetingJoinInfo } from "./v2/contracts/meetingJoinInfo"
import { areas as areasV2 } from "./v2/contracts/spaces/areas"
import { floorPreview } from "./v2/contracts/spaces/floors/floorPreview"
import { meetingJoinInfo as spaceMeetingJoinInfo } from "./v2/contracts/spaces/meetingJoinInfo"
import { baseCalendarEvents } from "./v2/contracts/spaces/users/baseCalendarEvents"
import { spaceTemplates } from "./v2/contracts/spaceTemplates"

const c = initContract()

export const contracts = c.router({
  v2: {
    spaces: {
      areas: areasV2,
      meetingJoinInfo: spaceMeetingJoinInfo,
      users: {
        baseCalendarEvents,
      },
    },
    meetingJoinInfo,
    spaceTemplates,
  },
  auth: {
    otpRequests,
    sso: authSSO,
    google,
  },

  hubspot,

  integrations: {
    cloudflare,
  },

  internals: {
    newRelic,
    logs,
  },

  scheduledTasks: {
    deleteRecordings,
  },

  spaces: {
    ...spaces,
    files: spaceFiles,
    insights,
    nooks,
    sso: {
      ...sso,
      emailDomains,
      linkedSpaces: {
        ...linkedSpaces,
        source,
      },
      settings: ssoSettings,
    },
    surveys: {
      onboarding: surveyOnboarding,
    },
    users: {
      ...spaceUsers,
    },
    floors: {
      preview: floorPreview,
    },
  },

  releases,

  supportRequests,

  users: {
    ...users,
    spaces: {
      ...userSpaces,
    },
  },

  wearables,

  webhooks: {
    workos,
    audioRecording: audioRecordingHook,
    assemblyAi: assemblyAiHooks,
    meetingSummary: meetingSummaryHook,
  },
  admin: {
    avClientStateSessions: {
      ...avClientStateSessions,
      feedback,
    },
    users: {
      ...usersFromAdminPortal,
      spaces: adminUserSpaces,
    },
    spaces: {
      ...adminSpaces,
      capacity: adminSpaceCapacity,
      users: adminSpaceUsers,
    },
    wearables: adminWearables,
    catalogItems: {
      ...adminCatalogItems,
      variants: adminCatalogItemVariants,
    },
    superAdminUsers: {
      ...superAdminUsers,
      roles: superAdminUserRoles,
    },
  },
  browserExtension,
})
