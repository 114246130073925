import { getFixedClassRef } from "gather-common/dist/src/public/getFixedClassRef"
import { ga } from "gather-common/dist/src/public/mobx/decorators"
import { BaseValueObject } from "gather-state-sync/dist/src/public/BaseValueObject"
import { s } from "../framework/schema/schema"
import { TILE_SIZE } from "../models/mapEntity/constants"
import { Position } from "./Position"

export const dimensionsSchema = s.object({
  width: s.number(),
  height: s.number(),
})

const EPSILON = 0.0001

@ga.observableClass
export class Dimensions extends BaseValueObject(dimensionsSchema.modelZod) {
  toPositions(origin: Position): Position[] {
    const values = []
    for (let i = 0; i < this.width; ++i) {
      for (let j = 0; j < this.height; ++j) {
        values.push(new Position(origin.x + i, origin.y + j))
      }
    }
    return values
  }

  static fromPixels({ width, height }: { width: number; height: number }) {
    const Dimensions_ = getFixedClassRef(this, Dimensions)
    return new Dimensions_({
      width: width / TILE_SIZE,
      height: height / TILE_SIZE,
    })
  }

  fromPixels() {
    return getFixedClassRef(this, Dimensions).fromPixels({ width: this.width, height: this.height })
  }

  static toPixels({ width, height }: { width: number; height: number }) {
    const Dimensions_ = getFixedClassRef(this, Dimensions)
    return new Dimensions_({
      width: width * TILE_SIZE,
      height: height * TILE_SIZE,
    })
  }

  toPixels() {
    return getFixedClassRef(this, Dimensions).toPixels({ width: this.width, height: this.height })
  }

  scale(scalar: number) {
    const Dimensions_ = getFixedClassRef(this, Dimensions)
    return new Dimensions_({
      width: this.width * scalar,
      height: this.height * scalar,
    })
  }

  static isEqual(a: Dimensions, b: Dimensions) {
    return Math.abs(a.width - b.width) < EPSILON && Math.abs(a.height - b.height) < EPSILON
  }

  equals(other: Dimensions) {
    return getFixedClassRef(this, Dimensions).isEqual(this, other)
  }
}
