import { Link, TableCell, TableRow, Typography } from "@mui/material"
import ChipList from "components/dashboard/ChipList"
import React, { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { CoreRoleTypePrisma, SpacePrisma } from "gather-prisma-types/dist/src/public/client"

interface Props {
  space: SpacePrisma
  role?: CoreRoleTypePrisma
  userId?: string // confusing name, this is a firebaseAuthId
}

const UserSpaceRow: FC<Props> = ({ space, role, userId: _userId }) => (
  <TableRow hover key={space.id}>
    <TableCell>
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/dashboard/spaces?space=${encodeURIComponent(space.id)}`}
        underline="always"
        variant="subtitle2"
      >
        {space.id}
      </Link>
    </TableCell>

    <TableCell>
      <Typography color="textSecondary" variant="body2">
        {space.name}
      </Typography>
    </TableCell>

    <TableCell>{role && <ChipList list={[role]} />}</TableCell>
  </TableRow>
)

export default UserSpaceRow
