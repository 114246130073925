import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { zodUuid } from "../../../zodHelpers"

const c = initContract()

export const meetingJoinInfo = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceMeetingJoinInfo,
    pathParams: z.object({ spaceId: zodUuid }),
    body: z.object({
      areaId: zodUuid.nullable(),
    }),
    responses: {
      200: c.type<{ linkId: string; areaId?: Uuid }>(),
      400: c.type<{ error: string }>(),
    },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.SpaceMeetingJoinInfoLink,
    pathParams: z.object({ spaceId: zodUuid, linkId: z.string() }),
    body: z.object({
      areaId: zodUuid.nullable(),
    }),
    responses: {
      200: c.type<{ linkId: string; areaId?: Uuid }>(),
      404: c.type<{ error: string }>(),
    },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceMeetingJoinInfoLink,
    pathParams: z.object({ spaceId: zodUuid, linkId: z.string() }),
    responses: {
      200: c.type<{ linkId: string; areaId?: Uuid; areaName?: string }>(),
      404: c.type<{ error: string }>(),
    },
  },
})
