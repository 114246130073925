import QueryKeys from "features/queryKeys"
import { useQuery, UseQueryResult } from "react-query"

import { getSpaceTemplates } from "../../api/spaceTemplates"

/**
 * Fetches a list of space templates using React Query
 * @returns React Query object where data is type of SpaceTemplatePrismaResource[] | undefined
 */
export const useSpaceTemplates = (): UseQueryResult<
  Awaited<ReturnType<typeof getSpaceTemplates>>
> =>
  useQuery([QueryKeys.SpaceTemplates], () => getSpaceTemplates(), {
    initialData: [],
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false,
  })
