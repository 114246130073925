import { DateTime as LuxonDateTime, Settings as LuxonSettings } from "luxon"

import { isNotNil } from "gather-common-including-video/dist/src/public/fpHelpers"

type WrapLuxonDateTimeArgTypes<T extends unknown[]> = {
  [K in keyof T]: T[K] extends LuxonDateTime ? DateTimeExt : T[K]
}

type UnwrapLuxonDateTimeArgTypes<T extends unknown[]> = {
  [K in keyof T]: T[K] extends DateTimeExt ? LuxonDateTime : T[K]
}

// This is the format we use for storing dates in the database. It's a stripped down version of the
// ISO format that doesn't include the timezone offset since we only format UTC dates. The "T" is
// the ISO separator and the Z represents the separator before the timezone. This is a format that
// is pulled from externally, and matches timestamps used to generate IDs like from the Google
// Calendar API.
const STRIPPED_UTC_ISO_STRING_FORMAT = "yyyyMMdd'T'HHmmss'Z'"

// Similar to above, this format is used for dates as a string without a time component. This format
// is used by the Google Calendar API for all-day event id prefixes.
const STRIPPED_DATE_ONLY_ISO_STRING_FORMAT = "yyyyMMdd"

/**
 * A wrapper around Luxon's DateTime class to allow us to pick from and extend its functionality.
 * Methods can be forwarded by pattern matching from "Forwarded Luxon methods" below.
 *
 * We should be mindful of returning a similar type to Luxon's interface to keep the interfaces
 * relatively the same (since this enables us to continue to use Luxon's documentation of their
 * interface for reference rather than having to write our own). Any methods that return a Luxon
 * DateTime should be wrapped with DateTimeExt (see `now` and `fromISO` for examples of this).
 */
export class DateTimeExt {
  protected constructor(private luxonDateTime: LuxonDateTime) {}

  /**
   * The Luxon method expects a Luxon DateTime instance, but we're taking in a DateTimeExt instance.
   * This is a helper to unwrap arguments passed to DateTimeExt methods so that we can forward them
   * to their underlying Luxon method.
   */
  static unwrapLuxonDateTimeFromArgs<T extends unknown[]>(args: T): UnwrapLuxonDateTimeArgTypes<T> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return args.map((arg) =>
      arg instanceof DateTimeExt ? arg.luxonDateTime : arg,
    ) as UnwrapLuxonDateTimeArgTypes<T>
  }

  static fromISOOrUndefined(iso8601?: string): DateTimeExt | undefined {
    return isNotNil(iso8601) ? DateTimeExt.fromISO(iso8601) : undefined
  }

  // ================================
  // [START] Forwarded Luxon methods
  static now() {
    return new DateTimeExt(LuxonDateTime.now())
  }

  static utc() {
    return new DateTimeExt(LuxonDateTime.utc())
  }

  static fromISO(...args: WrapLuxonDateTimeArgTypes<Parameters<typeof LuxonDateTime.fromISO>>) {
    return new DateTimeExt(LuxonDateTime.fromISO(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)))
  }

  static fromJSDate(
    ...args: WrapLuxonDateTimeArgTypes<Parameters<typeof LuxonDateTime.fromJSDate>>
  ) {
    return new DateTimeExt(
      LuxonDateTime.fromJSDate(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  static fromMillis(
    ...args: WrapLuxonDateTimeArgTypes<Parameters<typeof LuxonDateTime.fromMillis>>
  ) {
    return new DateTimeExt(
      LuxonDateTime.fromMillis(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  static fromObject(
    ...args: WrapLuxonDateTimeArgTypes<Parameters<typeof LuxonDateTime.fromObject>>
  ) {
    return new DateTimeExt(
      LuxonDateTime.fromObject(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  static fromStrippedUtcIsoString(strippedIsoString: string) {
    return new DateTimeExt(
      LuxonDateTime.fromFormat(strippedIsoString, STRIPPED_UTC_ISO_STRING_FORMAT, { zone: "utc" }),
    )
  }

  static min(...args: WrapLuxonDateTimeArgTypes<Parameters<typeof LuxonDateTime.min>>) {
    return new DateTimeExt(LuxonDateTime.min(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)))
  }

  static max(...args: WrapLuxonDateTimeArgTypes<Parameters<typeof LuxonDateTime.max>>) {
    return new DateTimeExt(LuxonDateTime.max(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)))
  }

  static isDateTime(maybeDateTime: unknown): maybeDateTime is DateTimeExt {
    return maybeDateTime instanceof DateTimeExt
  }

  minus(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["minus"]>>) {
    return new DateTimeExt(
      this.luxonDateTime.minus(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  plus(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["plus"]>>) {
    return new DateTimeExt(
      this.luxonDateTime.plus(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  set(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["set"]>>) {
    return new DateTimeExt(this.luxonDateTime.set(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)))
  }

  diff(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["diff"]>>) {
    return this.luxonDateTime.diff(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  diffNow(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["diffNow"]>>) {
    return this.luxonDateTime.diffNow(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  toMillis(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["toMillis"]>>) {
    return this.luxonDateTime.toMillis(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  toISO(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["toISO"]>>) {
    return this.luxonDateTime.toISO(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  toUTC(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["toUTC"]>>) {
    return new DateTimeExt(
      this.luxonDateTime.toUTC(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  valueOf(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["valueOf"]>>) {
    return this.luxonDateTime.valueOf(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  hasSame(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["hasSame"]>>) {
    return this.luxonDateTime.hasSame(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  startOf(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["startOf"]>>) {
    return new DateTimeExt(
      this.luxonDateTime.startOf(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  endOf(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["endOf"]>>) {
    return new DateTimeExt(
      this.luxonDateTime.endOf(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  toLocaleString(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["toLocaleString"]>>) {
    return this.luxonDateTime.toLocaleString(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  equals(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["equals"]>>) {
    return this.luxonDateTime.equals(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  setZone(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["setZone"]>>) {
    return new DateTimeExt(
      this.luxonDateTime.setZone(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args)),
    )
  }

  toRelative(...args: WrapLuxonDateTimeArgTypes<Parameters<LuxonDateTime["toRelative"]>>) {
    return this.luxonDateTime.toRelative(...DateTimeExt.unwrapLuxonDateTimeFromArgs(args))
  }

  get year() {
    return this.luxonDateTime.year
  }

  get quarter() {
    return this.luxonDateTime.quarter
  }

  get month() {
    return this.luxonDateTime.month
  }

  get day() {
    return this.luxonDateTime.day
  }

  get weekday() {
    return this.luxonDateTime.weekday
  }

  get hour() {
    return this.luxonDateTime.hour
  }

  get minute() {
    return this.luxonDateTime.minute
  }

  get second() {
    return this.luxonDateTime.second
  }

  get millisecond() {
    return this.luxonDateTime.millisecond
  }

  get isWeekend() {
    return this.luxonDateTime.isWeekend
  }

  get offset() {
    return this.luxonDateTime.offset
  }

  get zoneName() {
    return this.luxonDateTime.zoneName
  }

  static get DATE_SHORT() {
    return LuxonDateTime.DATE_SHORT
  }

  static get DATETIME_SHORT() {
    return LuxonDateTime.DATETIME_SHORT
  }

  static get TIME_SIMPLE() {
    return LuxonDateTime.TIME_SIMPLE
  }
  // [END] Forwarded Luxon methods
  // ================================

  get monthAndYearString() {
    return this.toLocaleString({ month: "long", year: "numeric" })
  }

  get weekdayShortString() {
    return this.toLocaleString({ weekday: "short" })
  }

  get timeString() {
    return this.toLocaleString(DateTimeExt.TIME_SIMPLE).toLocaleLowerCase()
  }

  toLuxonDateTime() {
    return this.luxonDateTime
  }

  // This still needs to get called in places where we're referencing v1 code
  toJSDate() {
    return this.luxonDateTime.toJSDate()
  }

  toStrippedUtcIsoString() {
    return this.luxonDateTime.toUTC().toFormat(STRIPPED_UTC_ISO_STRING_FORMAT)
  }

  toStrippedDateOnlyIsoString() {
    return this.luxonDateTime.toUTC().toFormat(STRIPPED_DATE_ONLY_ISO_STRING_FORMAT)
  }
}

// Re-export Luxon settings from the correct path so we can set values for testing (such as Locale or Timezone).
export { LuxonSettings }
