/* Material Icons */
import { Check } from "@mui/icons-material"
import { Switch } from "@mui/material"
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { CatalogItemVariantResource } from "gather-common/dist/src/public/v2/resources/catalogItems"
import { useUpdateCatalogItemVariant } from "../../../features/catalogItems/hooks"

interface Props {
  catalogItemVariant: CatalogItemVariantResource
}

const VariantRow: FC<Props> = ({ catalogItemVariant }) => {
  const { mutate } = useUpdateCatalogItemVariant()
  return (
    <TableRow hover>
      <TableCell>
        <img src={`https://static.gather.town${catalogItemVariant.mainRenderable__imageUrl}`} />
      </TableCell>
      <TableCell>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          {catalogItemVariant.orientation}
        </Box>
      </TableCell>
      <TableCell>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          {catalogItemVariant.color}
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          {catalogItemVariant.isDefault && <Check />}
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          {`${catalogItemVariant.dimensionsInPixels__width} x ${catalogItemVariant.dimensionsInPixels__height}px`}
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          {`${catalogItemVariant.originX}, ${catalogItemVariant.originY}`}
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          {catalogItemVariant.mainRenderable__fold}
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          {JSON.stringify(catalogItemVariant.sittable)}
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          {JSON.stringify(catalogItemVariant.collision)}
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          <Switch
            checked={catalogItemVariant.isPublished}
            onChange={(_, checked) => mutate({ id: catalogItemVariant.id, isPublished: checked })}
          />
        </Box>
      </TableCell>
    </TableRow>
  )
}
export default VariantRow
