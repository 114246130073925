export enum ModelKeys {
  BaseCalendarEvent = "BaseCalendarEvent",
  CatalogItem = "CatalogItem",
  CatalogItemVariant = "CatalogItemVariant",
  Chat = "Chat",
  ChatMessage = "ChatMessage",
  BaseCombinedCalendarEvent = "BaseCombinedCalendarEvent",
  BaseCombinedCalendarEventRoleAssignment = "BaseCombinedCalendarEventRoleAssignment",
  Connection = "Connection",
  ConnectionRoleAssignment = "ConnectionRoleAssignment",
  DeploySimulation = "DeploySimulation",
  ExternalCalendar = "ExternalCalendar",
  ExternalCalendarConnection = "ExternalCalendarConnection",
  ExternalCalendarConnectionAccess = "ExternalCalendarConnectionAccess",
  ExternalCalendarConnectionAccessRoleAssignment = "ExternalCalendarConnectionAccessRoleAssignment",
  ExternalCalendarConnectionRoleAssignment = "ExternalCalendarConnectionRoleAssignment",
  ExternalCalendarConnectionSecrets = "ExternalCalendarConnectionSecrets",
  ExternalCalendarRoleAssignment = "ExternalCalendarRoleAssignment",
  Floor = "Floor",
  FloorMap = "FloorMap",
  FloorRoleAssignment = "FloorRoleAssignment",
  GoogleCalendarEvent = "GoogleCalendarEvent",
  GuestPass = "GuestPass",
  GuestPassRoleAssignment = "GuestPassRoleAssignment",
  Invitation = "Invitation",
  MapArea = "MapArea",
  MapAreaRoleAssignment = "MapAreaRoleAssignment",
  MapEntity = "MapEntityV2",
  MapEntityIdentifier = "MapEntityIdentifier",
  MapGroup = "MapGroup",
  MapObject = "MapObject",
  MapObjectRoleAssignment = "MapObjectRoleAssignment",
  Meeting = "Meeting",
  MeetingJoinInfo = "MeetingJoinInfo",
  MeetingJoinInfoRoleAssignment = "MeetingJoinInfoRoleAssignment",
  MeetingJoinRequest = "MeetingJoinRequest",
  MeetingJoinRequestRoleAssignment = "MeetingJoinRequestRoleAssignment",
  MeetingParticipant = "MeetingParticipant",
  MeetingParticipantRoleAssignment = "MeetingParticipantRoleAssignment",
  MeetingRoleAssignment = "MeetingRoleAssignment",
  MoveClusterToMeeting = "MoveClusterToMeeting",
  OutfitTemplate = "OutfitTemplate",
  Permission = "Permission",
  PermissionsGroup = "PermissionsGroup",
  PermissionsGroupUser = "PermissionsGroupUser",
  PermissionsRole = "PermissionsRole",
  Space = "Space",
  SpaceInvitation = "SpaceInvitation",
  SpaceRoleAssignment = "SpaceRoleAssignment",
  SpaceSettings = "SpaceSettings",
  SpaceSettingsRoleAssignment = "SpaceSettingsRoleAssignment",
  SpaceTemplate = "SpaceTemplate",
  SpaceTemplateRoleAssignment = "SpaceTemplateRoleAssignment",
  SpaceUser = "SpaceUser",
  SpaceUserCluster = "SpaceUserCluster",
  SpaceUserOnboarding = "SpaceUserOnboarding",
  SpaceUserOnboardingRoleAssignment = "SpaceUserOnboardingRoleAssignment",
  SpaceUserOutfit = "SpaceUserOutfit",
  SpaceUserRoleAssignment = "SpaceUserRoleAssignment",
  StagedDeskAssignment = "StagedDeskAssignment",
  StudioUserSession = "StudioUserSession",
  StudioUserSessionRoleAssignment = "StudioUserSessionRoleAssignment",
  UserAccount = "UserAccount",
  UserAccountSecrets = "UserAccountSecrets",
  UserFile = "UserFile",
  UserInvitation = "UserInvitation",
  UserMapHistory = "UserMapHistory",
  UserMapHistoryRoleAssignment = "UserMapHistoryRoleAssignment",
  Wave = "Wave",
  Wearable = "Wearable",
  WearablePart = "WearablePart",
}

export enum EnumKeys {
  AdminRole = "AdminRole",
  AnimationType = "AnimationType",
  Availability = "Availability",
  CalendarEventProvider = "CalendarEventProvider",
  CalendarEventResponseStatus = "CalendarEventResponseStatus",
  CatalogItemCategory = "CatalogItemCategory",
  CatalogItemOrientation = "CatalogItemOrientation",
  CatalogItemTag = "CatalogItemTag",
  ConnectionTarget = "ConnectionTarget",
  CoreRoleType = "CoreRoleType",
  DeskType = "DeskType",
  Direction = "Direction",
  ExternalCalendarType = "ExternalCalendarType",
  GoogleCalendarAttendeeResponseStatus = "GoogleCalendarAttendeeResponseStatus",
  GoogleCalendarEventStatus = "GoogleCalendarEventStatus",
  GoogleCalendarEventType = "GoogleCalendarEventType",
  GoogleCalendarEventVisibility = "GoogleCalendarEventVisibility",
  GroundTextureKey = "GroundTextureKey",
  GuestPassState = "GuestPassState",
  GuestPassStateReason = "GuestPassStateReason",
  MapAreaType = "MapAreaType",
  MapStatus = "MapStatus",
  MeetingJoinInfoStatus = "MeetingJoinInfoStatus",
  MeetingJoinRequestResponseStatus = "MeetingJoinRequestResponseStatus",
  MeetingParticipantInviteStatus = "MeetingParticipantInviteStatus",
  MeetingParticipantResponseStatus = "MeetingParticipantResponseStatus",
  MeetingStatus = "MeetingStatus",
  MeetingType = "MeetingType",
  MeetingVisibility = "MeetingVisibility",
  OfficeStyle = "OfficeStyle",
  PermissionsRoleType = "PermissionsRoleType",
  SpaceUserOnboardingTask = "SpaceUserOnboardingTask",
  StudioUserSessionTarget = "StudioUserSessionTarget",
  UploadedFileType = "UploadedFileType",
  UserInvitationStatus = "UserInvitationStatus",
  WallTextureKey = "WallTextureKey",
  WearableLayer = "WearableLayer",
  WearableType = "WearableType",
}
