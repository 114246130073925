/**
 * Promise-related helpers.
 */

import { splitEvery } from "ramda"

/**
 * Iterates through a collection and executes an async callback **serially**. It's equivalent to
 *
 *     for (const x of xs) { await doSomething(x) }
 *
 * Ramda has a famously bad relationship with Promises, so we need to add our own async-compatible
 * helpers like these.
 */
export const forEachAsync = async <T>(xs: T[], cb: (arg: T) => Promise<void>): Promise<void> => {
  for (const x of xs) {
    await cb(x)
  }
}

/**
 * Iterates through a collection and executes an async callback **serially**. If the callback returns true,
 * the iteration is stopped.
 **/
export const forEachAsyncUntil = async <T>(
  xs: T[],
  cb: (arg: T) => Promise<boolean | undefined>,
): Promise<void> => {
  for (const x of xs) {
    const result = await cb(x)

    if (result) {
      break
    }
  }
}

export type DeferredPromise<T> = {
  promise: Promise<T>
  resolve: (value: T) => void
  reject: (reason?: Error) => void
}

/**
 * sets up a promise and its callbacks, so you can do whatever with them
 */
export function deferredPromise(): DeferredPromise<void>
export function deferredPromise<T>(): DeferredPromise<T>
export function deferredPromise<T>(): {
  promise: Promise<T>
  resolve: (_: T) => void
  reject: (_reason?: Error | undefined) => void
} {
  let resolve = (_: T) => {}
  let reject = (_reason?: Error) => {}
  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })
  return { promise, resolve, reject }
}

/**
 * returns a promise resolving after @param ms milliseconds
 */
export const sleep = async (ms: number): Promise<unknown> =>
  new Promise((resolve) => setTimeout(resolve, ms))

/**
 * returns a promise that is rejected after @param ms milliseconds
 */
export const sleepAndReject = async (ms: number, message?: string) => {
  await sleep(ms)
  throw new Error(message)
}

/**
 * returns a promise that is resolved when either @param promise is resolved or @param ms
 * have elapsed.  The resulting promise returns an object wrapping the resulting `value`
 * of the original promise and a `timedout` property (true if @param promise didn't resolve
 * in time, false otherwise).
 *
 * clears the internal `setTimeout` when the @param promise is resolved so that jest tests
 * don't have to wait around for the timeout to elapse before exiting.
 */
export const timeout = <T>(
  promise: Promise<T>,
  ms: number,
): Promise<
  | {
      timedout: true
    }
  | {
      timedout: false
      value: T
    }
> => {
  let timeout: NodeJS.Timeout

  const timeoutPromise = new Promise<{
    timedout: true
  }>((resolve) => (timeout = setTimeout(() => resolve({ timedout: true }), ms)))

  return Promise.race([
    promise
      .then((value) => ({ timedout: false, value } as const))
      .finally(() => clearTimeout(timeout)),
    timeoutPromise,
  ])
}

/**
 * A little helper to indicate the places where we provide a catch to silence the warning about
 * "Promise returned is ignored" but aren't yet providing error handling.
 */
export const explicitlyDoNotHandleError = (): void => {}

/**
 * Helpers for refining and filtering results from Promise.allSettled
 */
export const isFulfilled = <T>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
  p.status === "fulfilled"
export const isRejected = <T>(p: PromiseSettledResult<T>): p is PromiseRejectedResult =>
  p.status === "rejected"
export const fulfilledPromises = <T>(p: PromiseSettledResult<T>[]): PromiseFulfilledResult<T>[] =>
  p.filter(isFulfilled)
export const rejectedPromises = <T>(p: PromiseSettledResult<T>[]): PromiseRejectedResult[] =>
  p.filter(isRejected)

/**
 * Calls the argument array (currently limited to a single argument) on an async function
 * in batches specified by the chunkSizeLimit.
 *
 * For example, if you have an async function like `wait(seconds: number)`, calling
 * chunkAndBatchInSeriesAsync(wait, [1, 2, 1, 2], 2) will run the first two `wait`s in parallel
 * and resolve after 2 seconds, and then the following 2 will run and resolve after 2 seconds.
 */
export async function chunkAndBatchInSeriesAsync<ArgT, ArgR>(
  fn: (arg: ArgT) => Promise<ArgR>,
  argArray: ArgT[],
  chunkSizeLimit: number,
): Promise<ArgR[]> {
  const chunkedArgs = splitEvery(chunkSizeLimit, argArray)

  return chunkedArgs.reduce<Promise<ArgR[]>>(async (allValues, chunk) => {
    // Await the accumulator first to force reduce to run in series; otherwise it's in parallel.
    // https://advancedweb.hu/how-to-use-async-functions-with-array-reduce-in-javascript/#await-memo-first
    const allValuesResolved = await allValues

    const resolvedValues = await Promise.all(chunk.map((args) => fn(args)))

    return [...allValuesResolved, ...resolvedValues]
  }, Promise.resolve([]))
}

// This wrapper ensures that at any given point in time, only one call to the given async function is running, and
// ignores any other calls that are made to this function while it is active, except for the last one
export function debounceAsync<ArgsT extends unknown[], ReturnT>(
  f: (...args: ArgsT) => Promise<ReturnT>,
): (...args: ArgsT) => Promise<ReturnT | undefined> {
  let queuedArgs: ArgsT | undefined = undefined
  let executionLock = false

  // This function runs the passed function and has the following behavior:
  // - If the function returns normally, it returns what the function returns
  // - If there isn't anything queued, and the function throws, this throws and releases the executionLock
  // - If there is something queued, and the function throws, this returns undefined
  // In the third case, the caller of this function is responsible for continuing with calling the queued function
  const runAndReleaseLockIfThrowsWithNothingQueued = async (
    f: (...args: ArgsT) => Promise<ReturnT>,
    args: ArgsT,
  ): Promise<ReturnT | undefined> => {
    try {
      return await f(...args)
    } catch (e) {
      if (!queuedArgs) {
        executionLock = false
        throw e
      } else {
        return undefined
      }
    }
  }

  // This is a wrapped version of the function given to us that ensures the debounceAsync behavior
  const runDebounced = async (...args: ArgsT): Promise<ReturnT | undefined> => {
    if (!executionLock) {
      executionLock = true
      let returnValue = await runAndReleaseLockIfThrowsWithNothingQueued(f, args)
      while (queuedArgs) {
        const nextArgs = queuedArgs
        queuedArgs = undefined
        returnValue = await runAndReleaseLockIfThrowsWithNothingQueued(f, nextArgs)
      }
      executionLock = false
      return returnValue
    } else {
      queuedArgs = args
      return undefined
    }
  }

  return runDebounced
}

// Provides a way to create Singleton promises, until settled the same promise will be returned
// by the 'getOrAdd' method
export class SingletonPromiseMap<TKey, TResult> {
  private map = new Map<TKey, Promise<TResult>>()

  getOrAdd(key: TKey, factory: () => Promise<TResult>): Promise<TResult> {
    const existing = this.map.get(key)
    if (existing) return existing
    // the 'finally' call always happens asynchronously, even when the current promise is already settled
    const newPromise = factory().finally(() => this.map.delete(key))
    this.map.set(key, newPromise)
    return newPromise
  }

  has(key: TKey): boolean {
    return this.map.has(key)
  }
}

/**
 * Polls an async function until a condition is met.
 *
 * @param fn The function to be executed in each poll.
 * @param fnCondition The condition function that determines when to stop polling.
 * @param ms The interval between each poll in milliseconds. Default is 1000ms.
 * @returns The final result of the function after the condition is met.
 */
export const pollUntil = async (
  fn: Function,
  fnCondition: Function,
  ms = 1000,
): Promise<unknown> => {
  let result = await fn()
  while (!fnCondition(result)) {
    await sleep(ms)
    result = await fn()
  }
  return result
}

export async function tryCatchAsync<T>(args: {
  try: () => Promise<T>
  catch: (e: unknown) => Promise<never>
}): Promise<T>
export async function tryCatchAsync<T, C>(args: {
  try: () => Promise<T>
  catch: (e: unknown) => Promise<C>
}): Promise<T | C>
export async function tryCatchAsync<T, C>({
  try: tryFn,
  catch: catchFn,
}: {
  try: () => Promise<T>
  catch: (e: unknown) => Promise<never | C>
}): Promise<T | C> {
  try {
    return await tryFn()
  } catch (e) {
    return await catchFn(e)
  }
}
