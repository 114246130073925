import { Button, Card } from "@mui/material"
import Box from "@mui/material/Box"
import SearchIdInput from "components/inputs/SearchIdInput"
import PageContainer from "components/layout/dashboard/PageContainer"
import { anyPass, complement, equals, filter, isEmpty } from "ramda"
import React, { FC, useCallback, useEffect, useMemo } from "react"

import Select, { useSelect } from "../../../components/inputs/Select"
import { useSearchInput } from "../../../components/inputs/useSearchInput"
import { useSpaceTemplates } from "../../../features/spaceTemplates/hooks"
import useDeepLinking from "../clientState/useDeeplinking"
import SpaceTemplatesTable from "./partials/SpaceTemplatesTable"

const PAGE_TITLE = "Manage Space Templates"

const SpaceTemplatesIndexPage: FC = () => {
  const { params: urlParams, setParams: setUrlParams } = useDeepLinking()

  const {
    draftValue: draftSpaceTemplateIdValue,
    value: spaceTemplateIdValue,
    handleKeyPress: handleSpaceTemplateIdKeyPress,
    handleChange: handleSpaceTemplateIdChange,
    clear: clearSpaceTemplateId,
  } = useSearchInput(urlParams.get("spaceTemplateId") ?? "")

  const {
    draftValue: draftSpaceIdValue,
    value: spaceIdValue,
    handleKeyPress: handleSpaceIdKeyPress,
    handleChange: handleSpaceIdChange,
    clear: clearSpaceId,
  } = useSearchInput(urlParams.get("spaceId") ?? "")

  const {
    value: officeStyleValue,
    handleChange: handleOfficeStyleChange,
    setValue: setOfficeStyleValue,
  } = useSelect(urlParams.get("officeStyle") ?? "all")
  const {
    value: deskTypeValue,
    handleChange: handleDeskTypeChange,
    setValue: setDeskTypeValue,
  } = useSelect(urlParams.get("deskType") ?? "all")

  const clearAll = useCallback(() => {
    setUrlParams({})
    clearSpaceId()
    clearSpaceTemplateId()
    setDeskTypeValue("all")
    setOfficeStyleValue("all")
  }, [])

  const params = useMemo(
    () => ({
      ...filter(complement(anyPass([equals("all"), isEmpty])), {
        officeStyle: officeStyleValue,
        deskType: deskTypeValue,
        spaceId: spaceIdValue,
        spaceTemplateId: spaceTemplateIdValue,
      }),
    }),
    [deskTypeValue, officeStyleValue, spaceIdValue, spaceTemplateIdValue],
  )

  useEffect(() => {
    setUrlParams(params)
  }, [params, setUrlParams])

  const { data: spaceTemplates, isLoading } = useSpaceTemplates()

  const filteredSpaceTemplates = useMemo(() => {
    if (!spaceTemplates) return []

    return spaceTemplates.filter((spaceTemplate) => {
      if (spaceIdValue && spaceTemplate.spaceId !== spaceIdValue) return false
      if (spaceTemplateIdValue && spaceTemplate.id !== spaceTemplateIdValue) return false
      if (officeStyleValue !== "all" && spaceTemplate.officeStyle !== officeStyleValue) return false
      if (deskTypeValue !== "all" && spaceTemplate.deskType !== deskTypeValue) return false
      return true
    })
  }, [spaceTemplates, spaceIdValue, spaceTemplateIdValue, officeStyleValue, deskTypeValue])

  return (
    <PageContainer pageTitle={PAGE_TITLE}>
      <Box sx={{ minWidth: 1100 }}>
        {isLoading && <Box>Loading...</Box>}
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            gap: 1,
            mb: 2,
            mt: 2,
          }}
        >
          <SearchIdInput
            onKeyPress={handleSpaceTemplateIdKeyPress}
            onChange={handleSpaceTemplateIdChange}
            id={draftSpaceTemplateIdValue}
            label="Search by Space Template ID"
            placeholder="spaceTemplateId"
          />

          <SearchIdInput
            onKeyPress={handleSpaceIdKeyPress}
            onChange={handleSpaceIdChange}
            id={draftSpaceIdValue}
            label="Search by Space ID"
            placeholder="spaceId"
          />

          <Select
            onChange={handleOfficeStyleChange}
            value={officeStyleValue}
            options={[
              { value: "all", label: "All" },
              { value: "Startup", label: "Startup" },
              { value: "Antique", label: "Antique" },
              { value: "Zen", label: "Zen" },
              { value: "Eco", label: "Eco" },
            ]}
            label="Filter by Office Style"
          />

          <Select
            onChange={handleDeskTypeChange}
            value={deskTypeValue}
            options={[
              { value: "all", label: "All" },
              { value: "Open", label: "Open" },
              { value: "Private", label: "Private" },
              { value: "Hybrid", label: "Hybrid" },
            ]}
            label="Filter Desk Type"
          />
          <div>
            <Button onClick={clearAll}>Clear</Button>
          </div>
        </Box>
        {!isLoading && spaceTemplates && (
          <Card>
            <Box>
              <SpaceTemplatesTable spaceTemplates={filteredSpaceTemplates} />
            </Box>
          </Card>
        )}

        {!isLoading && !spaceTemplates && <Box>There's nothing here</Box>}
      </Box>
    </PageContainer>
  )
}

export default SpaceTemplatesIndexPage
