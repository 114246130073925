import { cloneDeep } from "lodash"
import { Class } from "type-fest"
import { z } from "zod"

import { ga } from "gather-common/dist/src/public/mobx/decorators"
import { toJS } from "gather-common/dist/src/public/mobx-utils"
import { zodKeys } from "gather-common/dist/src/public/zod-utils"

export const isValueObject = (obj: unknown): obj is InstanceType<BaseValueObjectClass> =>
  typeof obj === "object" && obj instanceof RootBaseValueObject

export type BaseValueObjectClass<TSchema extends z.AnyZodObject = z.AnyZodObject> = ReturnType<
  typeof BaseValueObject<TSchema>
> extends (new (data: infer _) => infer TInstance) & {
  schema: TSchema
  deserialize: infer TDeserialize
}
  ? // We use the same approach used in Model here. See comments there for context
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (new (data: any) => TInstance) & { schema: TSchema; deserialize: TDeserialize }
  : never

/**
 * The top-level no-op superclass that all value objects should extend.
 * Used for `isValueObject()`.
 */
class RootBaseValueObject {
  protected constructor() {}
}

/**
 * The underlying factory to create a BaseValueObject, a base class that can be extended to create value objects.
 *
 * Much of the approach to this impl is inspired by Model. See comments there for more context.
 */
export const BaseValueObject = <TSchema extends z.AnyZodObject, TSerialized = z.infer<TSchema>>(
  schema: TSchema,
) => {
  type ValueObjectState = z.infer<TSchema>

  // We can't type this more precisely because we can't reference BaseValueObject itself here,
  // otherwise TS will have complaints about the non-public members of BaseValueObject.
  type BaseValueObjectInstance = InstanceType<Class<ValueObjectState>>

  type BaseValueObjectStatic = {
    schema: TSchema
    deserialize(data: TSerialized): BaseValueObjectInstance
  }

  /**
   * The public interface of a BaseValueObject instance, excluding data accesses.
   *
   * We need to redundantly define this separately for the same reason we do this in `Model`.
   */
  type BaseValueObjectPublic = {
    serialize(): TSerialized
    cloneData(): ValueObjectState
    clone(): BaseValueObjectInstance

    /**
     * DO NOT USE THIS METHOD. BaseValueObjects DO NOT get destroyed. This exists for compatibility
     * with `@ga.observableClass` - normally you should always actually `destroy()` the object,
     * but BaseValueObjects are an exception.
     * Read more: https://www.notion.so/gathertown/Advanced-MobX-Notes-15abc7eac3d180ff919bce2b53809031?pvs=4#185bc7eac3d180efa6c9db332e19e97a
     * @deprecated
     */
    addDestructor(destructor: VoidFunction): void

    /**
     * BaseValueObjects DO NOT need to be destroyed! See `addDestructor()` for more context.
     */
    destroy(): void
  }

  const dataKeys = zodKeys(schema)

  @ga.observableClass.exclude(["destructors"])
  class BaseValueObject extends RootBaseValueObject implements BaseValueObjectPublic {
    static schema = schema

    private destructors?: VoidFunction[]

    constructor(data: ValueObjectState) {
      super()
      // Defensive copy incoming data for the same reasons we do so in `Model`.
      // We can't use `cloneForPatch()` here b/c it would create a circular dep, but that's fine
      // as long as we don't nest value objects within other value objects.
      Object.assign(this, cloneDeep(data))
    }

    /**
     * Serialize the value object to a format that can be correctly encoded by `msgpack`.
     * This means the return value should basically be JSON-serializable (e.g. no Functions, Sets, etc)
     *
     * This default impl works for "non-recursive" value objects, i.e. value objects with directly
     * serializable fields. See `DateRange` for an example of a "recursive" value object.
     *
     * Value Objects may want to override this default impl to:
     *   - handle "recursive" serialization
     *   - optimize their serialization (e.g. by using a more compact format)
     */
    serialize(): TSerialized {
      const data: ValueObjectState = dataKeys.reduce((acc, key) => {
        acc[key] = toJS(this[key])
        return acc
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      }, {} as ValueObjectState)

      // The default TSerialized is ValueObjectState
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return data as TSerialized
    }

    /**
     * See `serialize()` for more context.
     */
    static deserialize(data: TSerialized) {
      // The default TSerialized is ValueObjectState
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return new this(data as ValueObjectState)
    }

    cloneData() {
      return dataKeys.reduce<ValueObjectState>((acc, key) => {
        // We use `toJS` instead of `cloneForPatch` here to avoid circular dependencies.
        // The latter is unnecessary as long as we don't nest value objects.
        // TODO [GS Rebuild] support or disallow nested value objects
        acc[key] = toJS(this[key])
        return acc
      }, {})
    }

    /**
     * `cloneDeep()` works pretty well for value objects, but critically it doesn't preserve
     * MobX observability. This method fully clones the value object by instatiating a new
     * instance of the same class.
     */
    clone() {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const Constructor = this.constructor as typeof BaseValueObject
      return new Constructor(this.cloneData())
    }

    /**
     * WARNING: BaseValueObjects DO NOT get destroyed, see comments in `BaseValueObjectPublic` for more.
     */
    addDestructor(destructor: VoidFunction) {
      this.destructors ??= []
      this.destructors.push(destructor)
    }

    /**
     * WARNING: BaseValueObjects DO NOT get destroyed, see comments in `BaseValueObjectPublic` for more.
     */
    destroy() {
      this.destructors?.forEach((f) => f())
      delete this.destructors
    }
  }

  BaseValueObject satisfies BaseValueObjectStatic

  // We use the same approach used in Model here. See comments there for context
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return BaseValueObject as (new (
    ...args: ConstructorParameters<typeof BaseValueObject>
  ) => RootBaseValueObject & ValueObjectState & BaseValueObjectPublic) &
    BaseValueObjectStatic
}
