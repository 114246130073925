import { cloneDeep } from "lodash"

import { isObservable, toJS } from "gather-common/dist/src/public/mobx-utils"
import { isValueObject } from "../BaseValueObject"

/**
 * Clones an arbitrary value, removing MobX observability (if any existed),
 * EXCEPT for on value objects. This is particularly useful behavior for state
 * sync patches.
 *
 * NB: This will NOT work for nested value objects!
 */
export function cloneForPatch<T>(value: T): T {
  // Clone value objects to preserve prototype _and_ observability.
  // These need special handling because state sync always requires that value objects given
  // to it are already instantiated + observable (e.g. in `loadModel()`). This normally doesn't
  // end up mattering because we send patches over the network, but it can impact situations
  // (primarily tests!) where we cut out the network step.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  if (isValueObject(value)) return value.clone() as T

  // `toJS()` doesn't work on value objects - it won't preserve the object's prototype.
  // We don't need to `cloneDeep()` here because `toJS()` already does (for observables).
  if (isObservable(value)) return toJS(value)

  // Clone non-observables the regular way.
  return cloneDeep(value)
}
