import RunIcon from "@mui/icons-material/DirectionsRun"
import ManIcon from "@mui/icons-material/Man"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import PermittedComponent from "components/authentication/PermittedComponent"
import TabWrapper from "components/dashboard/TabWrapper"
import SearchIdInput from "components/inputs/SearchIdInput"
import PageContainer from "components/layout/dashboard/PageContainer"
import { Tab as TabType } from "components/types"
import { useSpace } from "features/spaces/hooks"
import useToggleStaffAccess from "features/spaces/mutations/useToggleStaffAccess"
import useSearchParams from "features/useSearchParams"
import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { extractSpaceIDFromUserInput } from "utils/handleSpaceID"

import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types"
import { asUuid } from "gather-common/dist/src/public/stringHelpers"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import SpaceDetailsTab from "./partials/SpaceDetailsTab"
import UsersTab from "./partials/UsersTab"

const PAGE_TITLE = "Manage Spaces"

const tabs: TabType[] = [
  { label: "Space Details", value: "space" },
  { label: "Users", value: "users" },
]

const SpacesIndexPage: FC = () => {
  const navigate = useNavigate()
  const queryParam = useSearchParams()
  const [spaceIdInputValue, setSpaceIdInputValue] = useState<string>("")
  const [spaceId, setSpaceId] = useState<Uuid | undefined>(undefined)
  const { data, isLoading } = useSpace(spaceId)
  const [currentTab, setCurrentTab] = useState("space")
  const toggleStaffAccess = useToggleStaffAccess()

  const handleSpaceIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSpaceIdInputValue(event.target.value)
    },
    [setSpaceIdInputValue],
  )

  const handleTabsChange = useCallback(
    (_event: SyntheticEvent<Element>, value: string) => {
      setCurrentTab(value)
    },
    [setCurrentTab],
  )

  useEffect(() => {
    const initialSpace = decodeURIComponent(queryParam.get("space") ?? "")
    console.log("initialSpace", initialSpace)
    if (!initialSpace) return
    setSpaceIdInputValue(initialSpace)
    setSpaceId(asUuid(initialSpace))
  }, [])

  const handleSpaceIdKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const newSpaceId = extractSpaceIDFromUserInput(spaceIdInputValue)
        setSpaceId(asUuid(newSpaceId))
      }
    },
    [setSpaceId, spaceIdInputValue],
  )

  const updateQueryParam = useCallback(() => {
    if (!spaceId) return
    const params = new URLSearchParams({ space: encodeURIComponent(spaceId) })
    navigate(`${window.location.pathname}${spaceId.length ? `?${params.toString()}` : ""}`, {
      replace: true,
    })
  }, [spaceId])

  // TODO @alecia - refactor into space update method later
  const handleToggleStaffAccess = useCallback(() => {
    if (!spaceId) return
    toggleStaffAccess.mutate({ spaceId })
  }, [spaceId, toggleStaffAccess])

  useEffect(() => {
    if (data) {
      updateQueryParam()
      setCurrentTab("space") // Reset to the main space details tab
    }
  }, [updateQueryParam, data, setCurrentTab])

  const getCurrentTabContents = () => {
    switch (currentTab) {
      case "space":
        return <SpaceDetailsTab spaceId={data?.id} />
      case "users":
        return <UsersTab spaceId={data?.id} />
      default:
        return <TabWrapper>Please let the Core App team know how you ended up here...</TabWrapper>
    }
  }

  return (
    <PageContainer
      pageTitle={PAGE_TITLE}
      buttons={
        // TODO @alecia - remove this button when feature for updating space is implemented
        !isLoading &&
        data && (
          <PermittedComponent permissions={[Can.ToggleSpaceStaffAccess]} requiresAll={true}>
            <Button
              color="primary"
              startIcon={
                data?.spaceSettings.gatherStaffAccessEnabled ? (
                  <RunIcon fontSize="small" />
                ) : (
                  <ManIcon fontSize="small" />
                )
              }
              variant="contained"
              onClick={handleToggleStaffAccess}
            >
              {data.spaceSettings.gatherStaffAccessEnabled ? "Disable" : "Enable"} Gather Staff
              Access
            </Button>
          </PermittedComponent>
        )
      }
    >
      <Box sx={{ minWidth: 1100 }}>
        <SearchIdInput
          onKeyPress={handleSpaceIdKeyPress}
          onChange={handleSpaceIdChange}
          id={spaceIdInputValue}
          placeholder="Enter Space ID or URL here"
          label="Search for Space"
        />

        {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

        {!isLoading && data && (
          <Box>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider />

            {getCurrentTabContents()}
          </Box>
        )}
      </Box>
    </PageContainer>
  )
}

export default SpacesIndexPage
