import Business from "@mui/icons-material/Business"
import GroupIcon from "@mui/icons-material/Group"
import HomeIcon from "@mui/icons-material/HomeRounded"
import LightIcon from "@mui/icons-material/Light"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import UsersIcon from "@mui/icons-material/PeopleRounded"
import ReplayIcon from "@mui/icons-material/Replay"
import WorldIcon from "@mui/icons-material/TravelExplore"
import WatchIcon from "@mui/icons-material/Watch"
import React, { ReactNode } from "react"

import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types"

// import LockIcon from "@mui/icons-material/LockOpenRounded";

export interface NavItemObject {
  title: string
  path: string
  icon?: ReactNode
  children?: Array<NavItemObject>
  permissions?: Can[]
  requiresAll?: boolean
}

export interface NavSectionObject {
  title?: string
  permissions?: Can[]
  requiresAll?: boolean
  items: NavItemObject[]
}

const WEARABLES_LINKS = {
  title: "Wearables",
  path: "/dashboard/wearables",
  icon: <WatchIcon fontSize="small" />,
  permissions: [Can.ManageWearables],
}

const CATALOG_ITEMS_LINKS = {
  title: "Catalog Items",
  path: "/dashboard/catalog-items",
  icon: <LightIcon fontSize="small" />,
  permissions: [Can.ManageCatalogItems],
}

const SPACES_LINKS = {
  title: "Spaces",
  path: "/dashboard/spaces",
  icon: <WorldIcon fontSize="small" />,
  permissions: [Can.ViewReservations],
}

const SPACE_TEMPLATES_LINKS = {
  title: "Space Templates",
  path: "/dashboard/space-templates",
  icon: <Business fontSize="small" />,
  permissions: [Can.ManageSpaceTemplates],
}

// const ROLES_PERMISSIONS_LINKS = {
//   title: "Roles & Permissions",
//   path: "/dashboard/roles",
//   icon: <LockIcon fontSize="small" />,
//   permissions: [Can.ManageRoles],
//   children: [
//     {
//       title: "View All",
//       path: "/dashboard/roles",
//     },
//     {
//       title: "New Role Group",
//       path: "/dashboard/roles/new",
//     },
//   ],
// };

const USER_MANAGEMENT_LINKS = {
  title: "User Lookup",
  path: "/dashboard/users",
  icon: <ManageAccountsIcon fontSize="small" />,
  permissions: [Can.ManageUsers],
}

const BULK_USER_MANAGEMENT_LINKS = {
  title: "Bulk Manage Users",
  path: "/dashboard/bulk-users",
  icon: <GroupIcon fontSize="small" />,
  permissions: [Can.ManageUsers],
}

const ADMIN_USER_MANAGEMENT_LINKS = {
  title: "Admin Users",
  path: "/dashboard/admin-users",
  icon: <UsersIcon fontSize="small" />,
  permissions: [Can.ManageAdminUsers],
}

const AV_MANAGEMENT_LINKS = {
  title: "A/V Client State",
  path: "/dashboard/client-state",
  icon: <ReplayIcon fontSize="small" />,
  permissions: [Can.ViewClientStateSessions],
}

export const NAV_LINKS: Array<NavSectionObject> = [
  {
    title: "General",
    items: [
      {
        title: "Home",
        path: "/dashboard",
        icon: <HomeIcon fontSize="small" />,
      },
      SPACES_LINKS,
      WEARABLES_LINKS,
      CATALOG_ITEMS_LINKS,
      SPACE_TEMPLATES_LINKS,
      USER_MANAGEMENT_LINKS,
      BULK_USER_MANAGEMENT_LINKS,
      AV_MANAGEMENT_LINKS,
    ],
  },
  {
    title: "Advanced",
    permissions: [Can.ManageAdminUsers],
    items: [
      // ROLES_PERMISSIONS_LINKS,
      ADMIN_USER_MANAGEMENT_LINKS,
    ],
  },
]
