// TODO [Rebuild] [Experimentation]: Remove

import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpacePrismaResource } from "gather-common/dist/src/public/v2/resources/spaces"
import { HttpV2Paths, RequestMethod } from "../httpAPI"
import { zodUuid } from "../zodHelpers"

const c = initContract()

export const spaceSourceSchema = z.discriminatedUnion("sourceType", [
  z.object({
    sourceType: z.literal("template"),
    templateId: zodUuid,
  }),
  z.object({
    sourceType: z.literal("space"),
    spaceId: zodUuid,
  }),
])

export const spaces = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.Spaces,
    body: z.object({
      // Non-ephemeral params:
      name: z.string(),
      showAssist: z.boolean().optional(),
      source: spaceSourceSchema.optional(), // TODO CA-2292 make this required once we have the full flow
    }),
    responses: { 200: c.type<SpacePrismaResource>() },
  },
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.Space,
    pathParams: z.object({ spaceId: zodUuid }),
    responses: { 200: c.type<{ exists: boolean }>() },
  },
})

export type SpaceSource = z.infer<typeof spaceSourceSchema>
