import { ga } from "gather-common/dist/src/public/mobx/decorators"
import { AlwaysVisibleToANYONE_DANGEROUS } from "../../framework/GameLogicBuilder/BaseModelHelpers"
import { BaseModel } from "../../framework/models/BaseModel"
import { s } from "../../framework/schema/schema"
import { EnumKeys, ModelKeys } from "../../modelKeys"

export enum CatalogItemTag {
  Furniture = "Furniture",
  Decoration = "Decoration",
}

export enum CatalogItemCategory {
  RoomDecor = "RoomDecor",
  WallDecor = "WallDecor",
  Table = "Table",
  Seating = "Seating",
  Nature = "Nature",
  Machine = "Machine",
  FoodAndDrink = "FoodAndDrink",
  CabinetsAndShelves = "CabinetsAndShelves",
  Interactive = "Interactive",
}

export const categoryEnumToFolderName: Record<CatalogItemCategory, string> = {
  [CatalogItemCategory.RoomDecor]: "Room Decor",
  [CatalogItemCategory.WallDecor]: "Wall Decor",
  [CatalogItemCategory.Table]: "Table",
  [CatalogItemCategory.Seating]: "Seating",
  [CatalogItemCategory.Nature]: "Nature",
  [CatalogItemCategory.Machine]: "Machine",
  [CatalogItemCategory.FoodAndDrink]: "Food & Drink",
  [CatalogItemCategory.CabinetsAndShelves]: "Cabinets & Shelves",
  [CatalogItemCategory.Interactive]: "Interactive",
}

export const catalogItemSchema = s
  .model(ModelKeys.CatalogItem, {
    id: s.uuid(),
    category: s.enum(EnumKeys.CatalogItemCategory, CatalogItemCategory),
    family: s.string(),
    type: s.string(),
    description: s.string().optional(),
    tags: s.array(s.enum(EnumKeys.CatalogItemTag, CatalogItemTag)),
    version: s.number(),
    lastSyncAuthoredAt: s.date().defaultNow_UNIMPLEMENTED(),
    // this does not guard against duplicates, but it's fine.
    // we mainly want to expose a way for art to loosely control ordering
    // the default is set to an arbitrarily high number so new catalog items are always sorted last
    // (to be re-sorted later via the admin dashboard)
    order: s.number().default_UNIMPLEMENTED(10_000),

    // TODO [Studio] Add permissions, ownership, and other metadata
  })
  .withUniqueIndex(["category", "family", "type"])
  .withCreatedAtUpdatedAt()
  .immutable()

@ga.observableClass
export class CatalogItem extends BaseModel(ModelKeys.CatalogItem, catalogItemSchema) {
  static [AlwaysVisibleToANYONE_DANGEROUS] = true

  get isParentable(): boolean {
    return (
      this.category === CatalogItemCategory.Table ||
      this.category === CatalogItemCategory.CabinetsAndShelves
    )
  }
}
