export type MobXPerfMetricSideEffectWatcherSnapshot = {
  totalCreatedCount: number
  totalDisposedCount: number
  totalExecutionCount: number
  totalDuration: number
}

export default class MobXPerfMetricSideEffectWatcher {
  private _isActive: boolean = false

  private totalCreatedCount: number = 0
  private totalDisposedCount: number = 0
  private totalExecutionCount: number = 0
  private totalDuration: number = 0

  public get isActive(): boolean {
    return this._isActive
  }

  public incrementTotalCreatedCount(): void {
    ++this.totalCreatedCount
  }

  public incrementTotalDisposedCount(): void {
    ++this.totalDisposedCount
  }

  public incrementTotalExecutionCount(duration: number): void {
    ++this.totalExecutionCount
    this.totalDuration += duration
  }

  public getMetricSnapshot(): MobXPerfMetricSideEffectWatcherSnapshot {
    return {
      totalCreatedCount: this.totalCreatedCount,
      totalDisposedCount: this.totalDisposedCount,
      totalExecutionCount: this.totalExecutionCount,
      totalDuration: this.totalDuration,
    }
  }

  start() {
    this._isActive = true

    return () => {
      this._isActive = false
    }
  }
}
