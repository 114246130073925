import { initContract } from "@ts-rest/core"
import { z } from "zod"

import {
  CatalogItemIncludeDownVariantsResource,
  CatalogItemResource,
} from "gather-common/dist/src/public/v2/resources/catalogItems"
import { zodUuid } from "gather-common/dist/src/public/zod-utils"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()
export const catalogItems = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminCatalogItems,
    responses: { 200: c.type<CatalogItemIncludeDownVariantsResource[]>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminCatalogItem,
    pathParams: z.object({ catalogItemId: zodUuid }),
    body: z.object({ order: z.number() }).partial(),
    responses: { 200: c.type<CatalogItemResource>() },
  },
})
