import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import PageContainer from "components/layout/dashboard/PageContainer"
import { useCatalogItems } from "features/catalogItems/hooks"
import { isEmpty, pipe, prop, sortBy } from "ramda"
import React, { FC, useMemo } from "react"

import { CatalogItemIncludeDownVariantsResource } from "gather-common/dist/src/public/v2/resources/catalogItems"
import CatalogItemsTable from "./CatalogItemsTable"

const CatalogItemsIndexPage: FC = () => {
  const { data: catalogItems = [], isLoading, isFetched } = useCatalogItems()

  const filteredCatalogItems = useMemo(
    () =>
      pipe<
        [CatalogItemIncludeDownVariantsResource[]],
        CatalogItemIncludeDownVariantsResource[],
        CatalogItemIncludeDownVariantsResource[]
      >(
        sortBy(prop("type")),
        sortBy(prop("family")),
      )(catalogItems),
    [catalogItems],
  )

  return (
    <PageContainer pageTitle="Manage Catalog Items">
      <Box sx={{ minWidth: 1100 }}>
        <Box>
          {isLoading && <Box>Loading...</Box>}

          {isFetched &&
            (isEmpty(catalogItems) ? (
              <Box>There's nothing here</Box>
            ) : (
              <Card>
                <CatalogItemsTable catalogItems={filteredCatalogItems} />
              </Card>
            ))}
        </Box>
      </Box>
    </PageContainer>
  )
}
export default CatalogItemsIndexPage
