import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpacePrismaResource } from "gather-common/dist/src/public/v2/resources/spaces"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"
import { zodUuid } from "../../zodHelpers"

const c = initContract()

export const spaces = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminSpace,
    pathParams: z.object({ spaceId: zodUuid }),
    responses: { 200: c.type<SpacePrismaResource>() },
  },
  toggleStaffAccess: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminSpace,
    pathParams: z.object({ spaceId: zodUuid }),
    body: c.noBody(),
    responses: { 200: c.noBody() },
  },
})
