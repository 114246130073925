import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { Platform } from "gather-common/dist/src/public/constants"
import { HttpV2Paths, RequestMethod } from "../httpAPI"

const c = initContract()

// Properties should stay synced with those from the `HubSpotContactProperty` enum
const ZodHubSpotContactPropertiesMap = z
  .object({
    platform: z.nativeEnum(Platform),
    last_enter_paid_space_date: z.string(),
    last_space_creation_date: z.string(),
    last_conversation_date: z.string(),
    last_reservation_end_date: z.string(),
  })
  .partial()

const ZodHubSpotContactNumberPropertiesMap = z
  .object({
    number_of_conversation_days: z.number(),
    user_revenue: z.number(),
    user_monthly_revenue: z.number(),
    number_of_one_time_reservations: z.number(),
    number_of_subscription_reservations: z.number(),
    number_of_discount_code_uses: z.number(),
  })
  .partial()

export const hubspot = c.router({
  contacts: {
    create: {
      method: RequestMethod.POST,
      path: HttpV2Paths.HubSpotCreateContact,
      body: z.object({
        properties: ZodHubSpotContactPropertiesMap.optional(),
      }),
      responses: { 200: c.noBody() },
    },
    update: {
      method: RequestMethod.PATCH,
      path: HttpV2Paths.HubSpotUpdateContact,
      body: z.object({
        properties: ZodHubSpotContactPropertiesMap,
      }),
      responses: { 200: c.noBody() },
    },

    numberProperties: {
      update: {
        method: RequestMethod.PATCH,
        path: HttpV2Paths.HubSpotUpdateContactNumberProperties,
        body: z.object({
          properties: ZodHubSpotContactNumberPropertiesMap,
        }),
        responses: { 200: c.noBody() },
      },
    },
  },
})
