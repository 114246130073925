import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SerializedAndNormalizedGSState } from "gather-game-logic/dist/src/public/gameLogicTypes"
import { ModelKeys } from "gather-game-logic/dist/src/public/modelKeys"
import { DEFAULT_PATH_PARAM_USER, HttpV2Paths, RequestMethod } from "../../../../httpAPI"
import { zodUuid } from "../../../../zodHelpers"

const c = initContract()

export const baseCalendarEvents = c.router({
  sync: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceUserBaseCalendarEventsSync,
    pathParams: z.object({
      spaceId: zodUuid,
      userId: z.union([z.literal(DEFAULT_PATH_PARAM_USER), zodUuid]),
    }),
    body: c.noBody(),
    responses: {
      200: c.noBody(),
      400: c.type<{ error: string }>(),
    },
  },
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceUserBaseCalendarEvents,
    pathParams: z.object({
      spaceId: zodUuid,
      userId: z.union([z.literal(DEFAULT_PATH_PARAM_USER), zodUuid]),
    }),
    responses: {
      200: c.type<SerializedAndNormalizedGSState<typeof ModelKeys.GoogleCalendarEvent>>(),
      400: c.type<{ error: string }>(),
    },
  },
})
