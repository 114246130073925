import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SerializedAndNormalizedGSState } from "gather-game-logic/dist/src/public/gameLogicTypes"
import { ModelKeys } from "gather-game-logic/dist/src/public/modelKeys"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { zodUuid } from "../../../zodHelpers"

const c = initContract()

export const areas = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceAreas,
    pathParams: z.object({ spaceId: zodUuid }),
    responses: {
      200: c.type<
        SerializedAndNormalizedGSState<typeof ModelKeys.MapArea>[typeof ModelKeys.MapArea]
      >(),
    },
  },
})
