const QueryKeys = {
  AdminUsers: "adminUsers",
  Discounts: "discounts",
  Space: "space",
  SpaceMaps: "spaceMaps",
  SpaceMap: "spaceMap",
  SpaceUsers: "spaceUsers",
  User: "user",
  UserDevices: "userDevices",
  UserSpaces: "userSpaces",
  SpaceTemplates: "spaceTemplates",
  Wearables: "wearables",
  SpaceRecordingBudgetInfo: "spaceRecordingBudgetInfo",
}

export default QueryKeys
