import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import PageContainer from "components/layout/dashboard/PageContainer"
import { useWearables } from "features/wearables/hooks"
import { isEmpty, pipe, prop, sortBy } from "ramda"
import React, { FC, useMemo } from "react"

import { WearableResource } from "gather-common/dist/src/public/v2/resources/wearables"
import WearablesTable from "./partials/WearablesTable"

const PAGE_TITLE = "Manage Wearables"

const WearablesIndexPage: FC = () => {
  const { data: wearables = [], isLoading, isFetched } = useWearables()

  const filteredWearables = useMemo(
    () =>
      pipe<[WearableResource[]], WearableResource[], WearableResource[], WearableResource[]>(
        sortBy(prop("color")),
        sortBy(prop("name")),
        sortBy(prop("type")),
      )(wearables),
    [wearables],
  )

  return (
    <PageContainer pageTitle={PAGE_TITLE}>
      <Box sx={{ minWidth: 1100 }}>
        <Box>
          {isLoading && <Box>Loading...</Box>}

          {isFetched &&
            (isEmpty(wearables) ? (
              <Box>There's nothing here</Box>
            ) : (
              <Card>
                <WearablesTable wearables={filteredWearables} />
              </Card>
            ))}
        </Box>
      </Box>
    </PageContainer>
  )
}

export default WearablesIndexPage
