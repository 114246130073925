import { Class } from "type-fest"

import { getMobxAdmin } from "../getMobxAdmin"
import { makeDataAutoObservable } from "../makeDataAutoObservable"
import { makeObservable } from "../mobx-utils"
import { destroyComputed } from "./computed"
import { getOrCreateObsClassInternalData, ObservableClassInternalSymbol } from "./internals"

type ObservableClass = Class<{ addDestructor(f: VoidFunction): void; afterObservable?(): void }>

// All classes created with this decorator will be marked with this symbol
const ObservableClassSymbol = Symbol("ObservableClass")

export function isObservableClass(C: Class<unknown>) {
  return (
    Object.hasOwn(C, ObservableClassSymbol) &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (C as { [ObservableClassSymbol]?: true })[ObservableClassSymbol] === true
  )
}

/**
 * A field to store excludes on the prototype chain.
 * This data can't be on the usual `ObservableClassInternalSymbol` because it needs to be shared
 * across all classes in the prototype chain. For example - a subclass should be able to *append*
 * to the excludes list of its superclass without *overwriting* it.
 */
export const ObservableClassExcludesSymbol = Symbol("ObservableClassExcludes")

/**
 * Internal impl for the `@ga.observableClass` decorators.
 *
 * @param OriginalClass The class to extend
 * @param excludes The keys to exclude from being made observable, if any
 * @returns A new class that extends the original class, satisfying `@ga.observableClass` functionality.
 */
function createObservableClass<TClass extends ObservableClass>(
  OriginalClass: TClass,
  excludes?: (string | symbol)[],
) {
  class GatherObservableClassWrapper extends OriginalClass {
    static [ObservableClassSymbol] = true

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any[]) {
      super(...args)

      // Update the excludes list on the prototype chain, wherever it is.
      // See `ObservableClassExcludesSymbol` for more.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const thisWithExcludes = this as {
        readonly [ObservableClassExcludesSymbol]?: Set<string | symbol>
      }
      if (excludes) {
        if (!thisWithExcludes[ObservableClassExcludesSymbol]) {
          // If no exclude cache exists anywhere yet, create one on this class prototype
          Object.getPrototypeOf(this)[ObservableClassExcludesSymbol] = new Set<string | symbol>()
        } else if (!Object.getPrototypeOf(this).hasOwnProperty(ObservableClassExcludesSymbol)) {
          // If the existing exclude cache is from a superclass, clone it for this class
          Object.getPrototypeOf(this)[ObservableClassExcludesSymbol] = new Set(
            thisWithExcludes[ObservableClassExcludesSymbol],
          )
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const excludesCache = thisWithExcludes[ObservableClassExcludesSymbol]!
        excludes.forEach((key) => excludesCache.add(key))
      }

      // If `TClass` is not the leaf class for `this` (i.e. we're currently "in a superclass"), don't do anything else yet.
      // Superclass constructors run before subclass decorators have a chance to run their initializers,
      // so the view of annotations right now is incomplete. MobX doesn't allow re-annotating the same properties, so we
      // need to do it exactly once.
      if (Object.getPrototypeOf(this) !== GatherObservableClassWrapper.prototype) return

      // Make all data fields on this class observable
      makeDataAutoObservable(this, thisWithExcludes[ObservableClassExcludesSymbol])

      // Apply annotations from decorators like `@ga.action`, etc
      const internals = getOrCreateObsClassInternalData(this)
      makeObservable(this, internals.annotations)

      // Setup destructor for computeds, if necessary
      const { computeds } = internals
      if (computeds) {
        this.addDestructor(() => {
          const values = getMobxAdmin(this).values_
          computeds.forEach((name) => {
            // `name` should always be a valid key for `this`, otherwise tests would fail.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            destroyComputed(values.get(name)!)
          })
        })
      }

      this.afterObservable?.()
    }
  }

  // Rename this class for easier debugging
  Object.defineProperty(GatherObservableClassWrapper, "name", {
    value: `${OriginalClass.name}ObservableWrapper`,
  })

  return GatherObservableClassWrapper
}

// Default keys to always exclude from being made observable.
export const DEFAULT_EXCLUDES = [
  // We know internally that all `@ga.observableClass` will have these symbols, but we don't want to type them publicly,
  // so we cast it here to avoid TS errors when using it.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  ObservableClassInternalSymbol as symbol,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  ObservableClassExcludesSymbol as symbol,
]

/**
 * This is the underlying implementation of `@ga.observableClass`.
 * DO NOT use this directly - import via `ga` instead.
 *
 * Classes decorated by this must implement an `addDestructor()` method for use in cleaning up
 * computeds, which can cause memory leaks if not cleaned up. Advanced users can opt to stub
 * this method for classes without computeds or without memory leak risk. See `destroyComputed()`
 * for more details on cleaning up computeds.
 *
 * Classes can also optionally implement `afterObservable()` as a callback to run exactly once
 * after the class has been made observable by this decorator.
 */
export function __observableClass<TClass extends ObservableClass>(
  OriginalClass: TClass,
  _context: ClassDecoratorContext,
) {
  return createObservableClass(OriginalClass, DEFAULT_EXCLUDES)
}

/**
 * This is the underlying implementation of `@ga.observableClass.exclude()`.
 * DO NOT use this directly - import via `ga` instead.
 *
 * See `__observableClass` for more.
 */
export function __observableClassExcluding<TClass extends ObservableClass>(
  excludes: (string | symbol)[],
) {
  const allExcludes = excludes.concat(DEFAULT_EXCLUDES)
  return function (OriginalClass: TClass, _context: ClassDecoratorContext) {
    return createObservableClass(OriginalClass, allExcludes)
  }
}
