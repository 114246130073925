import { computed, IComputedValue } from "mobx"

import { getOrCreateObsClassInternalData } from "./internals"

// MobX `computed`, but with `keepAlive` enabled: https://mobx.js.org/computeds.html#keepalive
export const keepAliveComputed = computed({ keepAlive: true })
export type KeepAliveComputed = typeof keepAliveComputed

/**
 * Internal impl for the `@ga.computed` decorators.
 */
function createComputedDecorator(
  context: ClassGetterDecoratorContext,
  computedObj: KeepAliveComputed,
) {
  context.addInitializer(function () {
    // Mark this method as a `computed` on the class prototype.
    const internals = getOrCreateObsClassInternalData(this)

    internals.annotations[context.name] = computedObj

    const computeds: Set<string | symbol> = internals.computeds ?? new Set()
    computeds.add(context.name)
    internals.computeds = computeds
  })
}

/**
 * Destroys a computed value created with `keepAlive: true`.
 * This is only applicable for `keepAlive` computeds - regular computeds do not need to
 * be destroyed (this function will no-op).
 * TODO [GS Rebuild] @vic link to docs explaining need for this
 */
export const destroyComputed = (computed: IComputedValue<unknown>) => {
  if (!("keepAlive_" in computed))
    throw new Error("`destroyComputed` received computed without `keepAlive_` property")
  computed.keepAlive_ = false
  if (!("suspend_" in computed) || typeof computed.suspend_ !== "function")
    throw new Error("`destroyComputed` received computed without `suspend_` property")
  computed.suspend_()
}

/**
 * This is the underlying implementation of `@ga.computed`.
 * DO NOT use this directly - import via `ga` instead.
 *
 * This decorator relies on the class it's applied to being decorated with `@ga.observableClass`.
 * This is a no-op otherwise.
 *
 * This decorator marks the getter as a MobX computed using `makeObservable()` under the hood.
 * TODO: More documentation to come...
 */
export function __computed<TGetter extends Function>(
  _originalGetter: TGetter,
  context: ClassGetterDecoratorContext,
) {
  createComputedDecorator(context, keepAliveComputed)
}

/**
 * This is the underlying implementation of `@ga.computed.equals()`.
 * DO NOT use this directly - import via `ga` instead.
 *
 * This decorator relies on the class it's applied to being decorated with `@ga.observableClass`.
 * This is a no-op otherwise.
 *
 * This decorator marks the getter as a MobX computed using `makeObservable()` under the hood.
 * TODO: More documentation to come...
 */
export function __computedEquals<TGetter extends Function>(
  comparer: (a: unknown, b: unknown) => boolean,
) {
  const equalsComputed = computed({ keepAlive: true, equals: comparer })
  return function (_originalGetter: TGetter, context: ClassGetterDecoratorContext) {
    createComputedDecorator(context, equalsComputed)
  }
}
