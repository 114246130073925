import styled from "@emotion/styled"
import { PlayCircleFilledRounded } from "@mui/icons-material"
import { Button, IconButton, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { SpaceTemplatePrismaResource } from "gather-common/dist/src/public/v2/resources/spaceTemplate"

const RowCell = styled(TableCell)`
  word-break: break-word;
`

interface Props {
  spaceTemplates?: SpaceTemplatePrismaResource[]
}

const SpaceTemplatesTable: FC<Props> = React.memo(function SpaceTemplatesTable({ spaceTemplates }) {
  return (
    <Box sx={{ minWidth: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Floor Preview</TableCell>
            <TableCell>Space Template ID</TableCell>
            <TableCell>Space ID</TableCell>
            <TableCell>Office Style</TableCell>
            <TableCell>Desk Type</TableCell>
            <TableCell>Number of Desks</TableCell>
            <TableCell>Source Space Admin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {spaceTemplates?.map((spaceTemplate) => (
            <TableRow hover key={spaceTemplate.id}>
              <RowCell>
                <Button onClick={() => window.open(spaceTemplate.spacePreviewUrl, "_blank")}>
                  <img
                    src={spaceTemplate.spacePreviewUrl}
                    alt="Preview Image"
                    style={{
                      width: "180px",
                      height: "180px",
                      objectFit: "contain",
                      objectPosition: "center",
                      imageRendering: "auto",
                    }}
                  />
                </Button>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{spaceTemplate.id}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{spaceTemplate.spaceId}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{spaceTemplate.officeStyle}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{spaceTemplate.deskType}</Typography>
              </RowCell>
              <RowCell>
                <Typography color="textPrimary">{spaceTemplate.numberOfDesks}</Typography>
              </RowCell>
              <RowCell>
                <IconButton
                  component={RouterLink}
                  to={`/dashboard/spaces?space=${spaceTemplate.spaceId}`}
                  aria-label="view"
                  size="large"
                >
                  <PlayCircleFilledRounded fontSize="inherit" />
                </IconButton>
              </RowCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
})

export default SpaceTemplatesTable
