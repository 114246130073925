import { StateSyncContext } from "gather-state-sync/dist/src/public/StateSyncContext"
import {
  AnyGameLogicBuilder,
  ModelClassesOf,
  RepoClassesOf,
  SharedGameLogicContext,
} from "./GameLogicBuilderTypes"
import { ModelInstanceOrRepo } from "./MethodAction"

/**
 * Exposes the runtime context of game logic with the "upgraded" type, upgrading from:
 *   1. `StateSyncContextInternal` -> `StateSyncContext<TModelClasses, TRepos>`
 *   2. Unknown additional context -> `SharedGameLogicContext`
 *
 * 1. State sync internals (like models) can only access the `StateSyncContextInternal` view of the context,
 * but external consumers can access the full context via this helper.
 * 2. State sync itself doesn't know what additional context we'll provide, but we know it's `SharedGameLogicContext`.
 */
export const defineContextGetter =
  <TGameLogic extends AnyGameLogicBuilder>() =>
  (instance: ModelInstanceOrRepo) =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    instance.context() as unknown as StateSyncContext<
      ModelClassesOf<TGameLogic>,
      RepoClassesOf<TGameLogic>,
      SharedGameLogicContext<TGameLogic> // TAdditionalContext
    >

/**
 * This Visibility API Symbol makes models visible to ANYONE IN THE ENTIRE WORLD, which can be
 * dangerous if used incorrectly. For appropriate situations, it's the recommended way to make a
 * model always visible.
 *
 * If you find yourself writing:
 *
 *     isVisible() {
 *       return true
 *     }
 *
 * Use this symbol instead:
 *
 *     static [AlwaysVisibleToANYONE_DANGEROUS] = true
 *
 * Using this lets the Visibility API impl make significant perf optimizations for these models.
 * Make sure your model truly should always be visible, though - ANYONE will be able to read all
 * applicable models, including users who haven't entered a space yet.
 */
export const AlwaysVisibleToANYONE_DANGEROUS = Symbol("AlwaysVisible")
