import { Class } from "type-fest"
import { z, ZodAny, ZodEffects } from "zod"

import { asUuid } from "./stringHelpers"
import { DateTimeExt } from "./valueObjects/DateTimeExt"

/**
 * Returns true if the input is T or z.ZodEffects<T>. Ex:
 *
 *     isMaybeZodEffectZodType(z.string(), z.ZodEffects<z.ZodString>) // true
 */
function isMaybeZodEffectZodType<T extends z.ZodType>(
  input: z.ZodType,
  type: Class<T>,
): input is T | z.ZodEffects<T> {
  return (
    input instanceof type || (input instanceof z.ZodEffects && input.innerType() instanceof type)
  )
}

/**
 * Returns true if the input is T or z.ZodOptional<T>. Ex:
 *
 *     isMaybeOptionalZodType(z.string(), z.ZodString) // true
 *
 *     Also unwraps if input type is a ZodEffect
 *     TODO - [gs-rebuild] - The purpose of this code was to opaquely handle
 *     the zodUuid(ZodEffects<ZodString, Uuid, string>) type. We can handle this more
 *     succinctly if we refactored to utilize the SchemaType system outright.
 */
export function isMaybeOptionalZodType<T extends z.ZodType>(
  input: z.ZodType,
  type: Class<T>,
): input is T | z.ZodOptional<T> {
  return (
    isMaybeZodEffectZodType(input, type) ||
    (input instanceof z.ZodOptional && isMaybeZodEffectZodType(input.unwrap(), type))
  )
}

export type ZodAnyObject = z.ZodObject<z.ZodRawShape>

// Private helper used for `zodKeys`
function zodObjectKeys<TSchema extends ZodAnyObject>(
  schema: TSchema,
): (string & keyof z.infer<TSchema>)[] {
  return schema.keyof().options
}

/**
 * Returns the keys of a Zod object (or object-ish) schema. Ex:
 *
 *     const s = z.object({ foo: z.string() })
 *     zodKeys(s) // ["foo"]
 */
export function zodKeys<
  TSchema extends ZodAnyObject | z.ZodIntersection<ZodAnyObject, ZodAnyObject>,
>(schema: TSchema): (string & keyof z.infer<TSchema>)[] {
  return schema instanceof z.ZodObject
    ? zodObjectKeys(schema)
    : Array.from(new Set([...zodObjectKeys(schema._def.left), ...zodObjectKeys(schema._def.right)]))
}

function isZodObject(schema: z.ZodTypeAny): schema is z.AnyZodObject {
  return schema._def.typeName === "ZodObject"
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isZodArray(schema: z.ZodTypeAny): schema is z.ZodArray<any> {
  return schema._def.typeName === "ZodArray"
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isZodUnion(schema: z.ZodTypeAny): schema is z.ZodUnion<any> {
  return schema._def.typeName === "ZodUnion"
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isZodRecord(schema: z.ZodTypeAny): schema is z.ZodRecord<any, any> {
  return schema._def.typeName === "ZodRecord"
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isZodSet(schema: z.ZodTypeAny): schema is z.ZodSet<any> {
  return schema._def.typeName === "ZodSet"
}

function pickObject(schema: z.ZodTypeAny, path: string): z.ZodTypeAny {
  if (schema instanceof z.ZodOptional) {
    schema = schema.unwrap()
  }
  if (isZodRecord(schema)) return pickRecord(schema)

  if (!isZodObject(schema)) throw Error("Not a zod object")

  const newSchema = schema.shape?.[path]
  if (!newSchema)
    throw Error(`${path} does not exist on schema with keys: ${Object.keys(schema.shape)}`)

  // Our ValueObjects are ZodEffects wrapping ZodAny. This is because
  // we use z.instanceof to generate the value object type. Unfortunately
  // zod does this by returning a ZodAny type with a refinement that just checks the
  // instanceof the provided object is of the right class type. The schema is essentially thrown away.
  // TODO - find a way to get both instanceof AND schema validation for value objects
  if (newSchema instanceof ZodEffects && newSchema.innerType() instanceof ZodAny)
    return newSchema.innerType()

  return newSchema
}

function pickArray(schema: z.ZodTypeAny): z.ZodTypeAny {
  if (!isZodArray(schema)) throw Error("Not a Zod Array")

  const newSchema = schema?.element
  if (!newSchema) throw Error("No element on Zod Array")

  return newSchema
}

function pickRecord(schema: z.ZodRecord): z.ZodTypeAny {
  if (!isZodRecord(schema)) throw Error("Not a zod record")

  return schema.valueSchema
}
/**
 *
 * Returns a nested portion of a zod schema.
 * Useful for validating a portion of an object against a portion of a schema
 * Code taken from https://github.com/colinhacks/zod/discussions/2083. Minor modification to support our
 * partition character '/'
 *
 * Caveat:
 * The deep picker does not handle unions well so the current behavior is just to early return once a union type is reached.
 * It is up to the consumer to parse the rest.
 *
 * Example:
 * const schema = z.object({
 *  foo: z.string(),
 *  bar: z.object({
 *    zee: z.number()
 *  }),
 * })
 * const subSchema = zodDeepPick(schema, 'bar/zee')
 *
 * subSchema will parse on just the portion of the schema you specify by propertyPath
 * expect(() => subSchema.parse(3)).not.toThrow()
 */
export function zodDeepPick(
  schema: z.ZodTypeAny,
  propertyPath: string,
): z.ZodTypeAny | { schema: z.ZodTypeAny; message: string } {
  // gather's model key property path begins with `/`, which does not work well with
  // this recursive algorithm
  if (propertyPath[0] === "/") return innerZodDeepPick(schema, propertyPath.slice(1))

  return innerZodDeepPick(schema, propertyPath)
}

function innerZodDeepPick(
  schema: z.ZodTypeAny,
  propertyPath: string,
): z.ZodTypeAny | { schema: z.ZodTypeAny; message: string } {
  // if schema is a union type, early return.
  // reason: it would be impossible to return a single subschema for discriminated unions
  if (isZodUnion(schema)) return { schema, message: "Early return for union" }

  if (propertyPath === "") return schema

  const arrayPartitionPattern = new RegExp("/[0-9]+$|/[0-9]+/")
  const objectPartitionPattern = new RegExp("/[^0-9]")

  const arrayIndex = propertyPath.search(arrayPartitionPattern)
  const objectIndex = propertyPath.search(objectPartitionPattern)

  const matchedArray = arrayIndex !== -1
  const matchedObject = objectIndex !== -1

  // We do not currently have a use-case for introspecting into indexes of an array schema(ex. ChangeTracker does not
  // observe individual elements within arrays and sets), but keeping this code here for now.
  if (
    (matchedArray && matchedObject && arrayIndex < objectIndex) ||
    (matchedArray && !matchedObject)
  ) {
    const arraySplit = propertyPath.split(arrayPartitionPattern)
    const restArray = arraySplit.slice(1, arraySplit.length).join("/0/")

    if (arrayIndex !== 0) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return innerZodDeepPick(pickObject(schema, arraySplit[0] as string), `/0/${restArray}`)
    }

    return innerZodDeepPick(
      pickArray(schema),
      restArray.charAt(0) === "/" ? restArray.slice(1, restArray.length) : restArray,
    )
  }

  if (matchedObject) {
    const objectSplit = propertyPath.split("/")
    const restObject = objectSplit.slice(1, objectSplit.length).join("/")

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
    return innerZodDeepPick(pickObject(schema, objectSplit[0] as string), restObject)
  }

  return pickObject(schema, propertyPath)
}

export const zodDateTimeExt = z.custom<DateTimeExt>((t) => DateTimeExt.isDateTime(t))

// TODO - PLAT-2539 - Replace usage of this with s.uuid once MethodAction
// refactored to use SchemaType
export const zodUuid = z
  .string()
  .uuid()
  .transform((val) => asUuid(val))
