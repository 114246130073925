import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { CatalogItemIncludeDownVariantsResource } from "gather-common/dist/src/public/v2/resources/catalogItems"
import CatalogItemRow from "./CatalogItemRow"

interface Props {
  catalogItems: CatalogItemIncludeDownVariantsResource[]
}

const CatalogItemsTable: FC<Props> = ({ catalogItems }) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            Type
          </TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Family</TableCell>
          <TableCell>Order</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {catalogItems.map((catalogItem) => (
          <CatalogItemRow key={`catalogItem-${catalogItem.id}`} catalogItem={catalogItem} />
        ))}
      </TableBody>
    </Table>
  </Box>
)

export default CatalogItemsTable
