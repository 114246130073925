import { initContract } from "@ts-rest/core"

import { AdminRolePrisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const roles = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SuperAdminUserRoles,
    responses: {
      200: c.type<AdminRolePrisma[]>(),
    },
  },
})
