import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../httpAPI"

export enum PublishLogSource {
  GatherChromeExtensionV2 = "GatherChromeExtensionV2",
}

const c = initContract()

export const logs = c.router({
  create: {
    method: RequestMethod.POST,
    path: HttpV2Paths.PublishLog,
    body: z.object({
      source: z.nativeEnum(PublishLogSource),
      error: z.string(),
      context: z.record(z.string().max(100), z.string().max(100)).optional(),
    }),
    responses: { 200: z.undefined() },
  },
})
