// Webpack version 5+ does not support Buffer natively anymore
import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { WearableResource } from "gather-common/dist/src/public/v2/resources/wearables"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"

/**
 * API request to fetch all wearables in the database.
 * @returns list of Wearables
 */
export const listWearables = async (): Promise<WearableResource[]> =>
  tsrAPI.admin.wearables.list({})

export const updateWearable = async (id: Uuid, order: number): Promise<WearableResource> =>
  tsrAPI.admin.wearables.update({ params: { wearableId: id }, body: { order } })
