import type { PayloadAction } from "@reduxjs/toolkit"
import { createDraftSafeSelector, createSelector, createSlice } from "@reduxjs/toolkit"
import { isArray, orderBy } from "lodash"
import {
  getAllParticipants,
  getParticipantsFromSnapshots,
} from "pages/dashboard/clientState/clientStateTool/participants.logic"
import { ViewerMessage } from "pages/dashboard/clientState/clientStateTool/types"
import {
  addUniqueMessageId,
  getSnapshots,
  isValidSession,
} from "pages/dashboard/clientState/clientStateTool/utils"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { BrowserVideoClientStateSessionPrisma } from "gather-prisma-types/dist/src/public/client"
import type { RootState } from "./store"

export interface AvClientStateViewerState {
  isPlaying: boolean
  currentStep: number
  currentSession?: BrowserVideoClientStateSessionPrisma
  pointsOfInterest: string[]
}

const initialState: AvClientStateViewerState = {
  isPlaying: false,
  currentStep: 0,
  pointsOfInterest: [
    "CONSOLE_MESSAGE/warn",
    "CONSOLE_MESSAGE/error",
    "WEBRTC_ISSUE/",
    "NETWORK_TEST_RESULTS/",
  ],
}

export const avClientStateViewerSlice = createSlice({
  name: "avClientStateViewer",
  initialState,
  reducers: {
    playing: (state) => {
      state.isPlaying = true
    },
    paused: (state) => {
      state.isPlaying = false
    },
    sessionChanged: (
      state,
      action: PayloadAction<BrowserVideoClientStateSessionPrisma | undefined>,
    ) => {
      state.currentSession = action.payload
      state.isPlaying = false
      state.currentStep = 0
    },
    timelineChanged: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
      state.isPlaying = false
    },
    clickedToTimestamp: (state, action: PayloadAction<number>) => {
      const value = findStepByTimestamp(action.payload, selectLocalTimestamps(state))
      if (value !== undefined) {
        state.currentStep = value
        state.isPlaying = false
      }
    },
    stepIncrement: (state) => {
      state.currentStep += 1
    },
  },
})

export const {
  clickedToTimestamp,
  timelineChanged,
  stepIncrement,
  sessionChanged,
  playing,
  paused,
} = avClientStateViewerSlice.actions

export const selectIsPlaying = (state: RootState): boolean => state.avClientStateViewer.isPlaying
export const selectCurrentStep = (state: RootState): number => state.avClientStateViewer.currentStep
export const selectCurrentSession = (
  state: RootState,
): typeof state.avClientStateViewer.currentSession => state.avClientStateViewer.currentSession
export const selectPointsOfInterest = (state: RootState): string[] =>
  state.avClientStateViewer.pointsOfInterest

export const selectRawMessages = (
  state: RootState,
): BrowserVideoClientStateSessionPrisma["session"] | undefined =>
  state.avClientStateViewer.currentSession?.session

export const transformWithId = (messages?: unknown): ViewerMessage[] => {
  if (isArray(messages) && isValidSession(messages))
    return addUniqueMessageId(orderBy(messages, ["timestamp"], ["asc"]))

  return []
}

export const selectMessages: (root: RootState) => ViewerMessage[] = createSelector(
  selectRawMessages,
  transformWithId,
)
export const transformTimestamps = (messages: ViewerMessage[] = []): number[] =>
  Array.from(new Set(messages.map((i) => i.timestamp)))

export const selectLocalRawMessages: (state: AvClientStateViewerState) => ViewerMessage[] =
  createDraftSafeSelector(
    (state: AvClientStateViewerState) => state.currentSession?.session,
    transformWithId,
  )

export const selectTimestamps = createSelector(selectMessages, transformTimestamps)
export const selectLocalTimestamps = createSelector(selectLocalRawMessages, transformTimestamps)
export const selectCurrentTimestamp = createSelector(
  selectCurrentStep,
  selectTimestamps,
  (currentStep, timestamps) => timestamps[currentStep] ?? 0,
)

export const useIsPlaying = (): ReturnType<typeof selectIsPlaying> => useSelector(selectIsPlaying)
export const useCurrentStep = (): ReturnType<typeof selectCurrentStep> =>
  useSelector(selectCurrentStep)
export const useCurrentSession = (): ReturnType<typeof selectCurrentSession> =>
  useSelector(selectCurrentSession)
export const useSessionMessages = (): ReturnType<typeof selectMessages> =>
  useSelector(selectMessages)
export const useTimestamps = (): ReturnType<typeof selectTimestamps> =>
  useSelector(selectTimestamps)
export const useCurrentTimestamp = (): ReturnType<typeof selectCurrentTimestamp> =>
  useSelector(selectCurrentTimestamp)
export const useAVClientStateViewerDispatchers = (): {
  dispatchPlaying: typeof dispatchPlaying
  dispatchPaused: typeof dispatchPaused
  dispatchStepIncrement: typeof dispatchStepIncrement
  dispatchTimelineChanged: typeof dispatchTimelineChanged
  dispatchSessionChanged: typeof dispatchSessionChanged
  dispatchClickedToTimestamp: typeof dispatchClickedToTimestamp
} => {
  const dispatch = useDispatch()

  const dispatchStepIncrement = useCallback(() => dispatch(stepIncrement()), [])
  const dispatchPlaying = useCallback(() => dispatch(playing()), [])
  const dispatchPaused = useCallback(() => dispatch(paused()), [])

  const dispatchTimelineChanged = useCallback(
    (currentStep: number) => dispatch(timelineChanged(currentStep)),
    [],
  )
  const dispatchClickedToTimestamp = useCallback(
    (timestamp: number) => dispatch(clickedToTimestamp(timestamp)),
    [],
  )

  const dispatchSessionChanged = useCallback(
    (currentSession: BrowserVideoClientStateSessionPrisma | undefined) =>
      dispatch(sessionChanged(currentSession)),
    [],
  )
  return {
    dispatchPlaying,
    dispatchPaused,
    dispatchStepIncrement,
    dispatchTimelineChanged,
    dispatchSessionChanged,
    dispatchClickedToTimestamp,
  }
}

export const selectParticipantsByTimestamp = createSelector(selectMessages, (messages) =>
  getParticipantsFromSnapshots(getSnapshots(messages)),
)

export const selectParticipants = createSelector(selectMessages, (messages) =>
  getAllParticipants(messages),
)
export const selectCurrentParticipants = createSelector(
  selectParticipantsByTimestamp,
  selectCurrentTimestamp,
  (participantsByTimestamp, currentTimestamp) => participantsByTimestamp[currentTimestamp],
)
export const useParticipantsByTimestamp = (): ReturnType<typeof selectParticipantsByTimestamp> =>
  useSelector(selectParticipantsByTimestamp)
export const useSelectParticipants = (): ReturnType<typeof selectParticipants> =>
  useSelector(selectParticipants)
export const useCurrentParticipants = (): ReturnType<typeof selectCurrentParticipants> =>
  useSelector(selectCurrentParticipants)
export const usePointsOfInterest = (): ReturnType<typeof selectPointsOfInterest> =>
  useSelector(selectPointsOfInterest)

export function useParticipants(selfParticipant = ""): {
  participantsByTimestamp: { [index: string]: string[] }
  participants: string[]
  currentParticipants: string[]
} {
  const participantsByTimestamp = useParticipantsByTimestamp()

  const remoteParticipants = useSelectParticipants()
  const currentParticipants = useCurrentParticipants()

  const participants = useMemo(
    () => (selfParticipant ? [selfParticipant, ...remoteParticipants] : remoteParticipants),
    [selfParticipant, remoteParticipants],
  )

  const currentParticipantsWithSelf = useMemo(
    () => [...(currentParticipants ?? []), selfParticipant],
    [selfParticipant, currentParticipants],
  )

  return {
    participantsByTimestamp,
    participants,
    currentParticipants: currentParticipantsWithSelf,
  }
}

export const findStepByTimestamp = (timestamp: number, timestamps: number[]): number =>
  timestamps.lastIndexOf(timestamp)

export default avClientStateViewerSlice.reducer
