import { z } from "zod"

import { decode, encode } from "@gathertown/msgpack"
import { Logger } from "gather-common-including-video/dist/src/public/Logger"
import { switchEnv } from "gather-env-config/dist/src/public/env"
import { codec } from "./ExtensionCodec"

const CODING_WARN_THRESHOLD_MS = switchEnv({
  prod: 10,
  staging: 10,
  dev: 10,
  local: 10,
  test: Infinity, // never log this warning in tests
})

export class Protocol<SchemaT extends z.Schema> {
  constructor(public schema: SchemaT) {}

  encode(message: z.infer<SchemaT>) {
    const start = performance.now()
    const ret = encode(message, { extensionCodec: codec, allowUndefinedCustomEncoding: true })
    const encodeTimeMs = performance.now() - start
    if (encodeTimeMs >= CODING_WARN_THRESHOLD_MS) {
      Logger.warn(`[Protocol] Encoding message took ${Math.round(encodeTimeMs * 10) / 10}ms`)
    }
    return ret
  }

  decode(data: ArrayBuffer): z.infer<SchemaT> {
    const start = performance.now()
    const ret = this.schema.parse(decode(data, { extensionCodec: codec }))
    const decodeTimeMs = performance.now() - start
    if (decodeTimeMs >= CODING_WARN_THRESHOLD_MS) {
      Logger.warn(`[Protocol] Decoding message took ${Math.round(decodeTimeMs * 10) / 10}ms`)
    }
    return ret
  }
}

export type SchemaOf<ProtocolT extends Protocol<z.Schema>> = ProtocolT["schema"]
export type MessageOf<ProtocolT extends Protocol<z.Schema>> = z.infer<SchemaOf<ProtocolT>>
