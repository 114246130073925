/* Material Icons */
import GitHubIcon from "@mui/icons-material/GitHub"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import Label from "components/dashboard/Label"
import { useUpdateCatalogItem } from "features/catalogItems/hooks"
import { head } from "ramda"
import React, { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { CatalogItemIncludeDownVariantsResource } from "gather-common/dist/src/public/v2/resources/catalogItems"
import { categoryEnumToFolderName } from "gather-game-logic/dist/src/public/models/catalog/CatalogItem"

interface Props {
  catalogItem: CatalogItemIncludeDownVariantsResource
}

const CatalogItemRow: FC<Props> = ({ catalogItem }) => {
  const { mutate: updateCatalogItem } = useUpdateCatalogItem()
  const defaultVariant =
    catalogItem.catalogItemVariants.find((variant) => variant.isDefault) ??
    head(catalogItem.catalogItemVariants)

  return (
    <TableRow hover>
      <TableCell>
        {/* TODO switch this to the default variant instead of first */}
        {/* https://linear.app/gather-town/issue/VW-2903/default-variant-ordered-catalog-colors */}
        <img src={`https://static.gather.town${defaultVariant?.mainRenderable__imageUrl}`} />
      </TableCell>
      <TableCell>
        <Grid container gap={1} alignItems="center">
          <Link
            color="textPrimary"
            variant="body2"
            component={RouterLink}
            to={`/dashboard/catalog-items/${catalogItem.id}`}
            target="_blank"
          >
            {catalogItem.type}
          </Link>
          <Link
            color="textPrimary"
            variant="body2"
            component={RouterLink}
            to={`https://github.com/gathertown/gather-catalog-items/tree/main/objects/${
              categoryEnumToFolderName[catalogItem.category]
            }/${catalogItem.family}/${catalogItem.type}`}
            target="_blank"
          >
            <GitHubIcon />
          </Link>
        </Grid>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          <Label color="primary">{catalogItem.category}</Label>
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          <Label color="primary">{catalogItem.family}</Label>
        </Box>
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          size="small"
          defaultValue={catalogItem.order ?? 10000}
          onChange={(e) => {
            const value = parseInt(e.target.value)
            if (!isNaN(value)) {
              updateCatalogItem({ id: catalogItem.id, order: value })
            }
          }}
          inputProps={{ min: 0 }}
        />
      </TableCell>
    </TableRow>
  )
}
export default CatalogItemRow
