const NR_DASHBOARD_URL_BASE = "https://one.newrelic.com/launcher/dashboards.launcher"

export type Dashboard = {
  entityId: string
  title: string
  helpText: string
  variableMapping: Record<string, string>
}

export enum AvailableDashboards {
  SINGLE_USER_PERF_BREAKDOWN = "SINGLE_USER_PERF_BREAKDOWN",
  VIDEO_METRICS_BY_SPACE_USER = "VIDEO_METRICS_BY_SPACE_USER",
  PERF_STABILITY_TROUBLESHOOTING = "PERF_STABILITY_TROUBLESHOOTING",
}

export type NewRelicDashboards = Record<AvailableDashboards, Dashboard>

export const NR_DASHBOARDS: NewRelicDashboards = {
  [AvailableDashboards.SINGLE_USER_PERF_BREAKDOWN]: {
    entityId: "MzM3MzkyNnxWSVp8REFTSEJPQVJEfGRhOjUxNDkzMDM",
    // link text
    title: "Single User Performance Breakdown",
    // shows as alt text when hovering over the link
    helpText: "Performance breakdown for a single user",
    // maps context data (keys) to dashboard variables (values)
    variableMapping: {
      userId: "user",
    },
  },
  [AvailableDashboards.VIDEO_METRICS_BY_SPACE_USER]: {
    entityId: "MzM3MzkyNnxWSVp8REFTSEJPQVJEfGRhOjMwMDY1OTU",
    title: "Video Metrics by User",
    helpText: "Video metrics and debugging information by user",
    variableMapping: {
      userId: "user",
      spaceId: "space",
    },
  },
  [AvailableDashboards.PERF_STABILITY_TROUBLESHOOTING]: {
    entityId: "MzM3MzkyNnxWSVp8REFTSEJPQVJEfGRhOjgzMjQwMTQ",
    title: "Perf/Stability Troubleshooting",
    helpText: "Debug performance and stability for this user",
    variableMapping: {
      userId: "user",
      spaceId: "spaceId",
    },
  },
}

export type DashboardContext = Record<string, object | string | undefined>

function convertContextForDashboard(
  dashboard: AvailableDashboards,
  context: DashboardContext,
): DashboardContext {
  const dashboardData = NR_DASHBOARDS[dashboard]
  if (!dashboardData) throw `Invalid dashboard key provided: ${dashboard}`

  const convertedContext: DashboardContext = {}
  for (const [key, value] of Object.entries(dashboardData.variableMapping)) {
    convertedContext[value] = context[key]
  }
  return convertedContext
}

/**
 * Creates a New Relic dashboard URL for a given dashboard and context.
 * Dashboard URLs get created by encoding the data in the URL as base64 data
 * base data looks like:
 *
 * `{
 * "nerdletId": "dashboards.dashboard",
 * "entityGuid": "MzM3MzkyNnxWSVp8REFTSEJPQVJEfGRhOjUxNDkzMDM",
 * "selectedVariables": { "user": "USER_ID" }
 * }`
 *
 * variables differ from dashboard to dashboard, so we need to map them using convertContextForDashboard and the dashboard's variableMapping
 * the final URL ends up looking like (for the above data):
 * https://one.newrelic.com/launcher/dashboards.launcher?pane=eyJuZXJkbGV0SWQiOiJkYXNoYm9hcmRzLmRhc2hib2FyZCIsImVudGl0eUd1aWQiOiJNek0zTXpreU5ueFdTVnA4UkVGVFNFSlBRVkpFZkdSaE9qVXhORGt6TURNIiwic2VsZWN0ZWRWYXJpYWJsZXMiOnsidXNlciI6ICJtdWF1YXVhaGFoYWhhaGFoYWhhIn19
 */
export function createDashboardUrl(
  dashboard: AvailableDashboards,
  context: DashboardContext,
): string {
  const dashboardData = NR_DASHBOARDS[dashboard]
  if (!dashboardData) throw `Invalid dashboard key provided: ${dashboard}`

  const convertedContext = convertContextForDashboard(dashboard, context)

  const baseData = {
    nerdletId: "dashboards.dashboard",
    entityGuid: dashboardData.entityId,
    selectedVariables: convertedContext,
  }
  const baseDataString = JSON.stringify(baseData)
  const baseDataEncoded = btoa(baseDataString)
  return `${NR_DASHBOARD_URL_BASE}?pane=${baseDataEncoded}`
}
