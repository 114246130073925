import { Box, Button, TextField, Typography } from "@mui/material"
import { Formik } from "formik"
import React, { FC } from "react"
import { toast } from "react-hot-toast"
import * as Yup from "yup"

import { guaranteedError } from "gather-common/dist/src/public/utils"
import { createSpace } from "../../../../api/spaces"

interface Props {
  startingName: string
  sourceSpaceId?: string
  onDone: () => void
  onCancel: () => void
}

const NewSpaceForm: FC<Props> = ({ startingName, sourceSpaceId, onDone, onCancel }) => (
  <Formik
    initialValues={{
      name: startingName,
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required("Name is required"),
    })}
    onSubmit={async ({ name }, { resetForm, setStatus, setSubmitting }) => {
      try {
        const newSpace = await createSpace(name, sourceSpaceId)
        // open admin to the new space in a new tab
        window.open("/dashboard/spaces?space=" + newSpace.id, "_blank")

        resetForm()
        setStatus({ success: true })
        setSubmitting(false)
        toast.success("Space was successfully created!")
        onDone()
      } catch (e) {
        const error = guaranteedError(e)
        setStatus({ success: false })
        setSubmitting(false)
        console.warn(error)
        toast.error(error.message)
      }
    }}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{ p: 3 }}>
          <Typography align="center" color="textPrimary" gutterBottom variant="h5">
            Create Space
          </Typography>
          {sourceSpaceId && <Typography>(copy of {sourceSpaceId})</Typography>}
        </Box>

        <Box sx={{ pl: 3, pr: 3 }}>
          <TextField
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label="New Space Name"
            name="name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            p: 2,
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Button color="primary" onClick={onCancel} variant="text">
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
          >
            Create!
          </Button>
        </Box>
      </form>
    )}
  </Formik>
)

export default NewSpaceForm
