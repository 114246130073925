import ContentCopy from "@mui/icons-material/ContentCopy"
import LaunchIcon from "@mui/icons-material/Launch"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import FormControlLabel from "@mui/material/FormControlLabel"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import BooleanLabel from "components/dashboard/BooleanLabel"
import DetailsCard from "components/dashboard/DetailsCard"
import TableCellTitle from "components/dashboard/TableCellTitle"
import TabWrapper from "components/dashboard/TabWrapper"
import { useSpace } from "features/spaces/hooks"
import React, { FC, useState } from "react"

import { isNotNilAndNotEmpty } from "gather-common-including-video/dist/src/public/fpHelpers"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { getAppBasePath } from "../../../../utils/basePath"
import { displayDate } from "../../../../utils/dateUtils"
import NewSpaceForm from "./NewSpaceForm"

interface Props {
  spaceId?: Uuid
}

const SpaceDetailsTab: FC<Props> = ({ spaceId }) => {
  const { data: space, isLoading } = useSpace(spaceId)
  const basePath = getAppBasePath()
  // this link doesn't actually work but whatever
  const spaceLink = `${basePath}/app/${space?.id}`

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <TabWrapper>
      {isLoading && <Box sx={{ p: 2 }}>Loading...</Box>}

      {!isLoading && space && (
        <>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
            <Box sx={{ width: "100%", maxWidth: 500 }}>
              <DetailsCard header="General Details" sx={{ mb: 4 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>ID</TableCellTitle>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography color="textPrimary" variant="body2">
                            {space?.id}
                          </Typography>

                          <Tooltip title="Launch in Gather" placement="top" arrow>
                            <Link
                              color="textPrimary"
                              href={spaceLink}
                              underline="none"
                              fontSize="small"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <LaunchIcon
                                fontSize="small"
                                sx={{
                                  ml: 1,
                                  mb: -0.5,
                                }}
                              />
                            </Link>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Name</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {space?.name}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Guest Check In Enabled?</TableCellTitle>
                      <TableCell>
                        <BooleanLabel condition={space.spaceSettings.guestCheckInEnabled} />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Email Domain Access</TableCellTitle>
                      <TableCell>
                        <BooleanLabel
                          condition={isNotNilAndNotEmpty(space?.spaceSettings.allowedEmailDomains)}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCellTitle>Creation Time</TableCellTitle>
                      <TableCell>
                        <Typography color="textPrimary" variant="body2">
                          {displayDate(space?.createdAt)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>
            </Box>

            <Box sx={{ width: "100%", maxWidth: 500 }}>
              <DetailsCard header="Space Settings" sx={{ mt: 4 }}>
                <Table sx={{ mt: 0 }}>
                  <TableBody>
                    <TableRow>
                      <TableCellTitle>Flags</TableCellTitle>
                      <TableCell>
                        <FormControlLabel
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              color="primary"
                              disabled
                              checked={!!space?.spaceSettings.gatherStaffAccessEnabled}
                            />
                          }
                          label="Allow Gather Staff Access"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCellTitle>Email Domains</TableCellTitle>
                      <TableCell>
                        <List sx={{ pt: 0, pb: 0, mb: 0 }}>
                          {space?.spaceSettings.allowedEmailDomains &&
                            space?.spaceSettings.allowedEmailDomains.map((domain) => (
                              <ListItem disableGutters sx={{ pt: 0 }} key={domain}>
                                {domain}
                              </ListItem>
                            ))}
                        </List>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCellTitle>Studio & Map Settings</TableCellTitle>
                      <TableCell>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                          <Button
                            color="primary"
                            startIcon={<ContentCopy fontSize="small" />}
                            variant="contained"
                            onClick={() => {
                              setModalOpen(true)
                            }}
                          >
                            Duplicate Space
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DetailsCard>
            </Box>
          </Box>
        </>
      )}

      <Dialog fullWidth maxWidth="sm" onClose={() => setModalOpen(false)} open={modalOpen}>
        {/* Dialog renders its body even if not open */}
        {modalOpen && (
          <NewSpaceForm
            sourceSpaceId={space?.id}
            startingName={space ? "Copy of " + space.name : "New Space"}
            onDone={() => {
              setModalOpen(false)
            }}
            onCancel={() => {
              setModalOpen(false)
            }}
          />
        )}
      </Dialog>
    </TabWrapper>
  )
}

export default SpaceDetailsTab
