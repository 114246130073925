import { initContract } from "@ts-rest/core"

import { UserHomeSpaceResource } from "gather-common/dist/src/public/v2/resources/spaces"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

type UserVisitedSpaceResource = Record<string, UserHomeSpaceResource>

export const spaces = c.router({
  listRecent: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserRecentSpaces,
    responses: { 200: c.type<UserVisitedSpaceResource>() },
  },
  listOwned: {
    method: RequestMethod.GET,
    path: HttpV2Paths.UserOwnedSpaces,
    responses: { 200: c.type<UserVisitedSpaceResource>() },
  },
})
