import { normalizeString, validateSpaceId } from "gather-common/dist/src/public/stringHelpers"
import { isNil, isNotNilAndNotEmpty } from "gather-common-including-video/dist/src/public/fpHelpers"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { Env } from "gather-env-config/dist/src/public/env"

// URL Path Constants:

export const PATH_PREFIX_APP = "app"
export const PATH_PREFIX_STUDIO = "studio"
export const PATH_PREFIX_DASHBOARD = "dashboard"

export const PATH_PATTERN_APP_HOME = `/${PATH_PREFIX_APP}`
export const PATH_PATTERN_APP_SPACE = `/${PATH_PREFIX_APP}/:spaceId`
export const PATH_PATTERN_STUDIO = `/${PATH_PREFIX_STUDIO}/:spaceId`
export const PATH_PATTERN_DASHBOARD = `/${PATH_PREFIX_DASHBOARD}/:spaceId`

export const getBaseSpaceLink = (spaceId: Uuid) => `${window.location.origin}/app/${spaceId}`
export const getGuestSpaceLink = (spaceId: Uuid) => `${getBaseSpaceLink(spaceId)}/join?guest`

// Returns a path with params interpolated.
// Lightweight implementation of https://reactrouter.com/utils/generate-path without wildcard matching
export function generatePathWithoutWildcards(
  originalPath: string,
  params: {
    [key: string]: string | null
  } = {},
) {
  const prefix = originalPath.startsWith("/") ? "/" : ""

  const segments = originalPath
    .split(/\/+/)
    .map((segment) => {
      const keyMatch = segment.match(/^:(\w+)(\??)$/)
      if (keyMatch) {
        const [, key, optional] = keyMatch
        if (key) {
          const isOptional = optional === "?"
          const param = params[key]
          if (!isOptional && isNil(param)) throw new Error(`Missing ":${key}" param`)
          return param ?? ""
        }
      }
      // Remove remaining optional segments
      return segment.replace(/\?$/g, "")
    })
    .filter((segment) => isNotNilAndNotEmpty(segment))

  return prefix + segments.join("/")
}

export function getURLBaseDomain(url: string) {
  const parsedUrl = new URL(url)
  const domainParts = parsedUrl.host.split(".")
  const domainEndParts = domainParts.slice(-2)

  const domain = domainEndParts[0]
  const tld = domainEndParts[1]

  if (!domain || !tld) return null

  return `${domain}.${tld}`
}

const gatherLocalOriginRegex = /^https?:\/\/((?:localhost:(?:3000|8080))|(?:.*ngrok(?:-free)?.app))/

export function isLocalGatherOrigin(url: string) {
  return gatherLocalOriginRegex.test(url)
}

export function isURLFromValidGatherOrigin(url: string) {
  // need to check the environment here directly so that we can mock it out in the test
  const isLocalOrTest =
    process.env.ENVIRONMENT === Env.local || process.env.ENVIRONMENT === Env.test
  return getURLBaseDomain(url) === "gather.town" || (isLocalOrTest && isLocalGatherOrigin(url))
}

// Use `getHumanReadableSpacePath` when creating a space path that is exposed to the user, e.g. a link copied to their clipboard.
export function getSpacePathFromId(spaceId: string): string {
  const constructedSpaceId = encodeURIComponent(spaceId)
  return generatePathWithoutWildcards(PATH_PATTERN_APP_SPACE, {
    spaceId: constructedSpaceId,
  })
}

// Use `getHumanReadableSpacePath` when creating a space path that is exposed to the user, e.g. a link copied to their clipboard.
export function getSpacePathFromIdOrThrow(spaceId: string | undefined | null): string {
  if (isNil(spaceId)) throw new Error("Invalid spaceId in getSpacePathFromIdOrThrow")
  return getSpacePathFromId(spaceId)
}

/**
 * When constructing a human-readable URL, we must append the space name to the spaceId. This
 * function is used to construct that string with a dash as the separator.
 */
export const getHumanReadableSpaceIdParam = (spaceId: Uuid, spaceName: string): string => {
  // Sanitize the space name:
  const normalizedSpaceName =
    // Remove diacritics, trim whitespace, lowercase, etc.
    normalizeString(spaceName)
      // Replace non-alphanumeric characters with dashes.
      .replace(/[^a-z0-9]+/g, "-")
      // Remove leading dash.
      .replace(/^-/, "")
      // Limit the length to something reasonable.
      .substring(0, 30)
      // Remove trailing dash.
      .replace(/-$/, "")

  // Ignore empty space names.
  if (!normalizedSpaceName) return spaceId

  return `${normalizedSpaceName}-${spaceId}`
}

// Use this util when creating a space path that is exposed to the user, e.g. a link copied to their clipboard.
// We include the space name in these cases for clarity on which space the link points to.
// This is not necessary in all cases because we inject the updated space name when navigating to a space.
export function getHumanReadableSpacePath({
  spaceId,
  spaceName,
}: {
  spaceId: Uuid
  spaceName: string
}): string {
  return getSpacePathFromIdOrThrow(getHumanReadableSpaceIdParam(spaceId, spaceName))
}

export function getSpaceStudioPathFromId(spaceId: string): string {
  const constructedSpaceId = encodeURIComponent(spaceId)
  return generatePathWithoutWildcards(PATH_PATTERN_STUDIO, {
    spaceId: constructedSpaceId,
  })
}

export function getDashboardPath(
  spaceId?: string,
  route?: string,
  subRoute?: string,
): string | null {
  if (isNil(spaceId)) return `/${PATH_PREFIX_DASHBOARD}`
  if (!validateSpaceId(spaceId)) return null

  const pathRoute = route ? `/${route}` : ""
  const pathSubRoute = route && subRoute ? `/${subRoute}` : ""
  return generatePathWithoutWildcards(`/${PATH_PATTERN_DASHBOARD}${pathRoute}${pathSubRoute}`, {
    spaceId: spaceId,
  })
}

export function getDashboardPathOrThrow(spaceId?: string, route?: string, subRoute?: string) {
  const path = getDashboardPath(spaceId, route, subRoute)
  if (isNil(path)) throw new Error("Invalid spaceId in getDashboardPathOrThrow")
  return path
}

export function getUrlFromSpaceId(spaceId: string, baseUrl = "https://gather.town") {
  const path = getSpacePathFromId(spaceId)
  if (!path) return ""
  return `${baseUrl}${path}`
}
