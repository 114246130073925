/**
 * WARNING: Avoid adding per-action event types!
 *          New Relic limits to 250 event types (including PageAction, Metrics, etc.)
 *          If you're considering a custom event, think about how you might merge multiple
 *          actions into that single event type.
 *
 * Reference: https://docs.newrelic.com/docs/data-apis/custom-data/custom-events/data-requirements-limits-custom-event-data/#event-limits
 */
export enum NewRelicCustomEvent {
  PerfGSEventTime = "PerfGSEventTime",
  PerfReactBusyTime = "PerfReactBusyTime",
  PerfProcessMetrics = "PerfProcessMetrics",
  MeetingRecording = "MeetingRecording",
  RefreshDetected = "RefreshDetected",
  ChatV2 = "ChatV2",
  Annotations = "Annotations",
  GameClientMetrics = "GameClientMetrics",
}

export type NewRelicCustomEventAttributes = Record<string, string | number | boolean>
