import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import PageContainer from "components/layout/dashboard/PageContainer"
import { useCatalogItemVariants } from "features/catalogItems/hooks"
import { isEmpty, pipe, prop, sortBy } from "ramda"
import React, { FC, useMemo } from "react"
import { useParams } from "react-router-dom"

import { asUuid } from "gather-common/dist/src/public/stringHelpers"
import { CatalogItemVariantResource } from "gather-common/dist/src/public/v2/resources/catalogItems"
import { just } from "gather-common-including-video/dist/src/public/fpHelpers"
import VariantsTable from "./VariantsTable"

const VariantsPage: FC = () => {
  const { catalogItemId } = useParams()
  const {
    data: catalogItemVariants = [],
    isLoading,
    isFetched,
  } = useCatalogItemVariants(asUuid(just(catalogItemId)))

  const sortedVariants = useMemo(
    () =>
      pipe<
        [CatalogItemVariantResource[]],
        CatalogItemVariantResource[],
        CatalogItemVariantResource[]
      >(
        sortBy(prop("orientation")),
        sortBy(prop("color")),
      )(catalogItemVariants),
    [catalogItemVariants],
  )

  return (
    <PageContainer
      pageTitle="Manage Variants"
      breadcrumbs={[{ title: "Manage Catalog Items", to: "/dashboard/catalog-items" }]}
    >
      <Box sx={{ minWidth: 1100 }}>
        <Box>
          {isLoading && <Box>Loading...</Box>}

          {isFetched &&
            (isEmpty(catalogItemVariants) ? (
              <Box>There's nothing here</Box>
            ) : (
              <Card>
                <VariantsTable catalogItemVariants={sortedVariants} />
              </Card>
            ))}
        </Box>
      </Box>
    </PageContainer>
  )
}
export default VariantsPage
