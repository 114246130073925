import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { WearableResource } from "gather-common/dist/src/public/v2/resources/wearables"
import WearableRow from "./WearableRow"

interface Props {
  wearables?: WearableResource[]
}

const WearablesTable: FC<Props> = ({ wearables }) => {
  if (!wearables) return null

  return (
    <Box sx={{ minWidth: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              Name
            </TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Order</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {wearables.map((wearable) => (
            <WearableRow key={`wearable-${wearable.id}`} wearable={wearable} />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default WearablesTable
