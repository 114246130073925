import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import TabWrapper from "components/dashboard/TabWrapper"
import SearchIdInput from "components/inputs/SearchIdInput"
import PageContainer from "components/layout/dashboard/PageContainer"
import { Tab as TabType } from "components/types"
import useRoles from "features/roles/useRoles"
import { useUser } from "features/users/hooks"
import useSearchParams from "features/useSearchParams"
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"

import { AdminPermission as Can } from "gather-admin-common/dist/src/public/roles/types"
import JoinedSpacesTab from "./partials/JoinedSpacesTab"
import UserDetailsTab from "./partials/UserDetailsTab"
import UserRolesTab from "./partials/UserRolesTab"

const PAGE_TITLE = "Manage User"

const DEFAULT_TABS: TabType[] = [
  { label: "User Details", value: "user" },
  { label: "Roles", value: "roles" },
  { label: "Joined Spaces", value: "spaces" },
]

const UsersIndexPage: FC = () => {
  const navigate = useNavigate()
  const userParam = useSearchParams()
  const initialUser = decodeURIComponent(userParam.get("user") ?? "")
  const [userIdInputValue, setUserIdInputValue] = useState<string>(initialUser) // The current value of the input
  const [userId, setUserId] = useState<string>(initialUser) // The userId to pass to the path params
  const userQuery = useUser(userId)
  const [currentTab, setCurrentTab] = useState("user")
  const { permissions: currentPermissions } = useRoles()
  const userSpecificTabs = useMemo(() => {
    const canManageDevices = currentPermissions.includes(Can.ManageMobileDeviceTokens)
    return canManageDevices
      ? [
          ...DEFAULT_TABS,
          {
            label: "User devices",
            value: "userDevices",
          },
        ]
      : DEFAULT_TABS
  }, [currentPermissions])

  const handleUserIdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUserIdInputValue(event.target.value)
    },
    [setUserIdInputValue],
  )

  const handleTabsChange = useCallback(
    (_event: SyntheticEvent<Element>, value: string) => {
      setCurrentTab(value)
    },
    [setCurrentTab],
  )

  const handleUserIdKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        setUserId(userIdInputValue)
      }
    },
    [setUserId, userIdInputValue],
  )

  const updateQueryParam = useCallback(() => {
    const params = new URLSearchParams({ user: encodeURIComponent(userId) })
    navigate(`${window.location.pathname}${userId.length ? `?${params.toString()}` : ""}`, {
      replace: true,
    })
  }, [userId])

  const getCurrentTabContents = () => {
    switch (currentTab) {
      case "user":
        return <UserDetailsTab user={userQuery.data} />
      case "roles":
        return <UserRolesTab userId={userQuery.data?.firebaseAuthId} />
      case "spaces":
        return <JoinedSpacesTab userId={userQuery.data?.firebaseAuthId} />
      default:
        return (
          <TabWrapper>Please let the Platform tools team know how you ended up here...</TabWrapper>
        )
    }
  }

  useEffect(() => {
    if (userQuery.data) {
      updateQueryParam()
    }
  }, [updateQueryParam, userQuery.data])

  return (
    <PageContainer pageTitle={PAGE_TITLE}>
      <Box sx={{ minWidth: 1100 }}>
        <SearchIdInput
          onKeyPress={handleUserIdKeyPress}
          onChange={handleUserIdChange}
          id={userIdInputValue}
          placeholder="Enter User ID, Email, Firebase Auth ID"
          label="Search for User"
        />

        {!userQuery.data && userId.length > 0 && <Box sx={{ p: 2 }}>Loading...</Box>}

        {userQuery.data && (
          <Box>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {userSpecificTabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider />

            {getCurrentTabContents()}
          </Box>
        )}
      </Box>
    </PageContainer>
  )
}

export default UsersIndexPage
