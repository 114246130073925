import {
  listCatalogItems,
  listCatalogItemVariants,
  updateCatalogItem,
  updateCatalogItemVariant,
} from "api/catalogItems"
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query"

import { asUuid } from "gather-common/dist/src/public/stringHelpers"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { CatalogItemVariantPrisma } from "gather-prisma-types/dist/src/public/client"

export const useCatalogItems = (): UseQueryResult<Awaited<ReturnType<typeof listCatalogItems>>> =>
  useQuery("catalogItems", listCatalogItems, {
    initialData: [],
    retry: false,
  })

export const useCatalogItemVariants = (
  catalogItemId: Uuid,
): UseQueryResult<Awaited<ReturnType<typeof listCatalogItemVariants>>> =>
  useQuery("catalogItemVariants", () => listCatalogItemVariants(catalogItemId), {
    initialData: [],
    retry: false,
  })

export const useUpdateCatalogItemVariant = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, ...rest }: Pick<CatalogItemVariantPrisma, "id" | "isPublished">) =>
      updateCatalogItemVariant(id, rest),
    onSuccess: async () => {
      queryClient.invalidateQueries("catalogItemVariants")
    },
  })
}

export const useUpdateCatalogItem = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, order }: { id: string; order: number }) =>
      updateCatalogItem(asUuid(id), order),
    onSuccess: () => {
      queryClient.invalidateQueries("catalogItems")
    },
  })
}
