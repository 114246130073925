import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpaceTemplatePrismaResource } from "gather-common/dist/src/public/v2/resources/spaceTemplate"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const spaceTemplates = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceTemplates,
    pathParams: z.object({}),
    responses: {
      200: c.type<SpaceTemplatePrismaResource[]>(),
      404: c.type<{ error: string }>(),
    },
  },
})
