import { ChangeEvent, useCallback, useState } from "react"

export const useSearchInput = (initialValue = "") => {
  const [draftValue, setDraftValue] = useState(initialValue)
  const [value, setValue] = useState(initialValue)
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDraftValue(event.target.value)
  }, [])

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        setValue(draftValue)
      }
    },
    [draftValue],
  )

  const clear = useCallback(() => {
    setDraftValue("")
    setValue("")
  }, [])

  return {
    draftValue,
    setDraftValue,
    value,
    setValue,
    handleChange,
    handleKeyPress,
    clear,
  }
}
