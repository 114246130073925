import { __action, __actionAllowPromiseReturn, __actionBound } from "./action"
import { __computed, __computedEquals } from "./computed"
import { __flow, __flowBound } from "./flow"
import { __observableClass, __observableClassExcluding } from "./observableClass"

/**
 * All custom MobX decorators we have are exported here, under the `ga` namespace.
 * See "MobX @ Gather" for full docs on using these:
 * https://www.notion.so/gathertown/MobX-Gather-160bc7eac3d180bbab49eb3082224676?pvs=4
 *
 * For more context on why these custom decorators exist, see
 * https://www.notion.so/gathertown/MobX-Custom-Decorators-15bbc7eac3d180e984baf9013df735ac?pvs=4
 *
 * We don't normally do `index.ts` barrel exports like this, but we're doing it here to
 * setup the `ga` namespace.
 * See more context on this naming: https://gather-town.slack.com/archives/C01QLNAQ76W/p1734651942321189
 */
export const ga = {
  /**
   * `@ga.observableClass` turns a class into an observable and enable the use of other `@ga`
   * decorators. Classes that use this decorator must extend `Destructible`.
   */
  observableClass: Object.assign(__observableClass, {
    /**
     * `@ga.observableClass.exclude(…)` is identical to `@ga.observableClass` except it allows
     * classes to opt out of certain properties from being made observable.
     */
    // TODO [GS Rebuild] @vic PLAT-2713 allow specifying exclude directly inline without making this an observable class
    // We currently have to do it this way because of a bug in `swc` that would break a hypothetical `@ga.exclude` decorator in tests
    exclude: __observableClassExcluding,
  }),

  /**
   * `@ga.action` turns a method into a MobX action, much the same as MobX's own `@action` decorator
   * or `makeObservable` call would.
   */
  action: Object.assign(__action, {
    /**
     * `@ga.action.bound` is the same as `ga.action` but it also binds the function to the class's
     * instance scope. This is useful for actions that are passed as event handlers.
     */
    bound: __actionBound,

    /**
     * `@ga.action.allowPromiseReturn` is identical to `ga.action` but it ignores our deliberate
     * type checks that disallow Promise returns. `@ga.action` explicitly should *not* be used on
     * async functions, but in rare cases it may be valid to have a sync function return a Promise.
     * Be careful when using this!
     */
    allowPromiseReturn: __actionAllowPromiseReturn,
  }),

  /**
   * `@ga.computed` turns a getter into a MobX `computed`. Computeds are cached and only
   * recalculated when one of the observed properties is changed. These computeds will NOT
   * suspend like standard MobX computeds.
   */
  computed: Object.assign(__computed, {
    /**
     * `@ga.computed.equals` is the same as `@ga.computed` but allows you to specify a custom
     * equality function to determine if the computed value needs to change.
     */
    equals: __computedEquals,
  }),

  /**
   * `@ga.flow` turns a method into a MobX flow, much the same as MobX's own `@flow` decorator or
   * `makeObservable` call would.
   */
  flow: Object.assign(__flow, {
    /**
     * `@ga.flow.bound` is the same as `ga.flow` but it also binds the function to the class's
     * instance scope. This is useful for flow that are passed as event handlers.
     */
    bound: __flowBound,
  }),

  presenter: {
    /**
     * This is the same as `@ga.computed` but it should only be used in presenters (`@ga.computed`
     * is not allowed in a presenter).
     *
     * YOU CANNOT USE THE PRESENTER'S `this.props` INSIDE THIS COMPUTED. This is because props are
     * not observable, and using them in a computed would cause the computed value to be stale if
     * the prop value its depending on changes. If you care about reacting to prop state in a
     * presenter, talk to @josh or frontend platform for guidance on introducing this as a pattern
     * since we don't have a precedent for this yet.
     *
     * Slack post for context: https://gather-town.slack.com/archives/C06SZ9JST61/p1737767992408489
     */
    computed_DO_NOT_USE_PROPS: __computed,
  },
} as const
