import QueryKeys from "features/queryKeys"
import { useQuery, UseQueryResult } from "react-query"

import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { getSpace } from "../../api/spaces"
import { listSpaceUsers } from "../../api/spaces/users"

/**
 * Fetches a space's details using React Query
 * @param spaceId - the ID of the space
 * @returns React Query object where data is type of SpaceInfo | undefined
 */
export const useSpace = (spaceId?: Uuid): UseQueryResult<Awaited<ReturnType<typeof getSpace>>> =>
  useQuery([QueryKeys.Space, spaceId], () => getSpace(spaceId), {
    initialData: undefined,
    enabled: !!spaceId && spaceId.length > 0,
    retry: false,
    refetchOnWindowFocus: false,
  })

/**
 * Fetches a list of users that belong to a space using React Query
 * @param spaceId - the ID of the space that the users belong to
 * @returns React Query object where data is type of User[] | undefined
 */
export const useSpaceUsers = (
  spaceId?: Uuid,
): UseQueryResult<Awaited<ReturnType<typeof listSpaceUsers>>> =>
  useQuery([QueryKeys.SpaceUsers, spaceId], () => listSpaceUsers(spaceId), {
    initialData: undefined,
    enabled: !!spaceId,
    retry: false,
    refetchOnWindowFocus: false,
  })
