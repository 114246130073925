import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { WearableResource } from "gather-common/dist/src/public/v2/resources/wearables"
import { zodUuid } from "gather-common/dist/src/public/zod-utils"
import { HttpV2Paths, RequestMethod } from "../../httpAPI"

const c = initContract()

export const wearables = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminWearables,
    responses: { 200: c.type<WearableResource[]>() },
  },
  update: {
    method: RequestMethod.PATCH,
    path: HttpV2Paths.AdminWearable,
    pathParams: z.object({ wearableId: zodUuid }),
    body: z.object({ order: z.number() }).partial(),
    responses: { 200: c.type<WearableResource>() },
  },
})
