import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { SpaceUserResource } from "gather-common/dist/src/public/v2/resources/spaceUsers"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"
import { zodUuid } from "../../../zodHelpers"

const c = initContract()

export const users = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminSpaceUsers,
    pathParams: z.object({ spaceId: zodUuid }),
    responses: { 200: c.type<SpaceUserResource[]>() },
  },
})
