import { AnyZodObject, ZodType } from "zod"

import {
  IArrayWillChange,
  IArrayWillSplice,
  intercept,
  IObjectDidChange,
  ISetWillChange,
  isObservable,
  isObservableSet,
  observe,
} from "gather-common/dist/src/public/mobx-utils"
import { DateTimeExt } from "gather-common/dist/src/public/valueObjects/DateTimeExt"
import { isZodSet, zodDeepPick } from "gather-common/dist/src/public/zod-utils"
import { isObject, objectEntries } from "gather-common-including-video/dist/src/public/tsUtils"
import { uuid } from "gather-common-including-video/dist/src/public/uuid"
import { appendPatchPath, PatchPath } from "./patches/patches"

type Disposer = VoidFunction
export type DisposerId = string
type DisposerMap = { [id: DisposerId]: Disposer }

export type ChangeListener = (
  path: PatchPath,
  change: IArrayWillChange | IArrayWillSplice | IObjectDidChange | ISetWillChange,
) => void
export type ChangeEvent = IArrayWillChange | IArrayWillSplice | IObjectDidChange | ISetWillChange

export class ChangeTracker<T extends Record<string, unknown>> {
  // All currently-active MobX disposers
  private disposers: DisposerMap = {}

  // A map of `path` -> the disposer currently tracking changes at that path.
  private disposersByPath: Record<PatchPath, DisposerId> = {}

  private readonly changeListeners = new Set<ChangeListener>()

  /**
   * Creates a new ChangeTracker instance for the given target MobX observable.
   * Does not start tracking changes until `track()` is called.
   * Validates against the schema per observable change
   *
   * @param target The MobX observable to track changes on.
   * @param schema The zod schema of the object you are tracking.
   */
  constructor(readonly target: T, readonly schema: AnyZodObject) {}

  /**
   * Adds a listener to be called whenever any change is detected.
   *
   * @returns A cleanup function to remove the listener
   */
  addChangeListener(listener: ChangeListener): VoidFunction {
    this.changeListeners.add(listener)
    return () => {
      this.changeListeners.delete(listener)
    }
  }

  /**
   * Start tracking changes to the given top-level keys on `this.target`.
   * Nested keys are fully recursively tracked.
   *
   * TODO [GS Rebuild] Handle multiple `track()` calls
   * @throws If `this.target` is not a MobX observable
   */
  track(keys: Set<string & keyof T>) {
    if (!isObservable(this.target)) throw new Error("Target must be observable")

    return this.trackImpl(this.target, "/", keys)
  }

  /**
   * Internal implementation for `.track()`
   *
   * @returns An array of MobX disposerIds created during tracking. These disposers
   *          will all have already been registered in `this.disposers`.
   * @throws If `objTarget` is not a MobX observable
   */
  private trackImpl<TObj extends object>(
    objTarget: TObj,
    pathPrefix: PatchPath,
    keys?: Set<string & keyof TObj>,
  ): DisposerId
  // Specifying specific `keys` to track does not work for arrays, so we disallow it
  private trackImpl<TObj extends unknown[]>(objTarget: TObj, pathPrefix: PatchPath): DisposerId
  private trackImpl<TObj extends object | unknown[]>(
    objTarget: TObj,
    pathPrefix: PatchPath,
    keys?: Set<string & keyof TObj>,
  ): DisposerId {
    if (!isObservable(objTarget))
      throw new Error(`Target ${objTarget} at path ${pathPrefix} is not observable!`)

    // For all reasonable practical use cases of this class, any `object` passed in here
    // should be indexable with `string` keys, so this typecast is safe.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const obj = objTarget as Record<string, unknown> | unknown[] | Set<number | string>

    const disposerId = uuid()
    // Intercept changes before they happen to arrays or sets
    if (Array.isArray(obj) || isObservableSet(obj)) {
      this.disposers[disposerId] = intercept(obj, (rawChange) => {
        // MobX's `intercept()` overload typings can't handle `Array | Set` correctly,
        // instead forcing `rawChange` to be `IObjectWillChange`. We know the correct type
        // is the one below, though (and it's covered by tests).
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const change = rawChange as IArrayWillChange | IArrayWillSplice | ISetWillChange

        this.changeListeners.forEach((listener) => listener(pathPrefix, change))
        // We're returning `rawChange` instead of `change` here to keep TS happy since they're
        // functionally equivalent.
        return rawChange
      })
    } else {
      // Observe changes after they happen for everything except arrays and sets
      this.disposers[disposerId] = observe(obj, (change) => {
        // Ignore changes to untracked object keys (if any)
        if (
          keys &&
          // This is a safe type widening
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          !(keys as Set<string>).has(String(change.name))
        ) {
          return
        }

        // TODO [GS Rebuild] PLAT-2237 Optimize dirtyPaths (e.g. support "merging" paths)
        const keyPath = appendPatchPath(pathPrefix, String(change.name))

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const typedObj = obj as Record<string, unknown>

        this.validateObjectOrRecord(keyPath, typedObj, change)

        // Mark this path as dirty
        this.changeListeners.forEach((listener) => listener(keyPath, change))

        // Re-track new objects on updates (re-assignments)
        if (change.type === "update") {
          // Avoid using implicit `any` types here
          const newValue: unknown = change.newValue
          const oldValue: unknown = change.oldValue
          if (shouldRecursivelyTrack(newValue) && oldValue !== newValue) {
            // Path to the re-assigned object
            const keyPath = appendPatchPath(pathPrefix, String(change.name))

            // Dispose all disposers in this path's entire subtree, because any/all of their
            // corresponding object references may now be outdated given the re-assignment.
            this.disposePathSubtree(keyPath)

            // Re-track this path's subtree (which will repopulate the disposers for it)
            this.trackImpl(newValue, keyPath)
          }
        }
      })
    }

    // Store the disposers created at this path
    this.disposersByPath[pathPrefix] = disposerId

    // Recurse into keys of `obj`
    const entries = isObservableSet(obj)
      ? null // We only support Sets of primitives, so we'll never need to recurse into Sets
      : keys
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        [...keys].map((key) => [key, (obj as Record<string, unknown>)[key]] as const)
      : Object.entries(obj)
    entries?.forEach(([key, val]) => {
      if (!shouldRecursivelyTrack(val)) return

      const keyPath = appendPatchPath(pathPrefix, key)
      this.trackImpl(val, keyPath)
    })

    return disposerId
  }

  /**
   * Takes an observed object and validates it against the schema, which is provided at the instance level.
   * If the object is invalid, it will attempt to reset the object to a valid state, before the change.
   * This method is not meant for observed arrays and sets
   *
   * @param keyPath The path of the observed object on the top-level schema
   * @param obj The observed and changed object
   * @param change The last change(provided by Mobx) in obj
   *
   * Caveat: At the moment, certain zod types are not validated against, ex. Zod unions.
   * See https://github.com/gathertown/gather-town-v2/pull/1781 for details.
   *
   */
  private validateObjectOrRecord(
    keyPath: PatchPath,
    obj: Record<string, unknown>,
    change: IObjectDidChange,
  ) {
    if (!this.schema) return

    const subSchema = this.extractSubSchema(this.schema, keyPath)

    // early return if no matching subSchema at keyPath
    if (!subSchema) return

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const propertyName = change.name as string

    // Only validating that a set is a set and no further due to perf constraints.
    if (isZodSet(subSchema) && isObservableSet(obj[propertyName])) return

    // updates to z.object or z.record fields
    if (change.type === "update") {
      const parseObject = subSchema.safeParse(obj[propertyName])
      if (!parseObject.success) {
        // sanity check the pre-existing value before resetting the field with it to avoid infinite loops
        if (change.oldValue !== change.newValue) {
          obj[propertyName] = change.oldValue
        }
        throw new Error(
          `Error validating against model ${keyPath} with change ${JSON.stringify(change)}`,
        )
      }
      // additions to a z.record
    } else if (change.type === "add") {
      const parseObject = subSchema.safeParse(obj[propertyName])

      if (!parseObject.success) {
        // only checking against additions to a record because
        // remove "shouldn't" cause validation errors unless we are doing something
        // weird with `.refine`, which at that point the developer should be using z.object and/or z.union
        if (change.type === "add") {
          // remove newly added invalid k/v pair
          delete obj[propertyName]
        }

        throw new Error(
          `Error validating against model ${keyPath} with change ${JSON.stringify(change)}`,
        )
      }
    }
  }

  extractSubSchema(schema: ZodType, subSchemaPath: string) {
    try {
      const subSchema = zodDeepPick(schema, subSchemaPath)
      // zodDeepPick will return a non-ZodType for cases we currently want to ignore,
      // ex. for ZodUnions.
      if (subSchema instanceof ZodType) return subSchema
    } catch (e) {
      return
    }
    return
  }

  /**
   * Invokes `dispose()` on all disposers stored in `disposersByPath` at the
   * given `path` and all subpaths. Also clears the corresponding entries in
   * `disposersByPath`.
   *
   * For example, if `path` is "/a" this method might dispose all disposers from:
   *   - "/a"
   *   - "/a/b"
   *   - "/a/c"
   *   - etc
   *
   * This method iterates over _all paths in `disposersByPath`_, but could be optimized
   * to only iterate over the subtree. TODO [GS Rebuild] PLAT-2298
   */
  private disposePathSubtree(rootPath: PatchPath) {
    const prefix = `${rootPath}/`
    objectEntries(this.disposersByPath).forEach(([path, disposers]) => {
      if (path === rootPath || path.startsWith(prefix)) {
        this.dispose(disposers)
        delete this.disposersByPath[path]
      }
    })
  }

  /**
   * Disposes the reaction for the given ID and deletes it from `this.disposers`.
   *
   * Does NOT update `this.disposersByPath` - be careful to sync invocations of this method there!
   */
  dispose = (id: DisposerId) => {
    this.disposers[id]?.()
    delete this.disposers[id]
  }

  destroy() {
    this.changeListeners.clear()
    this.disposersByPath = {}

    Object.keys(this.disposers).forEach((disposerId) => this.dispose(disposerId))
  }
}

// Primitives and Dates (see PLAT-2411) have nothing to recurse into
const shouldRecursivelyTrack = (val: unknown): val is object =>
  isObject(val) && !(val instanceof Date) && !DateTimeExt.isDateTime(val)
