/* Material Icons */
import GitHubIcon from "@mui/icons-material/GitHub"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import Label from "components/dashboard/Label"
import { useUpdateWearable } from "features/wearables/hooks"
import React, { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { WearableResource } from "gather-common/dist/src/public/v2/resources/wearables"

interface Props {
  wearable: WearableResource
}

const WearableRow: FC<Props> = ({ wearable }) => {
  const { mutate: updateWearable } = useUpdateWearable()

  return (
    <TableRow hover>
      <TableCell>
        <Avatar variant="square" src={`https://static.gather.town${wearable.previewUrl}`} />
      </TableCell>

      <TableCell>
        <Grid container gap={1} alignItems="center">
          <Box>{wearable.name}</Box>
          <Link
            color="textPrimary"
            variant="body2"
            component={RouterLink}
            to={`https://github.com/gathertown/gather-catalog-items/tree/main/wearables/${wearable.type}/${wearable.name}/${wearable.color}`}
            target="_blank"
          >
            <GitHubIcon />
          </Link>
        </Grid>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          <Label color="primary">{wearable.type}</Label>
        </Box>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }}>
        <Box component="span" sx={{ mr: 1, mt: 2 }}>
          <Label color="primary">{wearable.color}</Label>
        </Box>
      </TableCell>

      <TableCell>
        <TextField
          type="number"
          size="small"
          defaultValue={wearable.order ?? 10000}
          onChange={(e) => {
            const value = parseInt(e.target.value)
            if (!isNaN(value)) {
              updateWearable({ id: wearable.id, order: value })
            }
          }}
          inputProps={{ min: 0 }}
        />
      </TableCell>
    </TableRow>
  )
}

export default WearableRow
