// TODO @victor: clean up this file and/or this entire module
import { pipe } from "ramda"

import { axios } from "gather-common-including-video/dist/src/public/axios"
import { isNil, isNilOrEmpty } from "gather-common-including-video/dist/src/public/fpHelpers"
import { isPlainObject } from "gather-common-including-video/dist/src/public/tsUtils"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { UserAccountPrisma } from "gather-prisma-types/dist/src/public/client"
import { GATHER_OFFICE_ID, MAX_SPACE_NAME_LENGTH, VALID_SPACE_NAME_PATTERN } from "./constants"
import { asUuid } from "./stringHelpers"

export interface BoundingBox {
  readonly x1: number
  readonly x2: number
  readonly y1: number
  readonly y2: number
}

// Defines an x, y coordinate, without belonging to a map
export type Point = {
  readonly x: number
  readonly y: number
}

export function clamp(num: number, min: number, max: number): number {
  return num <= min ? min : num >= max ? max : num
}

export function dist(x1: number, x2: number, y1: number, y2: number): number {
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
}

export const VALID_ID_CHARS = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890"
export const makeId = (n: number): string =>
  Array.from(new Array(n).keys())
    .map(() => VALID_ID_CHARS[Math.floor(Math.random() * VALID_ID_CHARS.length)])
    .join("")

// given box coordinates _x1, _y1, _x2, _y2,
// parse them to ensure (x1,y1) is top left and (x2,y2) is bottom right.
export const parseBoxCoordinates = (
  _x1: number,
  _y1: number,
  _x2: number,
  _y2: number,
): BoundingBox => {
  let x1 = _x1,
    y1 = _y1,
    x2 = _x2,
    y2 = _y2

  if (_x1 > _x2) {
    x1 = _x2
    x2 = _x1
  }

  if (_y1 > _y2) {
    y1 = _y2
    y2 = _y1
  }
  return { x1, y1, x2, y2 }
}

// given box coordinates (x1, y1) = top left, (x2, y2) = bottom right,
// and given target coordinates (targetX1, targetY1) = target top left, target width, and target height,
// return true if target intersects with the box
export const intersectsBox = (
  boxCoordinates: BoundingBox,
  targetX1: number,
  targetY1: number,
  targetWidth = 1,
  targetHeight = 1,
): boolean => {
  const { x1, y1, x2, y2 } = boxCoordinates
  const targetX2 = targetX1 + targetWidth - 1
  const targetY2 = targetY1 + targetHeight - 1

  return x1 <= targetX2 && x2 >= targetX1 && y1 <= targetY2 && y2 >= targetY1
}

export const overlapsBox = (
  boxCoordinates: BoundingBox,
  targetX1: number,
  targetY1: number,
  targetWidth = 1,
  targetHeight = 1,
): boolean => {
  const { x1, y1, x2, y2 } = boxCoordinates
  const targetX2 = targetX1 + targetWidth - 1
  const targetY2 = targetY1 + targetHeight - 1

  return x1 <= targetX1 && x2 >= targetX2 && y1 <= targetY1 && y2 >= targetY2
}

export const arrayAverage = (array: number[]): number | undefined =>
  array.length > 0 ? array.reduce((acc, e) => acc + e) / array.length : undefined

export async function delay(delayMs: number): Promise<unknown> {
  return new Promise((res) => {
    setTimeout(res, delayMs)
  })
}

export const isValidSpaceName = (spaceName: string): boolean | null =>
  spaceName.match(VALID_SPACE_NAME_PATTERN) && spaceName.length <= MAX_SPACE_NAME_LENGTH

// Use this at the end of switch statements (or anywhere else with branching logic) where you want
// to have TS guarantee your switch clauses were exhaustive.
// Inspiration: https://stackoverflow.com/a/39419171/2672869
//
// Writing `x satisfies never` inline often also works for the same purpose, but `assertUnreachable` works in
// some cases where `satisfies never` doesn't: https://github.com/gathertown/gather-town-v2/pull/541#discussion_r1744258742
// As a result, we should standardize on `assertUnreachable()` until that^ edge case is resolved (if ever).
export function assertUnreachable(x: never): never {
  throw new Error(`assertUnreachable was reachable. received: ${x}`)
}

// When setting date property values, HubSpot recommends using the ISO 8601 complete date
// format, YYYY-MM-DD, as indicated here: https://developers.hubspot.com/docs/api/faq
export const getFormattedHubSpotDate = (date?: Date): string => {
  if (!date) {
    date = new Date(Date.now())
  }
  return date.toISOString().substring(0, 10)
}

// Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const allSettled = (
  promises: Promise<unknown>[],
): Promise<({ status: string; value: unknown } | { status: string; reason: unknown })[]> => {
  const mappedPromises = promises.map((p) =>
    p
      .then((value) => ({
        status: "fulfilled",
        value,
      }))
      .catch((reason) => ({
        status: "rejected",
        reason,
      })),
  )
  return Promise.all(mappedPromises)
}

export const gatherURLRegex =
  /^https?:\/\/(?:((?:(\w|\d|-|\.)*\.)?gather\.town)|(?:localhost:8080))/
export const gatherSpaceURLRegex =
  /^https?:\/\/(?:((?:(\w|\d|-|\.)*\.)?gather\.town)|(?:localhost:8080))\/app/

export const getErrorMessage = (error: unknown, fallbackMessage = "Unknown Error"): string => {
  if (axios.isAxiosError(error)) {
    return (
      error.response?.data.message ?? error.response?.data.errors?.[0]?.message ?? fallbackMessage
    )
  } else {
    return errMsgOrDefault(error)
  }
}

export const guaranteedError = (error: unknown): Error => {
  if (error instanceof Error) return error

  // Without an actual Error, we don't have a stacktrace. Get a stacktrace for the bad error
  // by throwing an error locally.
  try {
    // noinspection ExceptionCaughtLocallyJS
    throw new Error("Thrown error was not an instance of Error")
  } catch (e) {
    if (!(e instanceof Error)) return Error("this will never happen")
    console.error(`Caught error not instanceof Error.

  Original error: ${error}

  Stacktrace:\n\n${e.stack}`)
    return e
  }
}

export const errMsgOrDefault = (e: unknown): string => guaranteedError(e).message

type ErrorContextAttributes = Record<string, string | number>

export const isErrorContextAttributes = (
  attributes: unknown,
): attributes is ErrorContextAttributes =>
  isPlainObject(attributes) &&
  Object.values(attributes).every((value) => typeof value === "string" || typeof value === "number")

export class ErrorContext {
  constructor(public attributes: ErrorContextAttributes, public originalError?: unknown) {}
}

export const buildErrorContext = (error: unknown): ErrorContext | undefined =>
  error ? new ErrorContext({}, error) : undefined

export const errString = (err: unknown): string => {
  // tweak the order things are printed in so it's more useful
  let propertyNames = Object.getOwnPropertyNames(err)
  if (propertyNames.includes("message")) {
    // bring 'message' to the front
    propertyNames = ["message"].concat(propertyNames.filter((n) => n !== "message"))
  }
  if (propertyNames.includes("stack")) {
    // send stack to the back
    propertyNames = propertyNames.filter((n) => n !== "stack").concat(["stack"])
  }
  return JSON.stringify(err, propertyNames)
}

export const uuidRegex = "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"

const parseGatherSpaceIdParam = (spaceIdParam: string) =>
  spaceIdParam.match(new RegExp(`^([^\\/?]*)?(${uuidRegex})`))

const parseGatherPathWithUuid = (url: string) =>
  url.match(
    new RegExp(`^/(?:app|dashboard|mapmaker|app-v1|app-v2|studio)/([^\\/?]*)?(${uuidRegex})`),
  )

const parseGatherURLWithUuid = (url: string) =>
  url.match(
    new RegExp(
      `^https?://(?:(?:(?:w|d|-|.)*.)?gather.town|(?:localhost:8080)|(?:.*ngrok(?:-free)?.app))/(?:app|dashboard|mapmaker)/([^\\/?]*)?(${uuidRegex})`,
    ),
  )

export const getSpaceIdFromSpaceIdParam = (spaceIdParam: string | undefined): Uuid | null => {
  if (!spaceIdParam) return null
  const match = parseGatherSpaceIdParam(spaceIdParam)
  const uuidMatch = match?.[2]
  return uuidMatch ? asUuid(uuidMatch) : null
}

export const getSpaceIdFromURL = (url: string): Uuid | null => {
  const match = parseGatherURLWithUuid(decodeURIComponent(url))
  const uuidMatch = match?.[2]
  return uuidMatch ? asUuid(uuidMatch) : null
}

export const getSpaceIdFromURLOrThrow = (url: string): Uuid => {
  const spaceId = getSpaceIdFromURL(url)
  if (isNil(spaceId)) throw new Error("getAndAssertSpaceIdFromURL failed to find the spaceId!")

  return spaceId
}

export const getSpaceIdFromPath = (path: string): Uuid | null => {
  const decodedPath = decodeURIComponent(path)
  const pathWithSlash = decodedPath.startsWith("/") ? decodedPath : `/${decodedPath}`
  const match = parseGatherPathWithUuid(pathWithSlash)
  const uuidMatch = match?.[2]

  return uuidMatch ? asUuid(uuidMatch) : null
}

// This should only be used when we're directly accessing the spaceId path param from useParams. Otherwise, this conversion is taken care of
// by `getSpaceIdFromUrl` and `getSpaceIdFromPath`
export function getSpaceNameAndIdFromSpacePathParam(spacePathParam: string | undefined): {
  spaceName: string
  spaceId: string
} {
  if (!spacePathParam) return { spaceName: "", spaceId: "" }
  const match = decodeURIComponent(spacePathParam).match(new RegExp(`([^\\/?]*)?(${uuidRegex})`))
  const spaceNameMatch = match?.[1]
  // assume the last character in the space name match is "-"
  const spaceName = spaceNameMatch ? spaceNameMatch.slice(0, -1) : ""
  const spaceIdMatch = match?.[2]

  return { spaceName, spaceId: spaceIdMatch ? asUuid(spaceIdMatch) : "" }
}

// This is not guaranteed to return the correct value, because a space url does not always contain the up-to-date space name.
// Only use this if it's ok for the space name to be empty / outdated and you can't access it from the space repository or
// fetch it from the server.
export const maybeGetSpaceNameFromURL = (url: string): string => {
  const match = parseGatherURLWithUuid(decodeURIComponent(url))
  const spaceNameMatch = match?.[1]
  // Assume the last character in the space name match is "-".
  let spaceName = spaceNameMatch ? spaceNameMatch.slice(0, -1) : ""
  // Convert hyphens to spaces
  spaceName = spaceName.replace(/-/g, " ")
  return spaceName
}

export const isValidURL: (str: string) => boolean = (str): boolean => {
  try {
    new URL(str) // if this line fails, it will throw an error and indicate it is not a valid URL
    return true
  } catch (_) {
    return false
  }
}

interface ParseOptions {
  markdownLinks: boolean
}

export const parseTextFromHTML = (
  html: string,
  options: ParseOptions = { markdownLinks: true },
): string | null => {
  // Replace line breaks
  html = html.replace(/<br>/g, "\n")

  // Replace list items. Assumes list items do not contain other HTML tags.
  html = html.replace(/<li>(.*?)<\/li>/g, (_, p1) => `\n• ${p1}`)

  // Add new line at the beginning and end of an unordered list
  html = html.replace(/<\/?ul>/g, "\n")

  // parses anchor tags into markdown links
  // use linkify() to turn markdown into links
  if (options.markdownLinks) {
    const anchorMatches = html.matchAll(/<a.*?href="(.*?)".*?>.+<\/a>/g)
    Array(...anchorMatches).forEach((match) => {
      const matchedTag = match[0] || ""
      const matchedURL = match[1] || ""
      const matchedInnerText = new DOMParser().parseFromString(matchedTag, "text/html").body
        .textContent

      if (matchedTag && matchedURL && matchedInnerText) {
        html = html.replace(matchedTag, `[${matchedInnerText}](${matchedURL})`)
      }
    })
  }

  return new DOMParser().parseFromString(html, "text/html").documentElement.textContent
}

export const isGatherOfficeSpace = (spaceId?: Uuid): boolean => spaceId === GATHER_OFFICE_ID

export const isAnonymous = (user: Pick<UserAccountPrisma, "email"> | null): boolean =>
  isNilOrEmpty(user?.email)

export const getRandomNumberInRange = (min: number, max: number): number =>
  min + Math.random() * (max - min)

export const getRandomIntegerInRange = pipe(getRandomNumberInRange, Math.floor)

export const getRandomElementInArray = <T>(array: T[]): T | undefined =>
  array[Math.floor(Math.random() * array.length)]

// only allow alphanumeric, dash, underscore, and dot
export function sanitizeFilename(inputString: string): string {
  return inputString.replace(/[^.A-Za-z0-9_-]+/g, "-")
}

export function isSubclass(child: Function, parent: Function): boolean {
  let current = child
  while (current) {
    if (current === parent) return true

    current = Object.getPrototypeOf(current)
  }
  return false
}
