import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { FC } from "react"

import { CatalogItemVariantResource } from "gather-common/dist/src/public/v2/resources/catalogItems"
import VariantRow from "./VariantRow"

interface Props {
  catalogItemVariants: CatalogItemVariantResource[]
}

const CatalogItemsTable: FC<Props> = ({ catalogItemVariants }) => (
  <Box sx={{ minWidth: "100%" }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Orientation</TableCell>
          <TableCell>Color</TableCell>
          <TableCell>Default</TableCell>
          <TableCell>Width x Height</TableCell>
          <TableCell>Origin (x,y)</TableCell>
          <TableCell>Fold</TableCell>
          <TableCell>Sittable</TableCell>
          <TableCell>Collisions</TableCell>
          <TableCell>Published</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {catalogItemVariants.map((catalogItemVariant) => (
          <VariantRow
            key={`catalogItem-${catalogItemVariant.id}`}
            catalogItemVariant={catalogItemVariant}
          />
        ))}
      </TableBody>
    </Table>
  </Box>
)

export default CatalogItemsTable
