import {
  $mobx,
  AnnotationsMap,
  autorun as autorunMobX,
  CreateObservableOptions,
  IAutorunOptions,
  IReactionDisposer,
  IReactionOptions,
  IReactionPublic,
  isObservable,
  makeObservable,
  reaction as reactionMobX,
  runInAction as runInActionMobX,
  when,
} from "mobx"

import { createErrorClass } from "gather-common-including-video/dist/src/public/errors"
import { isNotNil, just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { switchEnv } from "gather-env-config/dist/src/public/env"
import { deferredPromise } from "../promises"
import MobXPerfMetricAutorunWatcher from "../public/mobx/MobXPerfMetricAutorunWatcher"
import MobXPerfMetricReactionWatcher from "../public/mobx/MobXPerfMetricReactionWatcher"

// -------------------------------------------------------------------------------------
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any */
const annotationsSymbol = Symbol()
type NoInfer<T> = [T][T extends any ? 0 : never]
type Annotations<T extends Object = Object, U extends PropertyKey = never> = AnnotationsMap<T, U>

/**
 * Makes a given `target` object auto-observable (infers all properties by default), for `target`s
 * with a superclass. DO NOT use this function for object without a superclass: use
 * `makeAutoObservable` instead.
 *
 * This function's behavior and signature mirror `makeAutoObservable` from mobx:
 * https://mobx.js.org/observable-state.html#makeautoobservable
 *
 * The implementation below is forked from `mobx-store-inheritance`, which in turn is based on the
 * following discussion: https://github.com/mobxjs/mobx/discussions/2850#discussioncomment-497321
 *
 * We're maintaining this ourselves (instead of depending on the package) so we can customize/test
 * it given it's such a critical part of our codebase. TODO [GS rebuild] PLAT-2201 Investigate edge
 * cases with the annotations caching TODO [Rebuild] TODO [GS Rebuild] add some tests for this
 *
 * @deprecated Use `@ga.observableClass` instead! Read more: https://www.notion.so/gathertown/MobX-Gather-160bc7eac3d180bbab49eb3082224676?pvs=4
 */
export const makeAutoObservableForSubclass = <
  T extends object & { [annotationsSymbol]?: any },
  AdditionalKeys extends PropertyKey = never,
>(
  target: T,
  overrides?: Annotations<T, NoInfer<AdditionalKeys>>,
  options?: CreateObservableOptions,
): T => {
  // Make sure nobody called makeObservable/makeAutoObservable/etc. previously
  // (e.g. in parent constructor)
  if (isObservable(target)) throw new Error("Target must not be observable")

  let annotations = target[annotationsSymbol]

  if (!annotations) {
    annotations = {} as Annotations

    let current = target
    while (current && current !== Object.prototype) {
      Reflect.ownKeys(current).forEach((key) => {
        if (key === $mobx || key === "constructor") return
        annotations[key] = !overrides
          ? true
          : key in overrides
          ? overrides[key as keyof typeof overrides]
          : true
      })

      current = Object.getPrototypeOf(current)
    }

    // Cache if class
    const proto = Object.getPrototypeOf(target)
    if (proto && proto !== Object.prototype) {
      Object.defineProperty(proto, annotationsSymbol, { value: annotations })
    }
  } else {
    // Do nothing and just use the annotations object we built up previously.
    //
    // Originally, this was the code contained in the else block:
    //
    //     // Apply only annotations existed in target already
    //     const tmp = {} as Annotations<Object, any>
    //     for (const key in target) {
    //       if (annotations[key]) {
    //         tmp[key] = annotations[key]
    //       }
    //     }
    //     annotations = tmp
    //
    // However, this had incorrect behavior for auto binding methods, because the methods are
    // located on the prototype, not the instance (`target`). The code was inspired from this
    // comment originally: https://github.com/mobxjs/mobx/discussions/2850#discussioncomment-1396837.
    // It appears to just be wrong at least in this case, and is guarding against an edge case
    // that neither Andrew nor Vic could determine. When would the target not have the same
    // annotations as one built for another instance of the same class? We thought it might be
    // to protect against running makeAutoObservable in a superclass, but that was already
    // guarded against in the original sample code by checking for `isObservable`.
  }

  return makeObservable(target, annotations, options)
}
/* eslint-enable @typescript-eslint/consistent-type-assertions */
/* eslint-enable @typescript-eslint/no-explicit-any */
// -------------------------------------------------------------------------------------

/**
 * Wrap MobX's `runInAction` to disallow async functions from being passed in.
 */
export type NoPromise<T> = T extends Promise<unknown> ? never : T
export function runInAction<TReturn>(fn: () => NoPromise<TReturn>): TReturn {
  return runInActionMobX(fn)
}

/**
 * Gather's wrapper around MobX's `reaction` that allows us to collect some metrics.
 */
export function reaction<T, FireImmediately extends boolean = false>(
  expression: (r: IReactionPublic) => T,
  effect: (
    arg: T,
    prev: FireImmediately extends true ? T | undefined : T,
    r: IReactionPublic,
  ) => void,
  opts?: IReactionOptions<T, FireImmediately>,
): IReactionDisposer {
  if (!MobXPerfMetricReactionWatcher.isActive) return reactionMobX(expression, effect, opts)

  const originalDisposer = reactionMobX(
    expression,
    (arg, prev, r) => {
      const start = performance.now()
      try {
        effect(arg, prev, r)
      } finally {
        const finish = performance.now()
        MobXPerfMetricReactionWatcher.incrementTotalExecutionCount(finish - start)
      }
    },
    opts,
  )
  MobXPerfMetricReactionWatcher.incrementTotalCreatedCount()

  const wrappedDisposer: IReactionDisposer = () => {
    originalDisposer()
    MobXPerfMetricReactionWatcher.incrementTotalDisposedCount()
  }

  // This is needed to match the `IReactionDisposer` interface.
  // If we didn't want to do this we could also update our `reaction` wrapper to return `VoidFunction`.
  wrappedDisposer[$mobx] = originalDisposer[$mobx]

  return wrappedDisposer
}

/**
 * Creates a named reactive view and keeps it alive, so that the view is always
 * updated if one of the dependencies changes, even when the view is not further used by something else.
 *
 * (This is Gather's wrapper around MobX's `autorun` that allows us to collect some metrics.)
 *
 * @param view The reactive view
 * @returns disposer function, which can be used to stop the view from being updated in the future.
 */
export function autorun(view: () => void, opts?: IAutorunOptions): IReactionDisposer {
  if (!MobXPerfMetricAutorunWatcher.isActive) return autorunMobX(view, opts)

  const originalDisposer = autorunMobX(() => {
    const start = performance.now()
    try {
      view()
    } finally {
      const finish = performance.now()
      MobXPerfMetricAutorunWatcher.incrementTotalExecutionCount(finish - start)
    }
  }, opts)
  MobXPerfMetricAutorunWatcher.incrementTotalCreatedCount()

  const wrappedDisposer: IReactionDisposer = () => {
    originalDisposer()
    MobXPerfMetricAutorunWatcher.incrementTotalDisposedCount()
  }

  // This is needed to match the `IReactionDisposer` interface.
  // If we didn't want to do this we could also update our `autorun` wrapper to return `VoidFunction`.
  wrappedDisposer[$mobx] = originalDisposer[$mobx]

  return wrappedDisposer
}

const AutoRunAsyncGeneratorRestartError = createErrorClass("AutoRunAsyncGeneratorRestartError")

/**
 * Wraps a generator function (that yields promises) inside a MobX `autorun` block
 */
export function autorunAsync(
  view: () => Generator<Promise<unknown>>,
  opts?: Pick<IAutorunOptions, "onError">,
): VoidFunction {
  const wrappedView = function* () {
    try {
      yield* view()
    } catch (e) {
      // don't bubble these up, just exit, these are used to restart the generator
      if (e instanceof AutoRunAsyncGeneratorRestartError) return
      opts?.onError?.(e)
    }
  }
  const disposers: IReactionDisposer[] = []

  const disposeReactions = () => disposers.splice(0).forEach((disposer) => disposer())

  let currentGenerator: Generator | undefined
  let changed = 0

  // We have some async actions to perform here, but we can't make the top-level function async.
  // Wrap the async actions here.
  const restart = async () => {
    if (currentGenerator) {
      const oldGenerator = currentGenerator
      currentGenerator = undefined
      oldGenerator.throw(new AutoRunAsyncGeneratorRestartError("restart failed"))
    }

    disposeReactions()

    const gen = await trackReaction(wrappedView)
    if (currentGenerator) return // another autorunAsync was started before this one started, bail out
    currentGenerator = gen

    let next = await trackReaction(() => gen.next())

    while (!next.done) {
      try {
        const result = await next.value
        // got executed again, bail out, the generator was already aborted at the top of the method
        if (gen !== currentGenerator) return
        next = await trackReaction(() => gen.next(result))
      } catch (e) {
        next = await trackReaction(() => gen.throw(e))
      }
    }

    currentGenerator = undefined
  }

  const trackReaction = async <TReturn>(fn: () => TReturn) => {
    const { promise, resolve } = deferredPromise<TReturn>()
    // the reaction may not execute the 'expression' immediately so wrap it in a promise and
    // the side effect fn will not be executed unless the result of the 'expression' changes
    // between runs so always force a change with the incremented number
    disposers.push(
      reaction(() => {
        resolve(fn())
        return ++changed
      }, restart),
    )
    return promise
  }

  // noinspection JSIgnoredPromiseFromCall
  restart()

  return disposeReactions
}

/**
 * Wrapper for MobX `autorun`.
 * Accepts a view that returns a cleanup callback, and calls the cleanup callback before each run.
 * The returned disposer function calls both the autorun disposer and the cleanup callback.
 */
export const autorunWithCleanup = (
  view: () => VoidFunction,
  opts?: IAutorunOptions,
): VoidFunction => {
  let cleanupCallback: VoidFunction | undefined
  const disposer = autorun(() => {
    cleanupCallback?.()
    cleanupCallback = view()
  }, opts)

  return () => {
    cleanupCallback?.()
    disposer()
  }
}

/**
 * Wrapper for MobX `reaction`.
 * Accepts an effect that returns a cleanup callback, and calls the cleanup callback before each
 * run. The returned disposer function calls both the reaction disposer and the cleanup callback.
 */
export const reactionWithCleanup = <T, FireImmediately extends boolean = false>(
  expression: (r: IReactionPublic) => T,
  effect: (
    arg: T,
    prev: FireImmediately extends true ? T | undefined : T,
    r: IReactionPublic,
  ) => VoidFunction,
  opts?: IReactionOptions<T, FireImmediately>,
) => {
  let cleanupCallback: VoidFunction | undefined
  const disposer = reaction(
    expression,
    (arg, prev, r) => {
      cleanupCallback?.()
      cleanupCallback = effect(arg, prev, r)
    },
    opts,
  )

  return () => {
    cleanupCallback?.()
    disposer()
  }
}

type CancellablePromise<T> = Promise<T> & { cancel(): void }

/**
 * Waits for `predicate` to return a defined value, then resolves with that value.
 *
 * @param predicate A function that returns the value to wait for.
 * @return A promise with a `.cancel()` method that cancels the waiting.
 */
export const waitForExistence = <T>(
  predicate: () => T | null | undefined,
): CancellablePromise<T> => {
  const promise = when(() => isNotNil(predicate()))
  const deferred = deferredPromise<T>()
  promise.then(() => deferred.resolve(just(predicate()))).catch(deferred.reject)

  // We'll add the `cancel()` interface on immediately below
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const retPromise = deferred.promise as CancellablePromise<T>
  retPromise.cancel = () => promise.cancel()

  return retPromise
}

/**
 * Wraps a promise in a typed generator. Useful in places like `autorunAsync()` to improve typing in some situations
 * where the simpler `yield promise` wouldn't work. More context: https://github.com/gathertown/gather-town-v2/pull/250#issuecomment-2276862391
 * Usage:
 *
 *     yield* yieldPromise(promise)
 */
export function yieldPromise<T>(value: Promise<T>): Generator<Promise<T>, T, T>
export function yieldPromise<T>(
  value: Promise<T> | undefined,
): Generator<Promise<T>, T | undefined, T>
export function* yieldPromise<T>(
  value: Promise<T> | undefined,
): Generator<Promise<T>, T | undefined, T> {
  if (value === undefined) return undefined
  return yield value
}

export function resolveGenerator<T, R>(generator: Generator<Promise<T>, R, T>): Promise<R> {
  function process(result: IteratorResult<Promise<T>, R>): Promise<R> {
    if (result.done) return Promise.resolve(result.value)

    return result.value.then((value) => process(generator.next(value)))
  }
  return process(generator.next())
}
/**
 * clears an object of all keys, triggering MobX reactivity
 */
export function clearObject<T extends Record<string, unknown>>(obj: T): void {
  for (const key in obj) {
    delete obj[key]
  }
}

/**
 * A helper function used to access getters on MobX observables to "mark" them as dependencies in the
 * current `autorun` (or other derivation). This is just syntax sugar - seeing the words `markDeps()`
 * helps readers understand what you're doing (referencing those observables).
 *
 * This function is a no-op in prod-like envs, but in dev-like envs will run validation checks.
 *
 * Example usage:
 *
 *     // Inside an `autorun()` somewhere...
 *      markDeps(spaceUser.currentMapArea) // explicitly tell MobX to react to this
 *      runInAction(() => {
 *        // Anything inside here will NOT cause the autorun to react
 *        this.doStuff()
 *      })
 *
 * Read more: https://gather-town.slack.com/archives/C06SZ9JST61/p1742409578259589
 *
 * @param deps MobX observables to mark as dependencies for the current derivation (e.g. `autorun`, `reaction`)
 */
export const markDeps = switchEnv({
  prod: () => noop,
  staging: () => noop,
  test: () => markDepsWithValidation,
  dev: () => markDepsWithValidation,
  local: () => markDepsWithValidation,
})

function noop(..._deps: unknown[]) {}
function markDepsWithValidation(...deps: unknown[]) {
  deps.forEach((dep, i) => {
    // There's not that much validation we can actually do because computeds could return literally anything... even functions.
    // Functions specifically should be rare enough to return from computeds that we can just throw an error if we see one.
    if (typeof dep === "function") {
      throw new Error(
        `markDeps() received function dep ${dep} at index ${i}! You don't need to mark functions you're calling as deps, since they aren't observables. If you're returning a function from a computed, manually mark it separately.`,
      )
    }
  })
}

export type {
  AnnotationsMap,
  IArrayWillChange,
  IArrayWillSplice,
  IAutorunOptions,
  IObjectDidChange,
  IObservableArray,
  IObservableValue,
  IReactionDisposer,
  ISetWillChange,
} from "mobx"
export {
  $mobx,
  action,
  comparer,
  flow,
  intercept,
  isComputed,
  isComputedProp,
  isObservable,
  isObservableProp,
  isObservableSet,
  makeAutoObservable,
  makeObservable,
  observable,
  ObservableSet,
  observe,
  toJS,
  trace,
  untracked,
  when,
} from "mobx"
export type { IViewModel } from "mobx-utils"
export { computedFn, createViewModel } from "mobx-utils"
