export enum RequestMethod {
  "GET" = "GET",
  "POST" = "POST",
  "PATCH" = "PATCH",
  "DELETE" = "DELETE",
  /** Use PATCH instead: https://www.notion.so/gathertown/http-server-standards-748d5e2b891844fda302fd17d5cf8bff?pvs=4#e8083eb5003d4853bc47c13cfce1edc6 */
  "PUT" = "PUT",
}

export enum HttpV2Paths {
  _V1_STUB = "/v1-implementation-stubbed-out",
  _Template = "/_template/:id",
  /* Releases */
  BrowserClientReleases = "/releases/browser/:browserName/:releaseCommit/:releaseChannel",
  DesktopClientReleases = "/releases/desktop/:platform/:releaseVersion/:releaseChannel",
  DesktopClientLatest = "/releases/desktop/latest",

  /* Users */
  UserRecentSpaces = "/users/:userId/recent-spaces",
  UserOwnedSpaces = "/users/:userId/owned-spaces",
  User = "/users/:userId",
  UserFile = "/users/:userId/files/:fileId",

  /* Spaces */
  Spaces = "/spaces",
  Space = "/spaces/:spaceId",
  SpaceUsers = "/spaces/:spaceId/users",
  SpaceUser = "/spaces/:spaceId/users/:userId",
  SpaceUserOnboarding = "/spaces/:spaceId/users/:userId/onboarding",
  SpaceUserBaseCalendarEvents = "/spaces/:spaceId/users/:userId/base-calendar-events",
  SpaceUserBaseCalendarEventsSync = "/spaces/:spaceId/users/:userId/base-calendar-events/sync",
  SpaceOnboardingSurvey = "/spaces/:spaceId/surveys/onboarding",
  SpaceSettings = "/spaces/:spaceId/settings",
  SpaceNooksSpawnTokens = "/spaces/:spaceId/nooks",
  SpaceFile = "/spaces/:spaceId/files/:fileId",
  SpaceFiles = "/spaces/:spaceId/files",
  SpaceSSO = "/spaces/:spaceId/sso",
  SpaceSSOEmailDomains = "/spaces/:spaceId/sso/email-domains",
  SpaceSSOSettings = "/spaces/:spaceId/sso/settings",
  SpaceSSOLinkedSpaces = "/spaces/:spaceId/sso/linked-spaces",
  SpaceSSOLinkedSpaceSource = "/spaces/:spaceId/sso/linked-space-source",
  SpaceSSOEmailDomainVerification = "/spaces/:spaceId/sso/email-domain/verify",

  /* Space Floor */
  SpaceFloorPreview = "/spaces/:spaceId/floors/:floorId/preview",

  /* Insights */
  SpaceInsights = "/spaces/:spaceId/insights/:metric",
  SpaceInsightsMetricDetails = "/spaces/:spaceId/insights-details/:metric",
  SpaceInsightsSummary = "/spaces/:spaceId/insights-summary/:metrics",

  /* Hubspot */
  HubSpotCreateContact = "/hubspot/contacts/create",
  HubSpotUpdateContact = "/hubspot/contacts/update",
  HubSpotUpdateContactNumberProperties = "/hubspot/contacts/update-number-properties",

  /* Support Requests */
  SupportRequests = "/support-requests",

  /* OTP Auth */
  AuthOtpRequests = "/auth/otp-requests",
  AuthOtpRequestsVerify = "/auth/otp-requests/verify",

  /* SSO Auth */
  AuthSSORequests = "/auth/sso/initiate",
  AuthSSOTokenSwap = "/auth/sso/token-swap",
  AuthSSOUserIsMemberOfActiveOrganizationByEmail = "/auth/sso/member",

  /* Google Auth */
  AuthGoogleTokenSwap = "/auth/google/token",
  AuthGoogleSignInAuthUrl = "/auth/google/authorize",

  /* Wearables */
  Wearables = "/wearables",
  Wearable = "/wearables/:wearableId",

  /* Gather Admin */
  AdminAvClientStateSessions = "/admin/av-client-state-sessions",
  AdminAvClientStateSession = "/admin/av-client-state-sessions/:sessionId",
  AdminAvClientStateUserFeedback = "/admin/av-client-state-sessions/:sessionId/userFeedback",

  AdminUserAccounts = "/admin/users",
  AdminUserAccount = "/admin/users/email-or-id/:userId",
  AdminUserSpaces = "/admin/users/:userId/spaces",
  AdminSpace = "/admin/spaces/:spaceId",
  AdminSpaceCapacity = "/admin/spaces/:spaceId/capacity",
  AdminSpaceUsers = "/admin/spaces/:spaceId/users",
  SuperAdminUserRoles = "/admin/super-admin-users/:adminUserId/roles",
  SuperAdminUsers = "/admin/super-admin-users",
  SuperAdminUser = "/admin/super-admin-users/:adminUserId",
  AdminWearables = "/admin/wearables",
  AdminWearable = "/admin/wearables/:wearableId",
  AdminCatalogItems = "/admin/catalog-items",
  AdminCatalogItem = "/admin/catalog-items/:catalogItemId",
  AdminCatalogItemVariants = "/admin/catalog-items/:catalogItemId/variants",
  AdminCatalogItemVariant = "/admin/catalog-item-variant/:catalogItemVariantId",

  /* Cloudflare */
  CloudflareSiteVerify = "/integrations/cloudflare/siteverify",

  /* Third Party Service Webhooks */
  WorkOSWebhook = "/webhooks/workos/8XIspKIObRYIHTDXVeCTuqEe", // WorkOS Webhook ObfuscatedUrl
  HookAssemblyAiTranscription = "/hooks/assemblyai/transcription",

  /* Internal Service Webhooks (S3) */
  HookAVAudioRecordingS3 = "/hooks/audio-recordings/s3",
  HookAVMeetingSummaryS3 = "/hooks/meeting-summaries/s3",

  /* Scheduled Tasks */
  // Beware that changing this will require changing the values in the `Google Cloud Scheduler` service as well
  ScheduledDeleteRecordings = "/scheduled-tasks/delete-recordings",

  /* Chrome Extension Logs */
  PublishLog = "/internal/logs",

  /* NewRelic */
  NewRelicCustomEvents = "/newrelic/custom-events",

  SpaceAreas = "/spaces/:spaceId/areas",
  SpaceMeetingJoinInfo = "/spaces/:spaceId/meeting-join-info",
  SpaceMeetingJoinInfoLink = "/spaces/:spaceId/meeting-join-info/:linkId",
  MeetingJoinInfoLink = "/meeting-join-info/:linkId",
  SpaceTemplates = "/space-templates",

  /* Browser Extension */
  BrowserExtensionConfig = "/browser-extension/config",
}

// Beware that changing this will require changing the values in the external service as well
export enum HttpOAuthPaths {
  GoogleCalendar = "/google-calendar/callback",
  Microsoft = "/microsoft/callback",
  GoogleSignIn = "/signin/google/callback",
  SSOSignIn = "/signin/sso/callback",
}

// Sensible default used for `:user` when path params are not provided
export const DEFAULT_PATH_PARAM_USER = "me" // nosemgrep
