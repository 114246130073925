import { ga } from "gather-common/dist/src/public/mobx/decorators"
import { BaseValueObject } from "gather-state-sync/dist/src/public/BaseValueObject"
import { s, SchemaInfer } from "../framework/schema/schema"

export enum SpeedModifier {
  WALKING = 1,
  RUNNING = 2,
  DRIVING = 3,
}

export const speedSchema = s.object({
  modifier: s.number().default_UNIMPLEMENTED(1),
})

type SpeedData = SchemaInfer<typeof speedSchema>

@ga.observableClass
export class Speed extends BaseValueObject(speedSchema.modelZod) {
  static WALKING = SpeedModifier.WALKING
  static RUNNING = SpeedModifier.RUNNING
  static DRIVING = SpeedModifier.DRIVING

  // This number seems high because the predicted position of the player is higher than the actual position
  // so the route length is longer than what it visually looks like
  static TILE_PATH_LENGTH_REMAINDER_TO_WALK = 6
  static TILE_PATH_LENGTH_REMAINDER_TO_RUN = 16
  static TILE_PATH_LENGTH_REMAINDER_TO_DRIVE = 40

  constructor(data: SpeedData)
  constructor(speed: number)
  constructor(speedOrData: number | SpeedData) {
    super(typeof speedOrData === "number" ? { modifier: speedOrData } : speedOrData)
  }
}
