import { Tagged } from "type-fest"

/**
 * Algebraic data types (https://en.wikipedia.org/wiki/Algebraic_data_type) for providing stronger
 * type safety in our system.
 */
export type Email = Tagged<string, "Email">
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const asEmail = (x: string) => x as Email

export type Url = Tagged<string, "Url">
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const asUrl = (x: string) => x as Url
