import { AuthManagerInstance } from "gather-auth-client/dist/src/public/auth"
import {
  createTsrApiClient,
  createTsrApiFullClient,
} from "gather-http-v2-client/dist/src/public/tsRestClient"
import { API_BASE_PATH } from "./basePaths"
import { addToGlobalWindowForLocalDebugging } from "./public/addToGlobalWindowForLocalDebugging"
import { getCurrentSpaceId } from "./spaceIdUtils"

export const tsrAPIPathParams = {
  spaceId: (spaceId?: string) => encodeURIComponent(spaceId ?? getCurrentSpaceId()),
}

export const tsrAPIFullClient = createTsrApiFullClient({
  apiBasePath: API_BASE_PATH,
  authTokenManager: AuthManagerInstance,
})

export const tsrAPI = createTsrApiClient({
  apiBasePath: API_BASE_PATH,
  authTokenManager: AuthManagerInstance,
})

addToGlobalWindowForLocalDebugging({ tsrAPI })
