import { isEqual } from "lodash"
import { Class, ReadonlyDeep, Writable } from "type-fest"
import { EnumLike, z } from "zod"

import { ga } from "gather-common/dist/src/public/mobx/decorators"
import {
  autorun,
  IObservableValue,
  isObservableSet,
  runInAction,
  when,
} from "gather-common/dist/src/public/mobx-utils"
import { guaranteedError } from "gather-common/dist/src/public/utils"
import { zodKeys, zodUuid } from "gather-common/dist/src/public/zod-utils"
import { createErrorClassGroup } from "gather-common-including-video/dist/src/public/errors"
import { Logger } from "gather-common-including-video/dist/src/public/Logger"
import { isObject } from "gather-common-including-video/dist/src/public/tsUtils"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { ChangeListener, ChangeTracker } from "./ChangeTracker"
import { cloneForPatch } from "./patches/cloneForPatch"
import {
  AddModelPatch,
  EncodedPatch,
  isTopLevelPath,
  joinPatchPaths,
  ModelScopedPatch,
  ModelScopedPatchOp,
  PatchPath,
  splitPatchPath,
} from "./patches/patches"
import { StateSyncContextInternal } from "./StateSyncContext"
import { StateSyncInjectedData, StateSyncInternals } from "./StateSyncInternals"

// Re-exporting Uuid type to fix "The inferred type of 'Uuid' cannot be named without a reference to..." error
export type { Uuid }

// TODO [GS Rebuild] This definition should come from gather-common-including-video/dist/src/public/tsUtils
// We're inlining it here because TS 5.3.3 (but not later versions) is complaining about subclasses of
// `Model` not being able to name their type without a reference to tsUtils if we import it from there
type StaticInterfaceOfClass<T extends Class<unknown>> = Omit<T, "prototype">

/**
 * All Model classes created via `Model` will satisfy this type.
 */
export type ModelClass<TModelKey extends string> = ReturnType<
  typeof ModelFactory<TModelKey, ModelSchema>
> extends (new (data: infer _, ...rest: infer TRestArgs) => infer TInstance) & {
  schema: infer TSchema
  key: TModelKey
  decodeFullStatePatch: infer TDecode
  encodeFullStatePatch: infer TEncode
}
  ? // We unfortunately need to rewrite the constructor params to be `any` here in order for
    // all Models to satisfy this type. Without this, TS complains b/c the following constructor
    // signatures don't match (the 2nd cannot be assigned to the 1st):
    //     new (data: { id: string }) => { id: string }
    //     new (data: { id: string; name: string }) => { id: string; name: string }
    // The former is what we want to enforce for schemas passed to `Model`, so we need to
    // keep that constraint (via `ModelSchema`) but relax it here.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (new (data: any, ...rest: TRestArgs) => TInstance) & {
      schema: TSchema
      key: TModelKey
      decodeFullStatePatch: TDecode
      encodeFullStatePatch: TEncode
    }
  : never

export type ModelInstance = InstanceType<ModelClass<string>>

// All models must have a unique `id`
type ModelRawShape = { id: Uuid }
export type ModelSchema = z.ZodObject<{ id: typeof zodUuid }>

export type BaseSuperClass = Class<ModelRawShape> & { schema: ModelSchema }

export type SchemaOf<TModelClass extends ModelClass<string>> = TModelClass["schema"]
export type ShapeOf<TModelClass extends ModelClass<string>> = z.infer<SchemaOf<TModelClass>>

export type EnumValue<EnumT extends EnumLike = EnumLike> = EnumT[keyof EnumT]

enum ModelErrorType {
  KeyNotFound = "KeyNotFound",
  IsVisible_PERF_SENSITIVE_NotImplemented = "IsVisible_PERF_SENSITIVE_NotImplemented",
}

export const ModelError = createErrorClassGroup("ModelError", ModelErrorType)

/**
 * A factory to create a Model, a base class that can be extended to create models.
 * Usage:
 *     class MyModel extends Model("myModel", schema, OptionalSuperClass) {}
 *
 * @param modelKey The model key associated with the model class
 * @param selfSchema A Zod schema that defines the shape of the model
 * @param superClass An optional superclass (that also extends Model)
 * @returns A Model class configured for the provided schema
 */
export const Model = <
  TModelKey extends string,
  TSelfSchema extends ModelSchema,
  TSuperClass extends BaseSuperClass = BaseSuperClass,
  TPermissionsEnumType extends EnumLike = EnumLike,
>(
  modelKey: TModelKey,
  selfSchema: TSelfSchema,
  superClass?: TSuperClass,
) =>
  ModelFactory<TModelKey, TSelfSchema, TSuperClass, EncodedPatch<TModelKey>, TPermissionsEnumType>(
    modelKey,
    selfSchema,
    { superClass },
  )

/**
 * A factory to create a Model *with custom patch behavior*
 * Usage:
 *     class MyModel extends ModelWithCustomPatchLegacy("myModel", schema, OptionalSuperClass)<TEncodedPatch>() {}
 *
 * @param modelKey The model key associated with the model class
 * @param selfSchema A Zod schema that defines the shape of the model
 * @param superClass An optional superclass (that also extends Model)
 * @returns A Model class configured for the provided schema
 */
export const ModelWithCustomPatch =
  <
    TModelKey extends string,
    TSelfSchema extends ModelSchema,
    TSuperClass extends BaseSuperClass = BaseSuperClass,
    TPermissionsEnumType extends EnumLike = EnumLike,
  >(
    modelKey: TModelKey,
    selfSchema: TSelfSchema,
    superClass?: TSuperClass,
  ) =>
  <TEncodedPatch extends EncodedPatch<TModelKey> = EncodedPatch<TModelKey>>() =>
    ModelFactory<TModelKey, TSelfSchema, TSuperClass, TEncodedPatch, TPermissionsEnumType>(
      modelKey,
      selfSchema,
      { superClass },
    )

const defaultContext = () => {
  throw new Error("Model context not set")
}

const noop = () => {}

// User Information used in the Visibility API. Based on UserInfo (defined in game-logic)
export type VisibilityUserInfo = {
  authUserId: string
  id: Uuid
  // if any additional fields are added here, also update Model.isVisibleSafe's exhaustive check
}

// Another piece of data for the Visibility API. This will almost always exist, but in some specific circumstances
// the GS may want to run visibility checks without it.
export type VisibilityConnectionIdBox = IObservableValue<Uuid | undefined>

/**
 * The underlying factory to create a Model, a base class that can be extended to create models.
 *
 * TODO [GS Rebuild] cleanup this interface, probably use an options object
 *
 * @param modelKey The model key associated with the model class
 * @param selfSchema A Zod schema that defines the shape of the model
 * @param options.superClass An optional superclass (that also extends Model)
 * @param options.immutable If true, this model will be typed as deeply-readonly, will no longer have MobX observable data fields,
 *                          and won't have change tracking (will never produce deltas).
 * @returns A Model class configured for the provided schema
 */
export const ModelFactory = <
  TModelKey extends string,
  TSelfSchema extends ModelSchema,
  TSuperClass extends BaseSuperClass = BaseSuperClass,
  TEncodedPatch extends EncodedPatch<TModelKey> = EncodedPatch<TModelKey>,
  TPermissionsEnumType extends EnumLike = EnumLike,
  TImmutable extends boolean = false,
>(
  modelKey: TModelKey,
  selfSchema: TSelfSchema,
  options?: {
    superClass?: TSuperClass
    immutable?: TImmutable
  },
) => {
  // The ordering here is important: subclass should override superclass
  const schema = z.object({
    ...options?.superClass?.schema._def.shape(),
    ...selfSchema._def.shape(),
  })

  // Really what we want here is `TSchema = typeof schema`, but that doesn't work due to the custom merging above.
  // This type below is close and works better for us.
  type TSchema = TSelfSchema & TSuperClass["schema"]

  type ModelState = z.infer<TSchema>
  type ModelStateKey = string & keyof ModelState

  type TEncodedAddModelPatch = Extract<TEncodedPatch, { op: "addmodel" }>
  type TEncodedModelScopedPatch = Extract<TEncodedPatch, { op: ModelScopedPatchOp }>

  /**
   * The public interface of a Model instance, excluding data accesses (see `ModelState` below).
   *
   * We need to [redundantly] define this separately in order to allow the actual implementation
   * below to use `private` access modifiers. If we instead derived this interface type from the
   * implementation, TS would not be happy:
   *     "Property of exported class expression may not be private or protected"
   */
  type ModelPublic = {
    // All models must have a unique `id`
    readonly id: Uuid
    destroyed: boolean
    context: () => StateSyncContextInternal

    addChangeListener(listener: ChangeListener): VoidFunction

    encodeModelScopedPatches(patches: ModelScopedPatch<TModelKey>[]): TEncodedModelScopedPatch[]
    decodeModelScopedPatch(patch: TEncodedModelScopedPatch): ModelScopedPatch<TModelKey>

    genFullStatePatch(): AddModelPatch<TModelKey, TSchema>

    replaceModelState(
      newState: Exclude<ModelState, "id">,
      compareWithCurrent?: boolean,
      defensiveCopy?: boolean,
    ): void
    applyPatch(patch: TEncodedModelScopedPatch): void

    /**
     * Optional callback invoked after the model is created in the Database.
     * Note: Actions will return to the client before the `afterCreate` hooks run.
     * * @throws If model state changes are detected when it's invoked.
     */
    afterDbCreate?(): void

    /**
     * Optional callback invoked after the model is updated in the Database.
     * Note: Actions could return to the client before the `afterDbUpdate` hooks run, depending on `debounced()` options.
     * * @throws If model state changes are detected when it's invoked.
     */
    afterDbUpdate?(): void

    getFieldAtPath(path: PatchPath): [unknown, undefined] | [undefined, Error]

    /**
     * enablePermissions is a way for a subclass to declare that it would like to participate in
     * permissions. If left false (default), permissions checks will be ignored. If a method action
     * has a non-public permission, it will throw an error. If enabled, calls to method actions will
     * compute permissions via role assignment and implicit permissions via overridePermissions.
     */
    enablePermissions: boolean

    overridePermissions(
      userId: Uuid,
      explicitPermissions: ReadonlySet<EnumValue<TPermissionsEnumType>>,
    ): ReadonlySet<EnumValue<TPermissionsEnumType>>
    getPermissions: () => ReadonlySet<EnumValue<TPermissionsEnumType>>
    hasPermission(permission: EnumValue<TPermissionsEnumType>): boolean

    /**
     * THIS METHOD IS EXTREMELY PERF SENSITIVE!
     * Think carefully about all logic you put in here, and err on the side of over-optimizing.
     * This includes (but is not limited to) all the common tactics listed here:
     * https://www.notion.so/gathertown/Perf-in-GS-Internals-19fbc7eac3d1802dbf0ecd39a2c245ee?pvs=4
     * You can also look at other models for inspiration for writing performant visibility checks.
     * Don't hesitate to ask for help and/or second opinions anytime!
     *
     * @returns `true` if the model should be visible to the user specified by `userInfo`
     */
    isVisible_PERF_SENSITIVE(
      userInfo: VisibilityUserInfo,
      connectionIdBox: VisibilityConnectionIdBox,
    ): boolean
    isVisibleSafe_INTERNAL(
      userInfo: Partial<VisibilityUserInfo>,
      connectionIdBox: VisibilityConnectionIdBox,
    ): boolean

    cloneData(): Readonly<ModelState>
    hasSameData(data: Readonly<ModelState>): boolean

    afterNew(): void

    invariants(): boolean[]

    addDestructor(fn: () => void): void
    removeDestructor(fn: () => void): void
    get isValid(): boolean
    destroy(): void
  }

  // The public static interface of a Model class
  type ModelPublicStatic = {
    schema: TSchema
    key: TModelKey
    encodeFullStatePatch(patch: AddModelPatch<TModelKey, TSchema>): TEncodedAddModelPatch
    decodeFullStatePatch(patch: TEncodedAddModelPatch): AddModelPatch<TModelKey, TSchema>
  }

  // A list of all top-level fields on the model schema
  const dataKeys: ModelStateKey[] = zodKeys(schema)
  const dataKeysSet = new Set(dataKeys)

  const mutableDataKeysSet = new Set(dataKeys.filter((key) => key !== "id"))

  // We need to extract this out so the decorator applies to `class Model` instead of this class here
  const SuperClass = options?.superClass ?? class {}

  // TODO "permissionsOptions" should live in StateSyncInjectedData
  @ga.observableClass.exclude([
    StateSyncInternals,
    StateSyncInjectedData,
    "permissionOptions",
    "destroyed",
    "invariantCheckerDisposer",
    // Immutable models shouldn't have any observable data fields.
    // Regular models should still exclude `id` given it's `readonly` on all models.
    ...(options?.immutable ? dataKeys : ["id"]),
  ])
  class Model extends SuperClass implements ModelPublic {
    /** A unique identifier for this model instance */
    readonly id: Uuid

    destroyed = false

    // This is functionally true in practice, but TS doesn't think so b/c "could be instantiated with a different subtype"
    // We need to slightly refine `typeof schema` to `TSchema` to make the schema merging we're doing work nicely.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    static schema = schema as TSchema
    static key = modelKey

    private invariantCheckerDisposer?: VoidFunction

    // A TS helper util for representing `this` as the `ModelState` it wraps.
    // We know this typing is safe for the same reasons that we feel confident
    // performing `ModelState` casts in the return function at the end of
    // `Model` - see below.
    private get asModelState(): ModelState {
      return this
    }

    get isValid() {
      return this._isValid
    }

    private _isValid: boolean = true

    // TODO - PLAT-2433 - Move public properties under this namespace
    private readonly [StateSyncInternals]: {
      // `this.changeTracker` doesn't exist in 2 scenarios:
      //   1. if the model hasn't been made observable yet (e.g. during construction, see `afterObservable()`)
      //   2. if the model is immutable (see `options?.immutable`)
      readonly changeTracker?: ChangeTracker<ModelState>
      destructors: VoidFunction[]
      readonly context: () => StateSyncContextInternal
      cancelAfterNewWait?: VoidFunction
      clonedDataCache?: ModelState
    }

    constructor(
      data: ModelState,
      // Stored as the instance's context. Consumers of state sync can use this to inject arbitrary context
      // into models.
      context?: () => StateSyncContextInternal,
      // Whether this constructor is a superclass of the actual model class.
      // If so, we shouldn't attempt work that only the actual model class can do, including:
      //   - making the model observable / tracking changes
      //   - validating the data against the schema
      isSuperClassConstructor = false,
    ) {
      // We'll handle making this model observable ourselves - don't do it in the superclass
      // Also don't run the constructor hook in the superclass
      super(data, noop, true /* isSuperClassConstructor */)

      this.id = data.id

      // Validate the data against the schema if we're not a superclass constructor.
      // This unfortunately not as immediate of feedback as a static type check like `Exact<>` would be,
      // but using `Exact<>` typing has its own significant DX downsides that may not be worth working through.
      // TODO [GS Rebuild] Decide on runtime vs static validation here
      if (!isSuperClassConstructor) {
        for (const key in data) {
          // Specifying extra data fields that aren't in the model schema could cause us to cache the
          // wrong annotations for future instances of this model class.
          if (!dataKeysSet.has(key)) {
            // Drop unrecognized data so we can interact with newer server versions with added fields
            Logger.warn(`Dropping unrecognized field ${key} in patch to ${modelKey}:${this.id}`)
            delete data[key]
          }
        }
      }

      // IMPORTANT: `replaceModelState()` defensive copies the incoming data, which is necessary to make
      // it impossible to accidentally indirectly modify a model.
      // This has happened before - see INC-524 for example.
      this.replaceModelState(data)

      // Default unspecified fields to `undefined` so they'll still get tracked.
      // See PLAT-2292 for full context.
      dataKeys.forEach((key) => {
        if (!(key in this)) {
          // Any schema key that doesn't exist yet must be an optional field, otherwise
          // there'd be type errors in the constructor.
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ;(this.asModelState[key] as unknown | undefined) = undefined
        }
      })

      this[StateSyncInternals] ??= {
        destructors: [],
        context: context ?? defaultContext,
      }

      // We can't invoke `afterNew()` until the currently-running action (if any) has completed.
      // See `afterNew()` for more.
      const promise = when(() => (context?.()?.nestedActionCount ?? 0) === 0)
      this[StateSyncInternals].cancelAfterNewWait = promise.cancel
      promise
        .then(() => {
          if (this.destroyed) return
          try {
            this.afterNew()
          } catch (e) {
            Logger.error(`Failed to execute afterNew ${modelKey}:${this.id}`, e)
          }
          this.invariantCheckerDisposer = autorun(() => {
            if (!this._isValid) return
            const allInvariantsPass = !this.invariants().some((invariant) => !invariant)

            runInAction(() => {
              this._isValid = allInvariantsPass

              if (!this._isValid) {
                this.invariantCheckerDisposer?.()
                this.invariantCheckerDisposer = undefined
                throw new Error(
                  `!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!\nINVALID INVARIANT DETECTED for ${modelKey}:${this.id}\n\nTHIS SHOULD NEVER HAPPEN and this model is no longer being persisted to the DB.\nPlease investigate and fix this immediately. Check the \`invariants()\` method in the model class to see all invariants.\n!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`,
                )
              }
            })
          })

          this.addDestructor(() => this.invariantCheckerDisposer?.())
        })
        .catch((e) => {
          // Throw by MobX when the `when()` above is cancelled.
          // MobX unfortunately does not provide a nice custom error or even export this hardcoded string,
          // but it's safe to check for it manually because we have tests covering it.
          if (e.message !== "WHEN_CANCELLED") throw e
        })
        .finally(() => {
          delete this[StateSyncInternals].cancelAfterNewWait
        })
    }

    // This hook gets invoked by `@ga.observableClass` after it finishes making the model observable
    afterObservable() {
      // Track all changes to model state, if this model isn't immutable
      if (!options?.immutable) {
        const changeTracker = new ChangeTracker(this.asModelState, schema)
        changeTracker.track(mutableDataKeysSet)

        const internals = this[StateSyncInternals]
        // This is the only place we write to changeTracker
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ;(internals as Writable<typeof internals>).changeTracker = changeTracker
      }

      this.addChangeListener(() => {
        // Clear cloned data cache on any change
        delete this[StateSyncInternals].clonedDataCache
      })
    }

    get context() {
      return this[StateSyncInternals].context
    }

    encodeModelScopedPatches(patches: ModelScopedPatch<TModelKey>[]): TEncodedModelScopedPatch[] {
      // By default, perform no encoding
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return patches as TEncodedModelScopedPatch[]
    }

    decodeModelScopedPatch(patch: TEncodedModelScopedPatch): ModelScopedPatch<TModelKey> {
      // By default, perform no decoding
      return patch
    }

    static encodeFullStatePatch(patch: AddModelPatch<TModelKey, TSchema>): TEncodedAddModelPatch {
      // There's no way to supply type safety here.
      // Models that supply custom coding should rely on tests to ensure correctness.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return patch as TEncodedAddModelPatch
    }

    static decodeFullStatePatch(patch: TEncodedAddModelPatch): AddModelPatch<TModelKey, TSchema> {
      // There's no way to validate that the patch is actually an AddModelPatch here.
      // Models that supply custom coding should rely on tests to ensure correctness.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return patch as AddModelPatch<TModelKey, TSchema>
    }

    /**
     * Adds a listener to be called whenever any change is detected.
     * NB: Immutable models will never invoke this listener!
     *
     * @returns A cleanup function to remove the listener
     */
    addChangeListener(listener: ChangeListener) {
      return this[StateSyncInternals].changeTracker?.addChangeListener(listener) ?? (() => {})
    }

    /**
     * Adds a destructor function to be called when the model is destroyed.
     *
     * @param fn The destructor function to add
     */
    addDestructor(fn: () => void) {
      this[StateSyncInternals].destructors.push(fn)
    }

    /**
     * Removes a destructor function from the list of destructors.
     *
     * @param fn The destructor function to remove
     */
    removeDestructor(fn: () => void) {
      this[StateSyncInternals].destructors = this[StateSyncInternals].destructors.filter(
        (destructor) => destructor !== fn,
      )
    }

    /**
     * Returns an AddPatch containing the full current state of this model.
     *
     * Notably, the returned patch is NOT encoded yet (because we can't access subclass overrides of
     * `static encodeFullStatePatch`). Callers should encode it as necessary!
     */
    genFullStatePatch(): AddModelPatch<TModelKey, TSchema> {
      return {
        op: "addmodel",
        model: modelKey,
        data: this.cloneData(),
      }
    }

    /**
     * Fully replaces this model's state with the given new state, EXCEPT `id` (which is immutable).
     *
     * @param compareWithCurrent If true, this does deep comparisons with the current state to avoid unnecessary updates
     * @param defensiveCopy If true, this will defensive copy new state before applying it
     */
    replaceModelState(newState: ModelState, compareWithCurrent = false, defensiveCopy = true) {
      for (const key in newState) {
        if (key === "id") {
          continue
        }
        // If `compareWithCurrent` is enabled, don't re-assign object fields that have no
        // deep changes so we avoid unnecessary reference changes and the associated overhead.
        if (
          compareWithCurrent &&
          typeof this.asModelState[key] === "object" &&
          typeof newState[key] === "object" &&
          isEqual(this.asModelState[key], newState[key])
        ) {
          continue
        }
        this.asModelState[key] = defensiveCopy ? cloneForPatch(newState[key]) : newState[key]
      }
    }

    /**
     * Applies the given patch to the current model state.
     *
     * @throws If anything goes wrong during patch application
     */
    applyPatch(encodedPatch: TEncodedModelScopedPatch) {
      const patch = this.decodeModelScopedPatch(encodedPatch)

      if (patch.id !== this.id)
        throw new Error(`Patch id ${patch.id} does not match this model's id ${this.id}`)

      // We're trusting `patch` to be well-formed here...
      // TODO [GS Rebuild] handle this more robustly
      switch (patch.op) {
        case "add": {
          this.addFieldAtPath_DANGEROUS(patch.path, patch.data)
          break
        }
        case "replace": {
          this.setFieldAtPath_DANGEROUS(
            patch.path,
            patch.data,
            // Replace patches must be applied to fields that already exist
            true, // validateFinalKey
          )
          break
        }
        case "delete": {
          this.deleteFieldAtPath_DANGEROUS(patch.path, patch.data)
          break
        }
        default:
          // All possible patch types should be handled above
          patch satisfies never
      }
    }

    /**
     * Attempts to retrieve the value at the given path in the model state
     * and return it, if it exists.
     *
     * This method NEVER throws.
     *
     * @returns `[value, undefined]` upon success, else `[undefined, error]`
     */
    getFieldAtPath = (path: PatchPath): [unknown, undefined] | [undefined, Error] =>
      this.operateAtPath(path, this.readFieldAtPathOperation)

    // Used to power `getFieldAtPath`
    private readFieldAtPathOperation = <T extends Record<string, unknown>>(
      parent: T,
      finalKey?: string & keyof T,
    ) => {
      // No `finalKey` means the path was "/" and `parent` is the value
      if (!finalKey) return parent
      return parent[finalKey]
    }

    /**
     * Attempts to DANGEROUSLY set the field at the given path to the given value.
     * It's impossible to enforce type safety here - be very careful to preserve
     * type safety when using this method!
     *
     * @throws If anything goes wrong during the setting
     */
    private setFieldAtPath_DANGEROUS(path: PatchPath, value: unknown, validateFinalKey = true) {
      const [_, err] = this.operateAtPath(
        path,
        (parent, finalKey) => {
          if (!finalKey) throw new Error("Cannot set the root object")

          // The operations below are DANGEROUS!
          // We're intentionally allowing the caller to add anything (`value` is unknown) -
          // it's up to them to ensure they're not breaking types.
          if (Array.isArray(parent)) {
            // --- Arrays
            const index = validateArrayIndex(finalKey, parent)
            parent[index] = value
          } else if (isObservableSet(parent)) {
            // --- Sets
            throw new Error("Cannot set fields on Sets directly - use add/delete instead")
          } else {
            // --- Objects
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
            parent[finalKey] = value as any
          }

          return
        },
        validateFinalKey,
      )
      if (err) {
        if (err instanceof ModelError.KeyNotFound) {
          // Suppress errors for backwards compatibility with newer server versions that may send unrecognized fields
          Logger.warn(err.message)
        } else {
          throw err
        }
      }
    }

    private addFieldAtPath_DANGEROUS(path: PatchPath, value: unknown) {
      const [_, err] = this.operateAtPath(
        path,
        (parent, finalKey) => {
          if (!finalKey) throw new Error("Cannot add on the root object")

          const finalValue = parent[finalKey]

          // The operations below are DANGEROUS!
          // We're intentionally allowing the caller to add anything (`value` is unknown) -
          // it's up to them to ensure they're not breaking types.
          if (Array.isArray(parent)) {
            // --- Array insertion
            const index = validateArrayIndex(finalKey, parent, true /* forInsertion */)
            parent.splice(index, 0, value)
          } else if (isObservableSet(finalValue)) {
            // --- Set insertion
            // TODO [GS Rebuild] PLAT-2302 Add Set validation
            finalValue.add(value)
          } else {
            // --- Add a new field to an object
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
            parent[finalKey] = value as any
          }
          return
        },
        // `finalKey` may not necessarily exist in `parent` for adds
        false, // validateFinalKey
      )
      if (err) {
        if (err instanceof ModelError.KeyNotFound) {
          // Suppress errors for backwards compatibility with newer server versions that may send unrecognized fields
          Logger.warn(err.message)
        } else {
          throw err
        }
      }
    }

    private deleteFieldAtPath_DANGEROUS(path: PatchPath, value?: unknown) {
      const [_, err] = this.operateAtPath(path, (parent, finalKey) => {
        if (!finalKey) throw new Error("Cannot delete the root object")

        const finalValue = parent[finalKey]

        // The operations below are DANGEROUS!
        // We're intentionally allowing the caller to delete any value at any path -
        // it's up to them to ensure they're not breaking types.
        if (Array.isArray(parent)) {
          // --- Delete an element from an array
          const index = validateArrayIndex(finalKey, parent)
          parent.splice(index, 1)
        } else if (isObservableSet(finalValue)) {
          if (value === undefined) throw new Error("Cannot delete a Set element without a value")

          // --- Delete an item from a Set
          finalValue.delete(value)
        } else {
          // --- Delete a field from an object
          if (isTopLevelPath(path)) {
            // MobX disallows deleting top-level fields (that were already tracked via `@ga.observableClass`,
            // which uses `makeObservable()` under the hood).
            // We can delete nested fields, though, b/c they're tracked with `observable()`. See PLAT-2292 for more
            throw new Error(
              "Cannot delete top-level fields directly - replace with undefined instead",
            )
          }
          delete parent[finalKey]
        }
      })
      if (err) {
        if (err instanceof ModelError.KeyNotFound) {
          // Suppress errors for backwards compatibility with newer server versions that may send unrecognized fields
          Logger.warn(err.message)
        } else {
          throw err
        }
      }
    }

    /**
     * Performs an operation on the field at the given path in the model state
     * and returns the result, if successful.
     *
     * @param path The path to the field to operate on.
     * @param fn The operation to perform on the field at the given path.
     * @returns `[value, err]` - upon success, `value` will be the return value of `fn`
     */
    // If `validateFinalKey`, we can type `finalKey` as `keyof T`
    private operateAtPath<TRet>(
      path: PatchPath,
      fn: <T extends Record<string, unknown>>(parent: T, finalKey?: string & keyof T) => TRet,
      validateFinalKey?: true,
    ): [TRet, undefined] | [undefined, Error]
    // If no `validateFinalKey`, we can NOT type `finalKey` as `keyof T`
    private operateAtPath<TRet>(
      path: PatchPath,
      fn: (parent: Record<string, unknown>, finalKey?: string) => TRet,
      validateFinalKey: boolean,
    ): [TRet, undefined] | [undefined, Error]
    private operateAtPath<TRet>(
      path: PatchPath,
      fn: <T extends Record<string, unknown>>(parent: T, finalKey?: string) => TRet,
      validateFinalKey = true,
    ): [TRet, undefined] | [undefined, Error] {
      try {
        const pathParts = splitPatchPath(path)

        let ref: Record<string, unknown> | unknown = this.asModelState

        for (let i = 0; i < pathParts.length; i++) {
          if (!isObject(ref)) {
            throw new Error(
              `Value at path ${joinPatchPaths(pathParts.slice(0, i))} is not an object`,
            )
          }

          // This is safe given the for loop conditions
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const key = pathParts[i]!
          // This isn't a technically correct cast (`ref` could be an array, Set, etc)
          // but is practically safe given the context. Basically anything in JS is
          // indexable via strings, and our loop here has the necessary precautions
          // to handle any crazy results we may get.
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const currentRef = ref as Record<string, unknown>

          if (!validateFinalKey && i === pathParts.length - 1)
            return [fn(currentRef, key), undefined]

          // Notably: `key in ref` works for arrays too, even with string keys.
          //     `"1" in [10, 20] === true`
          //     `"2" in [10, 20] === false`
          if (!(key in ref)) {
            const errorMessage = `Key "${key}" not found at path ${joinPatchPaths(
              pathParts.slice(0, i),
            )}`
            throw Array.isArray(ref) || isObservableSet(ref)
              ? new Error(errorMessage)
              : new ModelError.KeyNotFound(errorMessage)
          }

          // Notably: arr["0"] === arr[0]
          const nextRef = currentRef[key]

          if (i === pathParts.length - 1) {
            // If this was the last key in the path, we're done!
            return [fn(currentRef, key), undefined]
          } else {
            ref = nextRef
          }
        }

        // Reaching here means the path was "/"
        return [fn(this.asModelState), undefined]
      } catch (e) {
        return [undefined, guaranteedError(e)]
      }
    }

    /**
     * enablePermissions is a way for a subclass to declare that it would like to participate in
     * permissions. If left false (default), permissions checks will be ignored. If a method action
     * has a non-public permission, it will throw an error. If enabled, calls to method actions will
     * compute permissions via role assignment and implicit permissions via overridePermissions.
     */
    enablePermissions = false

    /**
     * overridePermissions is a no-op in the base class, but may be overridden by subclasses to
     * augment permissions. Use it to add or remove implicit permissions based on model instance
     * data.
     *
     * @param spaceUserId The id of the space user for which these permissions are being calculated.
     * @param explicitPermissions The set of explicit permissions pulled from role assignment
     * tables. The overridePermissions method is meant to augment this set.
     */
    overridePermissions(
      _spaceUserId: Uuid,
      explicitPermissions: ReadonlySet<EnumValue<TPermissionsEnumType>>,
    ): ReadonlySet<EnumValue<TPermissionsEnumType>> {
      return explicitPermissions
    }

    /**
     * For models that participate in permissions, getPermissions will return the computed
     * permissions enum set if it exists in the computed permissions model repo. Note that this
     * method is injected into Model only for models that participate in permissions.
     */
    getPermissions: () => ReadonlySet<EnumValue<TPermissionsEnumType>> = () => {
      throw new Error(
        `${modelKey} does not participate in permissions. Did you set enablePermissions?`,
      )
    }

    /**
     * For models that participate in permissions, hasPermission will return true if the model has
     * the given permission. This only works if an override to `getPermissions` is injected into
     * this instance.
     */
    hasPermission(permission: EnumValue<TPermissionsEnumType>): boolean {
      return this.getPermissions().has(permission)
    }

    /**
     * isVisible is a method overridable in subclasses which determines whether a model is visible.
     * This method is extremely perf sensitive - see `ModelPublic` for more.
     *
     * Warning: make sure to use isVisibleSafe_INTERNAL instead when invoking from inside the game framework impl
     */
    isVisible_PERF_SENSITIVE(
      _userInfo: VisibilityUserInfo,
      _connectionIdBox: VisibilityConnectionIdBox,
    ): boolean {
      throw new ModelError.IsVisible_PERF_SENSITIVE_NotImplemented()
    }

    /**
     * A wrapper around `isVisible_PERF_SENSITIVE` that swallows exceptions and returns false instead, to prevent application-level
     * errors causing server crashes.
     *
     * TODO [GS Rebuild] move this to some kind of internal state sync symbol so it's not on public API
     */
    isVisibleSafe_INTERNAL(
      userInfo: Partial<VisibilityUserInfo>,
      connectionIdBox: VisibilityConnectionIdBox,
    ): boolean {
      // You can't see anything until you have full VisibilityUserInfo. This should be an exhaustive check!
      if (!userInfo.authUserId || !userInfo.id) return false

      try {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return this.isVisible_PERF_SENSITIVE(userInfo as VisibilityUserInfo, connectionIdBox)
      } catch (e) {
        console.error(`Error in ${modelKey}:${this.id} isVisible`, e)
        return false
      }
    }

    cloneData(): Readonly<ModelState> {
      const internals = this[StateSyncInternals]
      if (!internals.clonedDataCache) {
        internals.clonedDataCache = dataKeys.reduce((acc, key) => {
          acc[key] = cloneForPatch(this[key])
          return acc
          // it's the job of this reduce call to ensure the shape is correct
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        }, {} as ModelState)
      }
      return internals.clonedDataCache
    }

    hasSameData(data: Readonly<ModelState>): boolean {
      for (const key of dataKeys) {
        if (!isEqual(this[key], data[key])) return false
      }
      return true
    }

    /**
     * An optional callback invoked exactly once after the model is constructed AND the current state is
     * safe to access. You should almost always use this instead of the constructor for any setup logic.
     *
     * State is not always safe to access immediately after a model's construct, e.g. during a
     * `collection.applyPatches()` call where many models with dependencies on each other may be
     * created at once in a single transaction.
     *
     * Related Slack thread with more context / specific examples where consistency can fail:
     * https://gather-town.slack.com/archives/C06SZ9JST61/p1733260353248669?thread_ts=1733247041.652679&cid=C06SZ9JST61
     */
    afterNew() {}

    invariants(): boolean[] {
      return []
    }

    destroy() {
      this[StateSyncInternals].changeTracker?.destroy()
      this[StateSyncInternals].destructors.forEach((fn) => fn())
      this[StateSyncInternals].cancelAfterNewWait?.()
      this.destroyed = true
    }
  }

  Model satisfies ModelPublicStatic

  // Immutable models should be publicly-typed as deeply readonly
  type ModelStatePublic = TImmutable extends true ? ReadonlyDeep<ModelState> : ModelState

  // The `ModelState` type assertion is necessary in order to make the `Object.assign` in the constructor
  // above also affect the types on the model. As long as the `Object.assign` always happens, this should
  // be a safe type-cast.
  // The other casts below are actually enforced by TS, e.g. if we forget to define `static schema` TS
  // will actually error below ("neither type sufficiently overlaps with the other").
  // All functionality casted below is also covered in tests!
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  type ModelType<TSuperClass = {}> = (new (
    ...args: ConstructorParameters<typeof Model>
  ) => ModelStatePublic & ModelPublic & TSuperClass) &
    ModelPublicStatic

  // Cast to `ModelType` first to get the TS safety mentioned above, then finally cast
  // to include `TSuperClass`.
  // Direct casting doesn't work here: "neither type sufficiently overlaps..."
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Model as unknown as ModelType<InstanceType<TSuperClass>> &
    // Add the custom (non-Model) static interface of the superclass, if any
    Omit<StaticInterfaceOfClass<TSuperClass>, keyof ModelPublicStatic>
}

// Parses and validates the given string as an index of the given array
export function validateArrayIndex(key: string, parent: unknown[], forInsertion = false): number {
  const index = parseFloat(key)
  if (isNaN(index)) throw new Error(`Invalid non-numeric array index: ${key}`)
  const maxIndex = forInsertion ? parent.length : parent.length - 1
  if (index < 0 || index > maxIndex || !Number.isInteger(index))
    throw new Error(
      `Invalid ${forInsertion ? "insert" : "mutation"} index ${index} for array with length ${
        parent.length
      }`,
    )
  return index
}
