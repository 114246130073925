import Link from "@mui/material/Link"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import ChipList from "components/dashboard/ChipList"
import React, { FC } from "react"
import { Link as RouterLink } from "react-router-dom"

import { SpaceUserResource } from "gather-common/dist/src/public/v2/resources/spaceUsers"

interface Props {
  user: SpaceUserResource
}

const UserRow: FC<Props> = ({ user }) => (
  <TableRow hover key={user.id}>
    <TableCell>
      <Link
        component={RouterLink}
        to={`/dashboard/users?user=${encodeURIComponent(user.userAccount.email || "")}`}
        underline="always"
        color="textPrimary"
        variant="body2"
      >
        <Typography color="textSecondary" variant="body2">
          {user.id}
        </Typography>
      </Link>
    </TableCell>

    <TableCell>
      <Typography color="textSecondary" variant="body2">
        {user.name}
      </Typography>
    </TableCell>

    <TableCell>
      <Link
        component={RouterLink}
        to={`/dashboard/users?user=${encodeURIComponent(user.userAccount.email || "")}`}
        underline="always"
        color="textPrimary"
        variant="body2"
      >
        <Typography color="textSecondary" variant="body2">
          {user.userAccount.email}
        </Typography>
      </Link>
    </TableCell>

    <TableCell>
      <ChipList list={user.coreRole ? [user.coreRole] : []} />
    </TableCell>
  </TableRow>
)

export default UserRow
