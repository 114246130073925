import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { AdminUser } from "gather-http-common/dist/src/public/superAdminUsers"
import { AdminRolePrisma } from "gather-prisma-types/dist/src/public/client"

export const listSuperAdminUsers = async (): Promise<AdminUser[]> => {
  try {
    const response = tsrAPI.admin.superAdminUsers.list({})
    return response
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : null
    throw new Error(`Failed to retrieve users: ${errorMessage}`)
  }
}

export const createSuperAdminUser = async (
  email: string,
  roles: AdminRolePrisma[],
): Promise<AdminUser> => {
  try {
    const response = await tsrAPI.admin.superAdminUsers.create({
      body: {
        email,
        roles,
      },
    })

    const newUser: AdminUser = {
      email,
      roles,
      id: response,
    }
    return newUser
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : null
    throw new Error(`Failed to create new user: ${errorMessage}`)
  }
}

export const deleteSuperAdminUser = async (userId: string | undefined): Promise<void> => {
  try {
    if (userId) {
      await tsrAPI.admin.superAdminUsers.delete({
        params: { adminUserId: userId },
      })
    } else {
      throw new Error("Missing userId")
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : null
    throw new Error(`Failed to remove admin user: ${errorMessage}`)
  }
}

export const updateSuperAdminUser: (
  userId: string | undefined,
  roles: AdminRolePrisma[],
) => Promise<void> = async (
  userId: string | undefined,
  roles: AdminRolePrisma[],
): Promise<void> => {
  try {
    if (userId) {
      await tsrAPI.admin.superAdminUsers.update({
        params: { adminUserId: userId },
        body: { roles },
      })
    } else {
      throw new Error("Missing userId")
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : null
    throw new Error(`Failed to update admin user: ${errorMessage}`)
  }
}
