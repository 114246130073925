import { isNil, mapObjIndexed } from "ramda"
import { PartialDeep } from "type-fest"
import {
  EnumLike,
  KeySchema,
  z,
  ZodArray,
  ZodBoolean,
  ZodDefault,
  ZodEffects,
  ZodIntersection,
  ZodLiteral,
  ZodNativeEnum,
  ZodNullable,
  ZodNumber,
  ZodObject,
  ZodOptional,
  ZodRawShape,
  ZodRecord,
  ZodSet,
  ZodString,
  ZodType,
  ZodTypeAny,
  ZodUnion,
  ZodUnionOptions,
} from "zod"

import { DateTimeExt } from "gather-common/dist/src/public/valueObjects/DateTimeExt"
import { ZodAnyObject, zodDateTimeExt, zodUuid } from "gather-common/dist/src/public/zod-utils"
import { asEmail, asUrl, Email, Url } from "gather-common-including-video/dist/src/public/dataTypes"
import { isNotNil, just } from "gather-common-including-video/dist/src/public/fpHelpers"
import { objectEntries } from "gather-common-including-video/dist/src/public/tsUtils"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { BaseValueObjectClass } from "gather-state-sync/dist/src/public/BaseValueObject"

export type ZodTypeFromSchemaType<T extends SchemaType> = T["zodType"]

export type UnwrappedZodType<T extends ZodType> = T extends ZodObject<infer TShape>
  ? ZodObject<{ [K in keyof TShape]: UnwrappedZodType<TShape[K]> }>
  : T extends ZodArray<infer U>
  ? ZodArray<UnwrappedZodType<U>>
  : T extends ZodOptional<infer U>
  ? ZodOptional<UnwrappedZodType<U>>
  : T extends ZodNullable<infer U>
  ? ZodNullable<UnwrappedZodType<U>>
  : T extends ZodDefault<infer U>
  ? UnwrappedZodType<U>
  : T extends ZodLiteral<infer _>
  ? ZodString
  : T

export type SchemaPrimitiveType<T extends SchemaType> = T extends SchemaObject<infer S>
  ? SchemaObject<{ [K in keyof S]: SchemaPrimitiveType<S[K]> }>
  : T extends SchemaModifierType<infer U, infer _>
  ? SchemaPrimitiveType<U>
  : T

export type SchemaInfer<T extends SchemaType> = z.infer<UnwrappedZodType<ZodTypeFromSchemaType<T>>>

/**
 * Util type to check if a type chain contains a certain type.
 *
 * Example:
 * const num = s.number().optional()
 * type HasNumber = SchemaContainsType<typeof num, SchemaNumber> // true
 * type HasOptional = SchemaContainsType<typeof num, SchemaOptional> // true
 * type HasDefault = SchemaContainsType<typeof num, SchemaDefault> // false
 */
export type SchemaContainsType<SchemaType, TargetSchemaType> = SchemaType extends TargetSchemaType
  ? true
  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SchemaType extends SchemaModifierType<infer InnerSchemaType, any>
  ? SchemaContainsType<InnerSchemaType, TargetSchemaType>
  : false

export type SchemaRelationKind = "one-to-one" | "many-to-one" | "many-to-many"

// TODO [GS Rebuild] PLAT-2502 handle `SetNull` Referential action
export type CascadeOption = "Cascade" | "SetNull" | "Restrict"

export type SchemaRelation = SchemaRelationOptions & {
  kind: SchemaRelationKind
  schema: string
  field: string
  joinTableName?: string
  onDelete: CascadeOption
  onUpdate: CascadeOption
}

export type SchemaRelationOptions = {
  relationName?: string
  /** the name of the model property on this side of the relation */
  modelPropertyName?: string
  /** the name of the model property on the other side of the relation */
  otherSideModelPropertyName?: string
  onDelete?: CascadeOption
  onUpdate?: CascadeOption
}

type Modifiers = "optional" | "nullable"

/**
 * SchemaType is our custom implementation of Zod types. Since it's challenging
 * to override and extend Zod's long list of primitives and modifiers, we
 * decided to build our own type system. SchemaType is the base class of
 * primitives, containers, and modifiers. Each layer is immutable and its
 * ancestry chain may be traversed to find specific types or properties (useful
 * when generating Prisma schemas or model instances).
 *
 * Note that each SchemaType instance also has an associated Zod instance. While
 * we're essentially building our own type system with its own API, we still use
 * Zod for runtime validation.
 */
export abstract class SchemaType<TZod extends ZodType = ZodType> {
  /**
   * A set of string modifiers that can be applied to the `getPrimitiveSchemaType`
   * so they can be checked at runtime regardless of where they defined.
   */
  private modifiers: Set<Modifiers> = new Set()

  /**
   * returns an unwrapped zod type that turns some modifiers into their primitive types,
   * that can then be used in the Model constructor
   * (e.g. 'id' being 'uuid' with default will get mapped back to a 'string' primitive type)
   */
  get modelZod() {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return this.zodType as UnwrappedZodType<TZod>
  }

  constructor(readonly zodType: TZod) {}

  array() {
    return new SchemaArray(this)
  }

  set() {
    return new SchemaSet(this)
  }

  unique() {
    return new SchemaUnique(this)
  }

  /** specifies the write flush interval for this field */
  debounced(intervalMs: number) {
    return new SchemaDebounced(this, intervalMs)
  }

  noPersist() {
    return new SchemaNoPersist(this)
  }

  optional() {
    this.getPrimitiveSchemaType().modifiers.add("optional")
    return new SchemaOptional(this)
  }

  nullable() {
    this.getPrimitiveSchemaType().modifiers.add("nullable")
    return new SchemaNullable(this)
  }

  /**
   * Marks a model as immutable. Immutable models will be typed with ReadonlyDeep<>
   * and will NOT have their data properties be observable or state-synced!
   * Making models immutable is a significant performance improvement, so use it whenever
   * it makes sense for your model.
   *
   * Usage:
   *     s.model(...).immutable()
   */
  immutable() {
    return new SchemaImmutable(this)
  }

  protected is(modifier: Modifiers | Modifiers[]): boolean {
    const modifiers = Array.isArray(modifier) ? modifier : [modifier]
    return modifiers.some((m) => this.getPrimitiveSchemaType().modifiers.has(m))
  }

  /**
   * TODO [GS Rebuild] PLAT-2496 `default` is currently only partially implemented, and may lead to unexpected behavior.
   * Message the GS team if you need this functionality, or run into issues with `default`
   * */
  default_UNIMPLEMENTED(value: z.infer<TZod>) {
    return new SchemaDefault(this, value)
  }

  /**
   * Adds a 'one-to-one' relation on the field where this side is the source of truth for the relation
   * @param schema the name of the schema on the opposite side of the relation
   * @param field the field on the schema the relation is over (the 'FK' field)
   * @param options additional options for the relation
   */
  oneToOne(schema: string, field: string, options?: SchemaRelationOptions) {
    return new SchemaOneToOne(this, schema, field, options).unique()
  }

  /**
   * Adds a 'many-to-one' relation on the field where this side is the 'many' side of the relation
   * and the other side is the 'one' side of the relation.
   * @param schema the name of the schema on the 'one' side of the relation
   * @param field the field on the schema the relation is over (the 'FK' field)
   * @param options additional options for the relation
   */
  manyToOne(schema: string, field: string, options?: SchemaRelationOptions) {
    return new SchemaManyToOne(this, schema, field, options)
  }

  /**
   * Adds a 'many-to-many' relation on the field to the other schema
   * @param schema
   * @param field
   * @param options
   */
  manyToMany(schema: string): SchemaManyToMany {
    // marks this field as not persisted since we don't directly persist the many-to-many id fields
    const nonPersisted = getSchemaNoPersist(this) ? this : this.noPersist()
    return new SchemaManyToMany(nonPersisted, schema)
  }

  /**
   * Instances of SchemaType may override this method if serialization of their
   * values is more complex than what Javascript's basic toString() method
   * handles. For instance, SchemaJSON may want to run JSON.stringify. This is
   * necessary both at runtime when creating/updating records and at schema
   * generation time for field defaults.
   */
  serializeValueForPrisma(value: z.infer<TZod>): string {
    return value.toString()
  }

  /**
   * Create an iterator that yields all the SchemaType nodes in this ancestry
   * chain. Since this implementation is part of the primitive, it's expected to
   * be the root, so this function isn't very interesting. Check the
   * implementation on SchemaModifierType for the interesting part.
   */
  *ancestry(): IterableIterator<SchemaType<ZodType>> {
    yield this
  }

  /**
   * SchemaTypes always start with primitives and then are optionally wrapped in
   * SchemaModifierType chains. getPrimitiveSchemaType always returns the root of
   * those chains, thus the primitive value. Since this implementation is part
   * of the primitive, it's expected to be the root, so this function isn't very
   * interesting. Check the implementation on SchemaModifierType for the
   * interesting part.
   */
  getPrimitiveSchemaType(): SchemaType<ZodType> {
    return this
  }
}

/**
 * SchemaModifierType is intended to add some additional functionality onto a
 * SchemaType without directly mutating it. This is achieved by storing the
 * wrapped SchemaType in the property `innerType`.
 */
export abstract class SchemaModifierType<
  T extends SchemaType = SchemaType,
  TZod extends ZodType = ZodType,
> extends SchemaType<TZod> {
  constructor(readonly innerType: T, zodType: TZod) {
    super(zodType)
  }

  /**
   * Create an iterator that yields all the SchemaType nodes in this ancestry
   * chain. This is useful if you need to find a specific node type via
   * instanceof checks to check for existence or retrieve properties in a
   * type-safe manner.
   */
  *ancestry(): IterableIterator<SchemaType<ZodType>> {
    yield this
    yield* this.innerType.ancestry()
  }

  /**
   * SchemaTypes always start with primitives and then are optionally wrapped in
   * SchemaModifierType chains. getPrimitiveSchemaType always returns the root of
   * those chains, thus the primitive value.
   */
  getPrimitiveSchemaType(): SchemaType<ZodType> {
    return this.innerType.getPrimitiveSchemaType()
  }

  /**
   * Call up the ancestry chain to find the primitive type, then get the
   * serialized version of the given value.
   */
  serializeValueForPrisma(value: z.infer<TZod>): string {
    return this.innerType.serializeValueForPrisma(value)
  }
}

export class SchemaDebounced<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodTypeFromSchemaType<T>
> {
  constructor(readonly innerType: T, readonly debounceMs: number) {
    super(innerType, innerType.zodType)
  }
}

export class SchemaUnique<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodTypeFromSchemaType<T>
> {
  constructor(readonly innerType: T) {
    super(innerType, innerType.zodType)
  }
}

export class SchemaNoPersist<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodTypeFromSchemaType<T>
> {
  constructor(readonly innerType: T) {
    super(innerType, innerType.zodType)
  }
}

export class SchemaDefault<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodDefault<ZodTypeFromSchemaType<T>>
> {
  constructor(innerType: T, readonly defaultValue: z.infer<ZodTypeFromSchemaType<T>>) {
    super(innerType, innerType.zodType.default(defaultValue))
  }
}

export class SchemaOptional<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodOptional<ZodTypeFromSchemaType<T>>
> {
  constructor(readonly innerType: T) {
    if (getSchemaNullable(innerType)) throw new Error("Schema can't be both optional and nullable")

    super(innerType, innerType.zodType.optional())
  }
}

export class SchemaNullable<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodNullable<ZodTypeFromSchemaType<T>>
> {
  constructor(readonly innerType: T) {
    if (getSchemaOptional(innerType)) throw new Error("Schema can't be both optional and nullable")

    super(innerType, innerType.zodType.nullable())
  }
}

// We could use Zod's `.readonly()` here, but it significantly complicates the flow of types from
// BaseModel -> Model and isn't actually necessary, since we implement immutability typing
// via Model's `immutable` option.
export class SchemaImmutable<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodTypeFromSchemaType<T>
> {
  constructor(readonly innerType: T) {
    super(innerType, innerType.zodType)
  }
}

export class SchemaOneToOne<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodTypeFromSchemaType<T>
> {
  private readonly _relation: Omit<SchemaRelation, "onDelete" | "onUpdate"> & SchemaRelationOptions

  relation(): SchemaRelation {
    return getDefaultReferentialActions(this._relation, this.is(["optional", "nullable"]))
  }

  /** Returns the default model property name for the relation */
  getDefaultModelPropertyName(fieldName: string) {
    return fieldName.endsWith("Id")
      ? fieldName.slice(0, -2) // remove 'Id' suffix
      : fieldName
  }

  getDefaultOthersideModelPropertyName(schemaName: string) {
    // camel case the schema name, both sides are singular part of the relation
    return schemaName[0]?.toLowerCase() + schemaName.substring(1)
  }

  /**
   * Adds a 'one-to-one' relation on the field where this side is the source of truth for the relation
   * @param innerType the SchemaType parent, usually an instance of SchemaUuid
   * @param schema the name of the schema on the opposing side of the relation
   * @param field the field on the schema the relation is over (the 'FK' field)
   * @param options additional options for the relation
   */
  constructor(innerType: T, schema: string, field: string, options?: SchemaRelationOptions) {
    super(innerType, innerType.zodType)

    this._relation = {
      kind: "one-to-one",
      schema,
      field,
      relationName: options?.relationName,
      modelPropertyName: options?.modelPropertyName,
      otherSideModelPropertyName: options?.otherSideModelPropertyName,
      onDelete: options?.onDelete,
      onUpdate: options?.onUpdate,
    }
  }
}

export class SchemaManyToMany<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodTypeFromSchemaType<T>
> {
  private readonly _relation: Omit<SchemaRelation, "onDelete" | "onUpdate"> & SchemaRelationOptions

  relation(): SchemaRelation {
    return {
      ...this._relation,
      // In order to use referential actions, define an explicit `many-to-many` relation on the join table
      // https://www.prisma.io/docs/orm/prisma-schema/data-model/relations/referential-actions#caveats
      onDelete: "Cascade",
      onUpdate: "Cascade",
    }
  }

  /** Returns the default identity field name on the join table for either side of the relation */
  getDefaultIdentityFieldName(schemaName: string) {
    return `${schemaName[0]?.toLowerCase() + schemaName.substring(1)}Id`
  }

  /** Returns the default name of the join table */
  getDefaultJoinTableName(schemaName: string) {
    return `${schemaName}${this._relation.schema}`
  }

  /** Returns the default model property name for the relation */
  getDefaultModelPropertyName(fieldName: string) {
    return (
      (fieldName.endsWith("Id")
        ? fieldName.slice(0, -2) // remove 'Id' suffix
        : fieldName) + "s"
    )
  }

  /** Returns the default model property name on the other side of the relation */
  getDefaultOthersideModelPropertyName(schemaName: string) {
    const name = this._relation.relationName ?? schemaName
    const pluralize = !this._relation.relationName && !schemaName.endsWith("s")
    // camel case and add an 's' since this side is the plural side of the relation
    return name[0]?.toLowerCase() + name.substring(1) + (pluralize ? "s" : "")
  }

  /**
   * Adds a 'many-to-many' relation on the field where this side is the 'many' side of the relation
   * and the other side is the 'one' side of the relation.
   * @param innerType the SchemaType parent, usually an instance of SchemaUuid
   * @param schema the name of the schema on the other side of the relation
   */
  constructor(innerType: T, schema: string) {
    super(innerType, innerType.zodType)

    this._relation = {
      kind: "many-to-many",
      schema,
      field: "id",
    }
  }
}

export class SchemaManyToOne<T extends SchemaType = SchemaType> extends SchemaModifierType<
  T,
  ZodTypeFromSchemaType<T>
> {
  private readonly _relation: Omit<SchemaRelation, "onDelete" | "onUpdate"> & SchemaRelationOptions

  relation(): SchemaRelation {
    return getDefaultReferentialActions(this._relation, this.is(["optional", "nullable"]))
  }

  /** Returns the default model property name for the relation */
  getDefaultModelPropertyName(fieldName: string) {
    return fieldName.endsWith("Id")
      ? fieldName.slice(0, -2) // remove 'Id' suffix
      : fieldName
  }

  /** Returns the default model property name on the other side of the relation */
  getDefaultOthersideModelPropertyName(schemaName: string) {
    const name = this._relation.relationName ?? schemaName
    const pluralize = !this._relation.relationName && !schemaName.endsWith("s")
    // camel case and add an 's' since this side is the plural side of the relation
    return name[0]?.toLowerCase() + name.substring(1) + (pluralize ? "s" : "")
  }

  /**
   * Adds a 'many-to-one' relation on the field where this side is the 'many' side of the relation
   * and the other side is the 'one' side of the relation.
   * @param innerType the SchemaType parent, usually an instance of SchemaUuid
   * @param schema the name of the schema on the 'one' side of the relation
   * @param field the field on the schema the relation is over (the 'FK' field)
   * @param options additional options for the relation
   */
  constructor(innerType: T, schema: string, field: string, options?: SchemaRelationOptions) {
    super(innerType, innerType.zodType)

    this._relation = {
      kind: "many-to-one",
      schema,
      field,
      relationName: options?.relationName,
      modelPropertyName: options?.modelPropertyName,
      otherSideModelPropertyName: options?.otherSideModelPropertyName,
      onDelete: options?.onDelete,
      onUpdate: options?.onUpdate,
    }
  }
}

export class SchemaBoolean extends SchemaType<ZodBoolean> {}

export class SchemaDate extends SchemaType<typeof zodDateTimeExt> {
  isDefault?: boolean
  isUpdatedAt?: boolean

  /**
   * Set the default value for this DateTime to now()
   * This doesn't actually do anything yet, because you'll still need to manually specify the date when
   * creating your model.
   */
  defaultNow_UNIMPLEMENTED() {
    this.isDefault = true
    // WARNING: THIS IS WRONG. This uses the timestamp of when the schema is created, not when the model is created.
    // TODO [GS Rebuild] Remove or fix this interface
    return this.default_UNIMPLEMENTED(DateTimeExt.now())
  }

  /** Mark this DateTime as the `updatedAt` */
  updatedAt() {
    this.isUpdatedAt = true
    return this
  }

  serializeValueForPrisma(value: DateTimeExt) {
    if (this.isDefault) return "now()"
    return just(value.toISO())
  }
}

// We could in theory merge this tagged type with SchemaString below. There are some TS
// challenges to it, though, which we chose to punt on. See notes below.
export class SchemaEmail extends SchemaType<ZodEffects<ZodString, Email, string>> {
  constructor() {
    super(z.string().email().transform(asEmail))
  }
}

// We could in theory merge this tagged type with SchemaString below. There are some TS
// challenges to it, though, which we chose to punt on. See notes below.
export class SchemaUrl extends SchemaType<ZodEffects<ZodString, Url, string>> {
  constructor() {
    super(z.string().url().transform(asUrl))
  }
}

// We could in theory merge the above tagged types into this SchemaString class. A first pass
// implementation is here:
// https://github.com/gathertown/gather-town-v2/pull/2561/files#diff-35411d468f1006c388bba575b23b597f42e27af9665a66f02503063e53c3047cR541-R560
// The primary issue was `ZodEffects<>` being incompatible with `ZodString`, so when using the
// primitive `s.string()` it would do the wrong thing. See discussion here for more detail:
// https://github.com/gathertown/gather-town-v2/pull/2561/files#r1880529496. We could change this
// later to collapse, but we decided it wasn't worth spending cycles on for now.
export class SchemaString extends SchemaType<ZodString> {
  constructor(zodType?: ZodString) {
    super(zodType ?? z.string())
  }

  get isURL() {
    return this.zodType.isURL
  }

  url() {
    return new SchemaUrl()
  }

  email() {
    return new SchemaEmail()
  }
}

export class SchemaUuid extends SchemaType<ZodEffects<ZodString, Uuid, string>> {
  constructor() {
    super(zodUuid)
  }
}

export class SchemaJSON<T extends ZodRawShape> extends SchemaType<ZodObject<T>> {
  constructor(readonly jsonSchema: ZodObject<T>) {
    super(jsonSchema)
  }

  serializeValueForPrisma(value: T) {
    return JSON.stringify(value)
  }
}

export class SchemaNumber extends SchemaType<ZodNumber> {
  constructor(zodType?: ZodNumber) {
    super(zodType ?? z.number())
  }

  get isInt() {
    return this.zodType.isInt
  }

  int() {
    return new SchemaNumber(this.zodType.int())
  }
}

export class SchemaArray<T extends SchemaType = SchemaType> extends SchemaType<
  ZodArray<ZodTypeFromSchemaType<T>>
> {
  constructor(readonly elementType: T) {
    super(z.array(elementType.zodType))
  }
}

export class SchemaSet<T extends SchemaType = SchemaType> extends SchemaType<
  ZodSet<ZodTypeFromSchemaType<T>>
> {
  constructor(readonly elementType: T) {
    super(z.set(elementType.zodType))
  }
}

export class SchemaUnion<
  T extends Readonly<[SchemaObject, ...SchemaObject[]]> = Readonly<[SchemaObject, SchemaObject]>,
> extends SchemaType<ZodUnion<ZodUnionOptions>> {
  constructor(readonly types: T) {
    if (types.length < 2) throw new Error(`Union must have at least 2 types`)
    // we validate above that there are at least 2 types in the array
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const zodTypes = types.map((t) => t.zodType) as [ZodAnyObject, ZodAnyObject, ...ZodAnyObject[]]
    super(z.union(zodTypes))
  }
}

export class SchemaEnum<T extends EnumLike = {}> extends SchemaType<ZodNativeEnum<T>> {
  constructor(readonly name: string, readonly values: T) {
    super(z.nativeEnum(values))
  }
}

export class SchemaRecord<
  TKey extends SchemaType<KeySchema> = SchemaString,
  TValue extends SchemaType<ZodTypeAny> = SchemaType,
> extends SchemaType<ZodRecord<ZodTypeFromSchemaType<TKey>, ZodTypeFromSchemaType<TValue>>> {
  constructor(keyType: TKey, valueType: TValue) {
    super(z.record(keyType.zodType, valueType.zodType))
  }
}

type RawShapeKeys<T extends object> = `${Exclude<keyof T, symbol>}`
export type SchemaRawShape = Record<string, SchemaType>
type SchemaRawToZodRaw<T extends SchemaRawShape> = {
  [K in RawShapeKeys<T>]: ZodTypeFromSchemaType<T[K]>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnySchemaObject = SchemaObject<any, any>

export class SchemaObject<
  TShape extends SchemaRawShape = {},
  TZod extends ZodType = ZodObject<SchemaRawToZodRaw<TShape>>,
> extends SchemaType<TZod> {
  getFields(): [string, SchemaType][] {
    return Object.entries(this.shape)
  }

  getFieldKeys(): string[] {
    return Object.keys(this.shape)
  }

  getFieldValues(): SchemaType[] {
    return Object.values(this.shape)
  }

  getFieldOrThrow(fieldName: string): SchemaType {
    const field = this.shape[fieldName]
    if (!field) throw new Error(`Field ${fieldName} not found in schema`)
    return field
  }

  /**
   * @param zodType Defaults to the ZodObject type derived from TShape, but can be overridden
   *                e.g. to use `z.instanceof()`
   */
  constructor(readonly shape: TShape, zodType?: TZod) {
    const zodShape =
      zodType ??
      // If we're here, `zodType` wasn't specified, so `TZod` should be its default value
      // which matches the value below's type.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (z.object(
        // haven't found a good way to avoid casting here (that keys match original shape), will need
        // a cast in different implementations and this is the simplest one line conversion
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        mapObjIndexed((value) => value.zodType, shape) as SchemaRawToZodRaw<TShape>,
      ) as unknown as TZod)
    super(zodShape)
  }

  extend<TExtendedShape extends SchemaRawShape>(shape: TExtendedShape) {
    return new SchemaObject({ ...this.shape, ...shape })
  }
}

export type SchemaIndexField<TField extends string = string> = {
  field: TField
  sort?: "Asc" | "Desc"
  type?: "Gin"
  length?: number
}

export type SchemaIndexFieldOf<TShape extends SchemaRawShape> =
  | (string & keyof TShape)
  | SchemaIndexField<string & keyof TShape>

export type SchemaIndex = {
  fields: SchemaIndexField[]
  unique: boolean
  name?: string
}

export type SchemaIndexOptions = {
  name?: string
}

/** Object with additional data, PK and indexes */
export class SchemaModel<TShape extends SchemaRawShape = {}> extends SchemaObject<TShape> {
  id?: string[]
  readonly indexes: SchemaIndex[] = []

  autogenDisabled?: boolean

  additionalRawSchema?: string

  constructor(readonly name: string, readonly shape: TShape) {
    super(shape)
  }

  extend<TExtendedShape extends SchemaRawShape>(shape: TExtendedShape) {
    const newModel = new SchemaModel(this.name, { ...this.shape, ...shape })

    newModel.id = this.id
    newModel.indexes.push(...this.indexes)

    return newModel
  }

  /** Explicitly specify the PK of the model, if not specified convention is to use 'id' field */
  withId<IdField extends string & keyof TShape>(fields: IdField | IdField[]): this {
    if (this.id) throw new Error(`Id already defined as ${this.id}`)

    this.id = [...fields]
    return this
  }

  /** Add an index to the model */
  withIndex<TIndexField extends SchemaIndexFieldOf<TShape>>(
    fields: TIndexField[],
    options?: SchemaIndexOptions,
  ): this {
    return this.addIndexes(fields, false, options)
  }

  /** Add a unique index to the model */
  withUniqueIndex<TIndexField extends SchemaIndexFieldOf<TShape>>(
    fields: TIndexField[],
    options?: SchemaIndexOptions,
  ): this {
    return this.addIndexes(fields, true, options)
  }

  withCreatedAt(): this {
    if (this.shape.createdAt) throw new Error("createdAt field already defined")

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    const shapeAsAny = this.shape as any
    shapeAsAny.createdAt = s.date().defaultNow_UNIMPLEMENTED()

    return this
  }

  withUpdatedAt(): this {
    if (this.shape.updatedAt) throw new Error("updatedAt field already defined")

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    const shapeAsAny = this.shape as any
    shapeAsAny.updatedAt = s.date().updatedAt()

    return this
  }

  withCreatedAtUpdatedAt(): this {
    return this.withCreatedAt().withUpdatedAt()
  }

  /**
   * WARNING: this is a hack escape hatch meant to ease the v1 -> v2 transition. You should not be using this to
   * implement long-term functionality.
   */
  withAdditionalRawSchema(rawSchema: string): this {
    if (this.additionalRawSchema) throw new Error("Additional raw schema already defined!")
    this.additionalRawSchema = rawSchema
    return this
  }

  /** disable auto generation of this schema */
  disableAutogeneration() {
    this.autogenDisabled = true
  }

  private addIndexes<TIndexField extends SchemaIndexFieldOf<TShape>>(
    fields: TIndexField[],
    unique: boolean,
    options?: SchemaIndexOptions,
  ): this {
    this.indexes.push({
      fields: fields.map<SchemaIndexField>((field) =>
        typeof field === "string" ? { field } : field,
      ),
      unique,
      name: options?.name,
    })
    return this
  }
}

export class SchemaValueObject<
  TClass extends BaseValueObjectClass = BaseValueObjectClass,
  TShape extends SchemaRawShape = {},
> extends SchemaObject<TShape, ZodType<InstanceType<TClass>>> {
  constructor(readonly Constructor: TClass, object: SchemaObject<TShape>) {
    super(object.shape, z.instanceof(Constructor))
  }
}

export class SchemaIntersection<
  T extends SchemaType = SchemaObject,
  U extends SchemaType = SchemaObject,
> extends SchemaType<ZodIntersection<ZodTypeFromSchemaType<T>, ZodTypeFromSchemaType<U>>> {
  constructor(readonly left: T, readonly right: U) {
    super(z.intersection(left.zodType, right.zodType))
  }
}

export const s = {
  model: <T extends SchemaRawShape>(name: string, shape: T): SchemaModel<T> =>
    new SchemaModel(name, shape),
  boolean: () => new SchemaBoolean(z.boolean()),
  object: <T extends Record<string, SchemaType>>(obj: T): SchemaObject<T> => new SchemaObject(obj),
  // TODO [GS Rebuild] Pull the SchemaObject directly off the Constructor once we can (PLAT-2466)
  valueObject: <TClass extends BaseValueObjectClass, TShape extends SchemaRawShape>(
    Constructor: TClass,
    object: SchemaObject<TShape>,
  ) => new SchemaValueObject(Constructor, object),
  string: (): SchemaString => new SchemaString(),
  json: <T extends ZodRawShape>(jsonSchema: ZodObject<T>): SchemaJSON<T> =>
    new SchemaJSON(jsonSchema),
  uuid: (): SchemaUuid => new SchemaUuid(),
  number: (): SchemaNumber => new SchemaNumber(),
  array: <T extends SchemaType>(elementType: T): SchemaArray<T> => new SchemaArray(elementType),
  set: <T extends SchemaType>(elementType: T): SchemaSet<T> => new SchemaSet(elementType),
  date: (): SchemaDate => new SchemaDate(zodDateTimeExt),
  enum: <T extends EnumLike>(name: string, values: T) => new SchemaEnum(name, values),
  record: <TKeys extends SchemaType, TValue extends SchemaType>(
    keys: TKeys,
    values: TValue,
  ): SchemaRecord<TKeys, TValue> => new SchemaRecord(keys, values),
  union: <T extends readonly [SchemaObject, SchemaObject, ...SchemaObject[]]>(types: T) =>
    new SchemaUnion(types),
  intersection: <T extends SchemaObject, U extends SchemaObject>(left: T, right: U) =>
    new SchemaIntersection(left, right),
}

export const isSchemaArray = (type: SchemaType): type is SchemaArray => type instanceof SchemaArray

export const isSchemaSet = (type: SchemaType): type is SchemaSet => type instanceof SchemaSet

export const isSchemaBoolean = (type: SchemaType): type is SchemaBoolean =>
  type instanceof SchemaBoolean

export const isSchemaDate = (type: SchemaType): type is SchemaDate => type instanceof SchemaDate

export const isSchemaEnum = (type: SchemaType): type is SchemaEnum => type instanceof SchemaEnum

export const isSchemaModel = (type: SchemaType): type is SchemaModel => type instanceof SchemaModel

export const isSchemaValueObject = (type: SchemaType): type is SchemaValueObject =>
  type instanceof SchemaValueObject

export const isSchemaNumber = (type: SchemaType): type is SchemaNumber =>
  type instanceof SchemaNumber

export const isSchemaObject = (type: SchemaType): type is SchemaObject =>
  type instanceof SchemaObject

export const getSchemaObject = (type: SchemaType): SchemaObject | undefined =>
  Array.from(type.ancestry()).find(isSchemaObject)

export const isSchemaString = (type: SchemaType): type is SchemaString =>
  type instanceof SchemaString

export const isSchemaUrl = (type: SchemaType): type is SchemaUrl => type instanceof SchemaUrl

export const isSchemaEmail = (type: SchemaType): type is SchemaEmail => type instanceof SchemaEmail

export const isSchemaUuid = (type: SchemaType): type is SchemaUuid => type instanceof SchemaUuid

export const getSchemaUuid = (type: SchemaType): SchemaUuid | undefined =>
  Array.from(type.ancestry()).find(isSchemaUuid)

export const isSchemaJSON = <T extends ZodRawShape>(type: SchemaType): type is SchemaJSON<T> =>
  type instanceof SchemaJSON

export const isSchemaRecord = (type: SchemaType): type is SchemaRecord =>
  type instanceof SchemaRecord

export const isSchemaUnion = (type: SchemaType): type is SchemaUnion => type instanceof SchemaUnion

export const isSchemaIntersection = (type: SchemaType): type is SchemaIntersection =>
  type instanceof SchemaIntersection

export const isSchemaModifier = (type: SchemaType): type is SchemaModifierType =>
  type instanceof SchemaModifierType

export const isSchemaUnique = (type: SchemaType): type is SchemaUnique =>
  type instanceof SchemaUnique

export const getSchemaUnique = (type: SchemaType): SchemaUnique | undefined =>
  Array.from(type.ancestry()).find(isSchemaUnique)

export const isSchemaNoPersist = (type: SchemaType): type is SchemaNoPersist =>
  type instanceof SchemaNoPersist

export const getSchemaNoPersist = (type: SchemaType): SchemaNoPersist | undefined =>
  Array.from(type.ancestry()).find(isSchemaNoPersist)

export const isSchemaDebounced = (type: SchemaType): type is SchemaDebounced =>
  type instanceof SchemaDebounced

export const getSchemaDebounced = (type: SchemaType): SchemaDebounced | undefined =>
  Array.from(type.ancestry()).find(isSchemaDebounced)

export const isSchemaDefault = (type: SchemaType): type is SchemaDefault =>
  type instanceof SchemaDefault

export const getSchemaDefault = (type: SchemaType): SchemaDefault | undefined =>
  Array.from(type.ancestry()).find(isSchemaDefault)

export const isSchemaOptional = (type: SchemaType): type is SchemaOptional =>
  type instanceof SchemaOptional

export const getSchemaOptional = (type: SchemaType): SchemaOptional | undefined =>
  Array.from(type.ancestry()).find(isSchemaOptional)

export const isSchemaNullable = (type: SchemaType): type is SchemaNullable =>
  type instanceof SchemaNullable

export const getSchemaNullable = (type: SchemaType): SchemaNullable | undefined =>
  Array.from(type.ancestry()).find(isSchemaNullable)

export const isSchemaImmutable = (type: SchemaType): type is SchemaImmutable =>
  type instanceof SchemaImmutable

export const getSchemaImmutable = (type: SchemaType): SchemaImmutable | undefined =>
  Array.from(type.ancestry()).find(isSchemaImmutable)

export const unwrapSchemaModifiers = (type: SchemaType): SchemaType =>
  isSchemaModifier(type) ? unwrapSchemaModifiers(type.innerType) : type

export const getSchemaOptionalOrNullable = (
  type: SchemaType,
): SchemaOptional | SchemaNullable | undefined =>
  Array.from(type.ancestry()).find(
    (i): i is SchemaOptional | SchemaNullable => isSchemaOptional(i) || isSchemaNullable(i),
  )

export const isSchemaOptionalOrNullable = (
  type: SchemaType,
): type is SchemaOptional | SchemaNullable => isSchemaOptional(type) || isSchemaNullable(type)

export const isSchemaManyToOne = (type: SchemaType): type is SchemaManyToOne =>
  type instanceof SchemaManyToOne

export const isSchemaOneToOne = (type: SchemaType): type is SchemaOneToOne =>
  type instanceof SchemaOneToOne

export const isSchemaManyToMany = (type: SchemaType): type is SchemaManyToMany =>
  type instanceof SchemaManyToMany

export const getSchemaManyToOne = (type: SchemaType): SchemaManyToOne | undefined =>
  Array.from(type.ancestry()).find(isSchemaManyToOne)

export const getSchemaOneToOne = (type: SchemaType): SchemaOneToOne | undefined =>
  Array.from(type.ancestry()).find(isSchemaOneToOne)

export const getSchemaManyToMany = (type: SchemaType): SchemaManyToMany | undefined =>
  Array.from(type.ancestry()).find(isSchemaManyToMany)

export const getSchemaRelation = (
  type: SchemaType,
): SchemaManyToOne | SchemaOneToOne | SchemaManyToMany | undefined =>
  getSchemaManyToOne(type) ?? getSchemaOneToOne(type) ?? getSchemaManyToMany(type)

/** Returns all the relations in a schema model */
export const getSchemaModelRelations = (type: SchemaModel) => {
  const result: [
    fieldName: string,
    relation: SchemaManyToOne | SchemaOneToOne | SchemaManyToMany,
  ][] = []
  objectEntries(type.shape).map(([fieldName, field]) => {
    const relation = getSchemaRelation(field)
    if (relation) {
      result.push([fieldName, relation])
    }
  })
  return result
}

/** Returns the minimum debounce interval given a schema model and partial data */
export const getSchemaObjectDebounceInterval = <T extends AnySchemaObject>(
  type: T,
  data: PartialDeep<SchemaInfer<T>> & Record<string, SchemaInfer<T>[string]>,
  defaultInterval?: number,
): number | undefined => {
  let debounceInterval = getSchemaDebounced(type)?.debounceMs

  const combineInterval = (newInterval: number | undefined) => {
    debounceInterval = isNil(newInterval)
      ? defaultInterval
      : isNotNil(debounceInterval)
      ? Math.min(debounceInterval, newInterval)
      : newInterval
  }

  objectEntries(type.shape).forEach(([fieldName, field]) => {
    if (!(fieldName in data)) return
    const debounceModifier = getSchemaDebounced(field)
    const primitive = field.getPrimitiveSchemaType()
    if (isSchemaObject(primitive)) {
      const nestedDebounce = getSchemaObjectDebounceInterval(
        primitive,
        data[fieldName],
        debounceModifier?.debounceMs,
      )
      combineInterval(nestedDebounce)
    } else {
      combineInterval(debounceModifier?.debounceMs ?? defaultInterval ?? 0)
    }
  })

  return debounceInterval
}

/**
 * Matches the default behavior of Prisma
 * https://www.prisma.io/docs/orm/prisma-schema/data-model/relations/referential-actions#referential-action-defaults
 * - `onDelete`: for optional relations defaults to `SetNull`
 * - `onDelete`: for mandatory relations defaults to `Restrict`
 * - `onUpdate` defaults to `Cascade`
 *
 * @param relation - The schema relation object without `onDelete` and `onUpdate` properties.
 * @param isOptionalOrNullable - A boolean indicating whether to apply default referential actions.
 * @returns The schema relation object with default referential actions applied.
 */
const getDefaultReferentialActions = (
  relation: Omit<SchemaRelation, "onDelete" | "onUpdate"> & SchemaRelationOptions,
  isOptionalOrNullable: boolean,
): SchemaRelation => ({
  ...relation,
  onDelete: relation.onDelete ?? (isOptionalOrNullable ? "SetNull" : "Restrict"),
  onUpdate: relation.onUpdate ?? "Cascade",
})
