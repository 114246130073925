import SearchIcon from "@mui/icons-material/Search"
import { SelectChangeEvent } from "@mui/material"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import InputAdornment from "@mui/material/InputAdornment"
import TablePagination from "@mui/material/TablePagination"
import TextField from "@mui/material/TextField"
import TabWrapper from "components/dashboard/TabWrapper"
import MultipleSelectChip from "components/inputs/MultipleSelectChip"
import { applyPagination, applySort } from "features/tables/utils"
import { useUserSpaces } from "features/users/hooks"
import React, { ChangeEvent, FC, MouseEvent, useCallback, useMemo, useState } from "react"
import { isCaseInsensitiveMatch } from "utils/filterLogic"

import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { CoreRoleTypePrisma, SpacePrisma } from "gather-prisma-types/dist/src/public/client"
import UserSpacesTable from "./UserSpacesTable"

interface Props {
  userId?: string // confusing name, this is a firebaseAuthId
}

interface SpaceRole {
  [x: Uuid]: CoreRoleTypePrisma
}

const JoinedSpacesTab: FC<Props> = ({ userId }) => {
  const spacesQuery = useUserSpaces(userId)
  const userRoles: SpaceRole = useMemo(() => spacesQuery.data?.userRoles || {}, [spacesQuery])
  const allSpaces: SpacePrisma[] = useMemo(() => spacesQuery.data?.spaces || [], [spacesQuery])
  const [sort, setSort] = useState("name|asc") // Name (asc) will be the default option
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(5)
  const [idQuery, setIdQuery] = useState<string>("")
  const [selectedRoles, setSelectedRoles] = useState<CoreRoleTypePrisma[]>([])

  const toggleSortOrder = useCallback(() => {
    const currentSortAsc = sort === "name|asc"

    if (currentSortAsc) {
      setSort("name|desc")
    } else {
      setSort("name|asc")
    }
  }, [setSort, sort])

  const handleSelectedRolesChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    const result =
      typeof value === "string"
        ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (value.split(",") as CoreRoleTypePrisma[])
        : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (value as CoreRoleTypePrisma[]) // On autofill, we get a stringified value.
    setSelectedRoles(result)
  }

  const handleIdQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIdQuery(event.target.value)
  }

  const handlePageChange = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setCurrentPage(page)
    },
    [setCurrentPage],
  )

  const handleLimitChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLimit(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [setLimit, setCurrentPage],
  )

  const applyFilters = useCallback(
    (query: string, spaces: SpacePrisma[]) =>
      spaces.filter((space: SpacePrisma) => {
        let matches = true

        if (query) {
          matches =
            isCaseInsensitiveMatch(space.name, query) || isCaseInsensitiveMatch(space.id, query)
        }

        if (selectedRoles.length > 0 && userRoles[space.id]) {
          matches = matches && selectedRoles.some((r) => userRoles[space.id]?.includes(r))
        }

        return matches
      }),
    [selectedRoles, userRoles],
  )

  const filteredSpaces: SpacePrisma[] = useMemo(
    () => applyFilters(idQuery, allSpaces),
    [idQuery, allSpaces, applyFilters],
  )

  const spacesToDisplay: SpacePrisma[] = useMemo(() => {
    const sortedSpaces = applySort(filteredSpaces, sort)
    const paginatedSpaces = applyPagination<SpacePrisma>(sortedSpaces, currentPage, limit)
    return paginatedSpaces
  }, [filteredSpaces, currentPage, limit, sort])

  return (
    <TabWrapper>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          mb: 1,
          p: 2,
        }}
      >
        <Box
          sx={{
            width: 400,
            ml: -1,
            mr: 2,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleIdQueryChange}
            placeholder="Search by Space Name or ID"
            value={idQuery}
            variant="outlined"
          />
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <MultipleSelectChip
            options={Object.values(CoreRoleTypePrisma).sort()}
            value={selectedRoles}
            onChange={handleSelectedRolesChange}
            label="Filter by Roles"
          />
        </Box>
      </Box>

      {spacesQuery.data ? (
        <Card>
          <UserSpacesTable
            spaces={spacesToDisplay}
            roles={userRoles}
            onToggleSort={toggleSortOrder}
            currentSortAsc={sort === "name|asc"}
            userId={userId}
          />
          <TablePagination
            component="div"
            count={filteredSpaces.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={currentPage}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </Card>
      ) : (
        <Box sx={{ p: 2 }}>Loading...</Box>
      )}
    </TabWrapper>
  )
}

export default JoinedSpacesTab
