import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import {
  CatalogItemIncludeDownVariantsResource,
  CatalogItemResource,
  CatalogItemVariantResource,
} from "gather-common/dist/src/public/v2/resources/catalogItems"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { CatalogItemVariantPrisma } from "gather-prisma-types/dist/src/public/client"

export const listCatalogItems = async (): Promise<CatalogItemIncludeDownVariantsResource[]> =>
  tsrAPI.admin.catalogItems.list({})

export const listCatalogItemVariants = async (
  catalogItemId: Uuid,
): Promise<CatalogItemVariantResource[]> =>
  tsrAPI.admin.catalogItems.variants.list({ params: { catalogItemId } })

export const updateCatalogItemVariant = async (
  catalogItemVariantId: Uuid,
  body: Pick<CatalogItemVariantPrisma, "isPublished">,
): Promise<CatalogItemVariantResource> =>
  tsrAPI.admin.catalogItems.variants.update({ params: { catalogItemVariantId }, body })

export const updateCatalogItem = async (id: Uuid, order: number): Promise<CatalogItemResource> =>
  tsrAPI.admin.catalogItems.update({ params: { catalogItemId: id }, body: { order } })
