import { z } from "zod"

import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { ModelSchema } from "../Model"

// A patch path is delimited by "/" and must have at least one character ("path param") in between each delimiter.
// Special chars "/" and "~" within path params are escaped (during encoding/decoding).
export type PatchPath = `/${string}`

const topLevelPathRegex = /^\/[^/]+$/

// Returns whether a path is a top-level path (exactly one path param)
export const isTopLevelPath = (path: PatchPath) => topLevelPathRegex.test(path)

interface BasePatch<TModelKey extends string> {
  op: string
  model: TModelKey
  data?: unknown
}

export interface AddModelPatch<TModelKey extends string, TSchema extends ModelSchema>
  extends BasePatch<TModelKey> {
  op: "addmodel"
  data: z.infer<TSchema>
}

export interface DeleteModelPatch<M extends string> extends BasePatch<M> {
  op: "deletemodel"
  id: Uuid
}

export interface AddPatch<TModelKey extends string> extends BasePatch<TModelKey> {
  op: "add"
  id: Uuid
  path: PatchPath
  data: unknown
}

export interface ReplacePatch<TModelKey extends string> extends BasePatch<TModelKey> {
  op: "replace"
  id: Uuid
  path: PatchPath
  data: unknown
}

export interface DeletePatch<TModelKey extends string> extends BasePatch<TModelKey> {
  op: "delete"
  id: Uuid
  path: PatchPath
}

// All possible types of patches
export type Patch<TModelKey extends string, TSchema extends ModelSchema> =
  | AddModelPatch<TModelKey, TSchema>
  | ModelScopedPatch<TModelKey>
  | DeleteModelPatch<TModelKey>

export type ModelScopedPatch<TModelKey extends string> =
  | ReplacePatch<TModelKey>
  | AddPatch<TModelKey>
  | DeletePatch<TModelKey>

export type PatchOp = Patch<string, ModelSchema>["op"]
export type ModelScopedPatchOp = ModelScopedPatch<string>["op"]

export interface EncodedAddModelPatch<TModelKey extends string, TData = unknown>
  extends BasePatch<TModelKey> {
  op: "addmodel"
  data: TData
}

/**
 * All possible types of _encoded_ patches.
 *
 * Models can optionally supply custom encoders/decoders for their patches for transport.
 */
export type EncodedPatch<TModelKey extends string, TAddModelData = unknown> =
  // All encoded patch types except AddModel share the same types as their non-encoded counterparts (for now)
  | Exclude<Patch<TModelKey, ModelSchema>, AddModelPatch<TModelKey, ModelSchema>>
  // Encoded AddModel patches won't necessarily provide data matching the model schema
  | EncodedAddModelPatch<TModelKey, TAddModelData>

// Asserts that a path param is valid (non-empty)
function assertValidPathParam(pathParam: string) {
  if (pathParam.length === 0) throw new Error(`Path params cannot be empty!`)
}

/**
 * Encodes a path param by escaping special chars "/" and "~".
 *
 * @throws If the path param is invalid (empty)
 */
export function encodePatchPathParam(pathParam: string) {
  assertValidPathParam(pathParam)
  return pathParam.replace(/~/g, "~0").replace(/\//g, "~1")
}

/**
 * Decodes a path param by un-escaping special chars "/" and "~".
 *
 * @throws If the path param is invalid (empty)
 */
export function decodePatchPathParam(pathParam: string) {
  assertValidPathParam(pathParam)
  return pathParam.replace(/~1/g, "/").replace(/~0/g, "~")
}

/**
 * Converts a path into an ordered list of decoded path params
 *
 * @throws If the path is invalid (e.g. any path param is empty)
 */
export function splitPatchPath(path: PatchPath) {
  // Special case the root path (which split()s to ["", ""])
  if (path === "/") return []
  return path.split("/").slice(1).map(decodePatchPathParam)
}

/**
 * Converts the ordered list of path params into a path
 *
 * @throws If any path param is invalid (empty)
 */
export function joinPatchPaths(pathParams: string[]): PatchPath {
  return `/${pathParams.map(encodePatchPathParam).join("/")}`
}

/**
 * Appends a path param to a path.
 *
 * @throws If the path param is invalid (empty)
 */
export function appendPatchPath(path: PatchPath, pathParam: string | number): PatchPath {
  return `${path}${path === "/" ? "" : "/"}${encodePatchPathParam(`${pathParam}`)}`
}
