import { EnumLike } from "zod"

import { BaseSuperClass, ModelFactory } from "gather-state-sync/dist/src/public/Model"
import { EncodedPatch } from "gather-state-sync/dist/src/public/patches/patches"
import { ModelKeys } from "../../modelKeys"
import {
  getSchemaImmutable,
  SchemaImmutable,
  SchemaModel,
  SchemaNoPersist,
  SchemaRawShape,
  SchemaType,
  SchemaUuid,
} from "../schema/schema"

type MaybeNoPersist<T extends SchemaType> = T | SchemaNoPersist<T>
type MaybeImmutable<T extends SchemaType> = T | SchemaImmutable<T>

type BaseBaseModelSchema<TShape extends SchemaRawShape = { id: SchemaUuid }> = SchemaModel<TShape>
export type BaseModelSchema<TShape extends SchemaRawShape = { id: SchemaUuid }> = MaybeImmutable<
  MaybeNoPersist<BaseBaseModelSchema<TShape>>
>

type SchemaIsImmutable<T extends BaseModelSchema> = T extends SchemaImmutable<infer _>
  ? true
  : false

export type UnwrapBaseModelModifiers<T extends SchemaType> = T extends SchemaImmutable<infer U>
  ? UnwrapBaseModelModifiers<U>
  : T extends SchemaNoPersist<infer U>
  ? UnwrapBaseModelModifiers<U>
  : T

export function unwrapBaseModelModifiers<T extends SchemaType>(
  schema: T,
): UnwrapBaseModelModifiers<T>
export function unwrapBaseModelModifiers<_T extends SchemaType>(schema: undefined): undefined
export function unwrapBaseModelModifiers<T extends SchemaType>(
  schema: T | undefined,
): UnwrapBaseModelModifiers<T> | undefined
export function unwrapBaseModelModifiers<T extends SchemaType>(
  schema: T | undefined,
): UnwrapBaseModelModifiers<T> | undefined {
  if (schema instanceof SchemaImmutable) return unwrapBaseModelModifiers(schema.innerType)
  if (schema instanceof SchemaNoPersist) return unwrapBaseModelModifiers(schema.innerType)
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return schema as UnwrapBaseModelModifiers<T>
}

/**
 * This is our Gather-specific flavor of the generic Model from `gather-state-sync`.
 * ALWAYS use this instead of `Model`!
 *
 * Notably, it:
 *   - enforces use of `ModelKeys`
 *   - requires an `s.model()` schema instead of a Zod schema
 */
export function BaseModel<
  TModelKey extends ModelKeys,
  TSelfSchema extends BaseModelSchema,
  TSuperClass extends BaseSuperClass = BaseSuperClass,
>(modelKey: TModelKey, schema: TSelfSchema, superClass?: TSuperClass) {
  return ModelFactory<
    TModelKey,
    TSelfSchema["modelZod"],
    TSuperClass,
    EncodedPatch<TModelKey>,
    EnumLike,
    SchemaIsImmutable<TSelfSchema>
  >(modelKey, schema.modelZod, {
    superClass,
    // We're relying on the typing of SchemaIsImmutable<> to match the behavior of
    // `getSchemaImmutable()` here.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    immutable: !!getSchemaImmutable(schema) as SchemaIsImmutable<TSelfSchema>,
  })
}

// TODO [GS Rebuild] Support `ModelWithCustomPatch` here once we need it
