import { getFixedClassRef } from "gather-common/dist/src/public/getFixedClassRef"
import { ga } from "gather-common/dist/src/public/mobx/decorators"
import { BaseValueObject } from "gather-state-sync/dist/src/public/BaseValueObject"
import { s, SchemaInfer } from "../framework/schema/schema"
import { EnumKeys } from "../modelKeys"

export enum MoveDirection {
  Up = "Up",
  Down = "Down",
  Left = "Left",
  Right = "Right",
}

export const directionSchema = s.object({
  value: s.enum(EnumKeys.Direction, MoveDirection).default_UNIMPLEMENTED(MoveDirection.Down),
})

type Data = SchemaInfer<typeof directionSchema>

@ga.observableClass
export class Direction extends BaseValueObject(directionSchema.modelZod) {
  constructor(data: Data)
  constructor(direction: MoveDirection)
  constructor(directionOrData: MoveDirection | Data) {
    super(typeof directionOrData === "string" ? { value: directionOrData } : directionOrData)
  }

  static keyToMoveDirection(e: KeyboardEvent): MoveDirection | null {
    if (e.code === "ArrowLeft" || e.code === "KeyA") {
      return MoveDirection.Left
    } else if (e.code === "ArrowRight" || e.code === "KeyD") {
      return MoveDirection.Right
    } else if (e.code === "ArrowUp" || e.code === "KeyW") {
      return MoveDirection.Up
    } else if (e.code === "ArrowDown" || e.code === "KeyS") {
      return MoveDirection.Down
    }
    return null
  }

  toPositionDelta() {
    return this.value === MoveDirection.Up
      ? { x: 0, y: -1 }
      : this.value === MoveDirection.Down
      ? { x: 0, y: 1 }
      : this.value === MoveDirection.Left
      ? { x: -1, y: 0 }
      : this.value === MoveDirection.Right
      ? { x: 1, y: 0 }
      : { x: 0, y: 0 }
  }

  toOppositeDirection(): Direction {
    const Direction_ = getFixedClassRef(this, Direction)
    switch (this.value) {
      case MoveDirection.Left:
        return new Direction_(MoveDirection.Right)
      case MoveDirection.Right:
        return new Direction_(MoveDirection.Left)
      case MoveDirection.Up:
        return new Direction_(MoveDirection.Down)
      case MoveDirection.Down:
        return new Direction_(MoveDirection.Up)
    }
  }

  toLeftDirection(): Direction {
    const Direction_ = getFixedClassRef(this, Direction)
    switch (this.value) {
      case MoveDirection.Up:
        return new Direction_(MoveDirection.Left)
      case MoveDirection.Right:
        return new Direction_(MoveDirection.Up)
      case MoveDirection.Down:
        return new Direction_(MoveDirection.Right)
      case MoveDirection.Left:
        return new Direction_(MoveDirection.Down)
    }
  }
}
