import { ga } from "gather-common/dist/src/public/mobx/decorators"
import { BaseValueObject } from "gather-state-sync/dist/src/public/BaseValueObject"
import { s, SchemaInfer } from "../framework/schema/schema"
import { EnumKeys } from "../modelKeys"

export enum Availability {
  Active = "Active",
  Focused = "Focused", // Green headphones
  Busy = "Busy", // Orange headphones
  Away = "Away",
  Offline = "Offline",
}

export const spaceUserAvailabilitySchema = s.object({
  value: s.enum(EnumKeys.Availability, Availability).default_UNIMPLEMENTED(Availability.Offline),
})

type Data = SchemaInfer<typeof spaceUserAvailabilitySchema>

@ga.observableClass
export class SpaceUserAvailability extends BaseValueObject(spaceUserAvailabilitySchema.modelZod) {
  constructor(data: Data)
  constructor(availability: Availability)
  constructor(availabilityOrData: Availability | Data) {
    super(
      typeof availabilityOrData === "string" ? { value: availabilityOrData } : availabilityOrData,
    )
  }

  /**
   * Warning: Most of the time you do not need to use this directly!
   * Use SpaceUser#isFocused instead to account for whether the user is connected to the GS
   */
  get _isFocused() {
    return this.value === Availability.Focused
  }

  /**
   * Warning: Most of the time you do not need to use this directly!
   * Use SpaceUser#isBusy instead to account for whether the user is connected to the GS
   */
  get _isBusy() {
    return this.value === Availability.Busy
  }

  /**
   * Warning: Most of the time you do not need to use this directly!
   * Use SpaceUser#isAway instead to account for whether the user is connected to the GS
   */
  get _isAway() {
    return this.value === Availability.Away
  }

  /**
   * Warning: Most of the time you do not need to use this directly!
   * Use SpaceUser#isActive instead to account for whether the user is connected to the GS
   */
  get _isActive() {
    return this.value === Availability.Active
  }

  /**
   * Warning: Most of the time you do not need to use this directly!
   * Use SpaceUser#isOffline instead to account for whether the user is connected to the GS
   */
  get _isOffline() {
    return this.value === Availability.Offline
  }

  /**
   * Warning: Most of the time you do not need to use this directly!
   * Use SpaceUser#isOffline instead to account for whether the user is connected to the GS
   */
  get _isInOffice() {
    return this._isActive || this._isFocused || this._isBusy
  }
}
