import { always } from "ramda"

import { mapReduceKeysIntoObject } from "gather-common-including-video/dist/src/public/fpHelpers"
import { getMobxAutoDataObservables } from "./getMobxAutoDataObservables"
import { type AnnotationsMap, makeObservable, observable } from "./mobx-utils"

// The key where we store the annotations cache on class prototypes
const ANNOTATIONS_CACHE_SYMBOL = Symbol("AUTO_DATA_OBSERVABLES")

const withAnnotationsCache = <T>(obj: T) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  obj as T & { [ANNOTATIONS_CACHE_SYMBOL]: AnnotationsMap<T, never> }

/**
 * Marks all non-function _data_ properties on `obj` as mobx `observable`.
 * See `getMobxAutoDataObservables` for more details on what properties are included.
 *
 * WARNING: This function will cache the annotations on the prototype chain of `obj` if it's a class instance!
 * This means the object's auto-data-observable properties and `excludes` must be static for the class.
 *
 * @param obj An object or class instance
 * @param excludes An optional array of keys to exclude from being made auto-observable
 */
export const makeDataAutoObservable = <T extends object>(
  obj: T,
  excludes?: Set<string | symbol>,
): T => {
  let annotations: AnnotationsMap<T, never>

  // Pull the annotations cache for the direct class of `obj`, if available.
  // Superclass annotations caches may not be correct.
  if (Object.hasOwn(Object.getPrototypeOf(obj), ANNOTATIONS_CACHE_SYMBOL)) {
    annotations = withAnnotationsCache(obj)[ANNOTATIONS_CACHE_SYMBOL]
  } else {
    // Generate and cache the annotations for this class
    const dataKeys = getMobxAutoDataObservables(obj)
    annotations = {
      ...mapReduceKeysIntoObject(dataKeys, always(observable)),
      ...(excludes ? mapReduceKeysIntoObject(Array.from(excludes), () => false) : undefined),
    }

    // Cache the annotations on the prototype chain, if this is a class instance
    const proto = Object.getPrototypeOf(obj)
    if (proto && proto !== Object.prototype) {
      Object.defineProperty(proto, ANNOTATIONS_CACHE_SYMBOL, { value: annotations })
    }
  }

  return makeObservable(obj, annotations)
}
