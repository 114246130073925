import { getAndAssertSpaceIdFromPath, getSpaceIdFromPath } from "./pathUtils"

/**
 * TODO [Rebuild]: create a repository to store the spaceId state shared between the space, space dashboard, and mapmaker, and move these functions to that repository.
 * There were some concerns that this would make the URL unwieldy and long, and there were also suggestions that we might want to be able
 * to assign custom url paths, which would require a db lookup / layer of logic to get from the space identifier in the url to the space
 * UUID. We’re planning to do this in a way that affords us the flexibility to change the URL format in the future. Following is our thought
 * process and plan:
 *  - We shouldn’t be reading the spaceId directly from the url every time we need it. We’re planning to create a space repository, read the
 *   space identifier, transform it to the space UUID once, and store it in the repository. Then, all further access to space UUID can be via
 *   a getter method on the repository.
 *  - This should be a shared repository between the space, space dashboard, mapmaker.
 *    react-native has its own way of reading spaceId, where it sets up the spaceId in a React Context once and then reads from that context
 *    every time. Right now, react-native only relies on getting the spaceId from within React Context, so we don’t currently have a need for
 *    extending this repository to react-native.
 */
export const getCurrentSpaceId = () => getAndAssertSpaceIdFromPath()

export const getCurrentSpaceIdOrNull = () => getSpaceIdFromPath()
