import { useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query"

import { asUuid } from "gather-common/dist/src/public/stringHelpers"
import * as WearablesAPI from "../../api/wearables"

/**
 * Fetches all wearables
 * @returns ReactQuery object where data is type of Wearable | undefined
 */
export const useWearables = (): UseQueryResult<
  Awaited<ReturnType<typeof WearablesAPI.listWearables>>
> =>
  useQuery("wearables", WearablesAPI.listWearables, {
    initialData: [],
    retry: false,
  })

export const useUpdateWearable = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, order }: { id: string; order: number }) =>
      WearablesAPI.updateWearable(asUuid(id), order),
    onSuccess: () => {
      queryClient.invalidateQueries("wearables")
    },
  })
}
