export const TILE_SIZE = 32
export const HALF_TILE_SIZE = TILE_SIZE / 2

/**
 * Maximum number of entities that can be placed on a single map. This is mostly a soft limit aimed
 * at preventing performance issues.
 */
export const MAX_ENTITIES_PER_MAP = 32_000

/**
 * Maximum depth of the hierarchy of map entities.
 */
export const MAX_HIERARCHY_DEPTH = 20
