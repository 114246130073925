export { PrismaClient, Prisma as PrismaTypes } from "./prismaGenerated_DO_NOT_TOUCH"

import type { BrowserVideoClientStateSession } from "./prismaGenerated_DO_NOT_TOUCH"

/**
 * Exported types
 * ===============
 *
 * Suffix with `*Prisma*` to disambiguate from the gather-game-logic model classes.
 */
export type {
  BrowserVideoClientStateSession as BrowserVideoClientStateSessionPrisma,
  CatalogItem as CatalogItemPrisma,
  CatalogItemVariant as CatalogItemVariantPrisma,
  FloorMap as FloorMapPrisma,
  Floor as FloorPrisma,
  MapArea as MapAreaPrisma,
  MapEntityIdentifier as MapEntityIdentifierPrisma,
  MapObject as MapObjectPrisma,
  PermissionsGroup as PermissionsGroupPrisma,
  PermissionsGroupUser as PermissionsGroupUserPrisma,
  PermissionsRole as PermissionsRolePrisma,
  Space as SpacePrisma,
  SpaceRoleAssignment as SpaceRoleAssignmentPrisma,
  SpaceSettings as SpaceSettingsPrisma,
  SpaceSSOEmailDomain as SpaceSSOEmailDomainPrisma,
  SpaceSSOLinkedSpace as SpaceSSOLinkedSpacePrisma,
  SpaceSSOSettings as SpaceSSOSettingsPrisma,
  SpaceTemplate as SpaceTemplatePrisma,
  SpaceUser as SpaceUserPrisma,
  SpaceWorkOSConnection as SpaceWorkOSConnectionPrisma,
  UserAccount as UserAccountPrisma,
  UserAccountSecrets as UserAccountSecretsPrisma,
  UserDeletionForStaging as UserDeletionForStagingPrisma,
  UserFeedback as UserFeedbackPrisma,
  UserFile as UserFilePrisma,
  WearablePart as WearablePartPrisma,
  Wearable as WearablePrisma,
} from "./prismaGenerated_DO_NOT_TOUCH"

/**
 * Exported enums
 * ===============
 *
 * You need to export as a const, otherwise you can't access it as a value.
 */
export {
  AdminRole as AdminRolePrisma,
  AnimationType as AnimationTypePrisma,
  CatalogItemOrientation as CatalogItemOrientationPrisma,
  CoreRoleType as CoreRoleTypePrisma,
  DeskType as DeskTypePrisma,
  MeetingParticipantInviteStatus as MeetingParticipantInviteStatusPrisma,
  MeetingParticipantResponseStatus as MeetingParticipantResponseStatusPrisma,
  MeetingType as MeetingTypePrisma,
  MeetingVisibility as MeetingVisibilityPrisma,
  OfficeStyle as OfficeStylePrisma,
  Permission as PermissionPrisma,
  PermissionsRoleType as PermissionsRoleTypePrisma,
  Prisma,
  SpaceSSOConnectionState as SpaceSSOConnectionStatePrisma,
  SpaceSSOEmailDomainVerificationState as SpaceSSOEmailDomainVerificationStatePrisma,
  UserInvitationStatus as UserInvitationStatusPrisma,
  WearableLayer as WearableLayerPrisma,
  WearableType as WearableTypePrisma,
} from "./prismaGenerated_DO_NOT_TOUCH"

/**
 * Other specialized types
 * =======================
 *
 * For any other custom Prisma-related type definitions. It may be better for them to live
 * colocated with that domain's code, though.
 */

export type BrowserVideoClientStateSessionIndexPrisma = Pick<
  BrowserVideoClientStateSession,
  | "id"
  | "avStrategy"
  | "commitHash"
  | "buildTimestamp"
  | "createdAt"
  | "electron"
  | "message"
  | "userAccountId"
  | "spaceId"
  | "updatedAt"
  | "userAgentBrowser"
  | "userAgentOs"
  | "userAgentVersion"
  | "sessionId"
  | "liveKitSessionId"
  | "liveKitParticipantId"
  | "agoraVideoId"
  | "agoraScreenId"
  | "agoraVideoChannelName"
  | "agoraScreenChannelName"
  | "spaceUserId"
>
