import { action, flow } from "../mobx-utils"
import { KeepAliveComputed } from "./computed"

export const ObservableClassInternalSymbol = Symbol("ObservableClassInternal")

// The shape of the internal data stored by our `@ga` decorators on class prototypes.
export type ObservableClassInternalData = {
  readonly annotations: Record<
    string | symbol,
    typeof action | typeof action.bound | KeepAliveComputed | typeof flow | typeof flow.bound
  >
  computeds?: Set<string | symbol>
  // TODO [GS Rebuild] @vic add flag for "done" to prevent extraneous init work
}

const initObsClassInternalData = (): ObservableClassInternalData => ({
  annotations: {},
})

/**
 * `ga.observableClass` relies on this function to get or create the internal data for a class.
 */
export const getOrCreateObsClassInternalData = (obj: unknown): ObservableClassInternalData => {
  const prototype = Object.getPrototypeOf(obj)
  // check if the prototype itself has an internals object yet, we don't want to use the parent's
  if (!prototype.hasOwnProperty(ObservableClassInternalSymbol)) {
    prototype[ObservableClassInternalSymbol] = initObsClassInternalData()
  }
  return prototype[ObservableClassInternalSymbol]
}
