import {
  Box,
  Button,
  Card,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import ChipList from "components/dashboard/ChipList"
import TabWrapper from "components/dashboard/TabWrapper"
import MultipleSelectChip from "components/inputs/MultipleSelectChip"
import useRoles from "features/roles/useRoles"
import { FC, useCallback, useEffect, useState } from "react"
import { toast } from "react-hot-toast"

import { AdminPermission } from "gather-admin-common/dist/src/public/roles/types"
import { guaranteedError } from "gather-common/dist/src/public/utils"
import { AdminRolePrisma } from "gather-prisma-types/dist/src/public/client"
import { updateSuperAdminUser } from "../../../../api/superAdminUsers"
import { listAdminUserRoles } from "../../../../api/superAdminUsers/roles"

interface Props {
  userId?: string // confusing name, this is a firebaseAuthId
}

const UserRolesTab: FC<Props> = ({ userId }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [roles, setRoles] = useState<AdminRolePrisma[]>([])
  const [selectedRoles, setSelectedRoles] = useState<AdminRolePrisma[]>([])
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { permissions } = useRoles()
  const canManageRoles = permissions.includes(AdminPermission.ManageRoles)

  const fetchUserRoles = useCallback(async () => {
    if (!userId) return

    setLoading(true)
    try {
      const userRoles = await listAdminUserRoles(userId)
      setRoles(userRoles)
      setSelectedRoles(userRoles)
    } catch (e) {
      const error = guaranteedError(e)
      console.error("Failed to fetch user roles:", error)
      toast.error(`Failed to fetch user roles: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }, [userId])

  const handleSaveRoles = useCallback(async () => {
    if (!userId) return

    setSaving(true)
    try {
      await updateSuperAdminUser(userId, selectedRoles)
      setRoles(selectedRoles)
      setIsEditing(false)
      toast.success("User roles updated successfully")
    } catch (e) {
      const error = guaranteedError(e)
      console.error("Failed to update user roles:", error)
      toast.error(`Failed to update user roles: ${error.message}`)
    } finally {
      setSaving(false)
    }
  }, [userId, selectedRoles])

  const handleCancelEdit = useCallback(() => {
    setSelectedRoles(roles)
    setIsEditing(false)
  }, [roles])

  const handleEditRoles = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleRolesChange = useCallback((event: SelectChangeEvent<AdminRolePrisma[]>) => {
    const {
      target: { value },
    } = event

    // When using Select with multiple=true, the value is always an array
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setSelectedRoles(typeof value === "string" ? (value.split(",") as AdminRolePrisma[]) : value)
  }, [])

  useEffect(() => {
    fetchUserRoles()
  }, [fetchUserRoles])

  return (
    <TabWrapper>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
        <Box sx={{ width: "100%" }}>
          <Card>
            <Box sx={{ p: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <Typography variant="h6">User Roles</Typography>
                {canManageRoles && !isEditing ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditRoles}
                    disabled={loading}
                  >
                    Edit Roles
                  </Button>
                ) : canManageRoles && isEditing ? (
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancelEdit}
                      disabled={saving}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveRoles}
                      disabled={saving}
                    >
                      {saving ? "Saving..." : "Save"}
                    </Button>
                  </Box>
                ) : null}
              </Box>

              {loading ? (
                <Box sx={{ p: 2 }}>Loading...</Box>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Roles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {canManageRoles && isEditing ? (
                          <MultipleSelectChip
                            options={Object.keys(AdminRolePrisma).sort()}
                            value={selectedRoles}
                            onChange={handleRolesChange}
                            label="Select Roles"
                          />
                        ) : (
                          <ChipList list={roles} />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Box>
          </Card>
        </Box>
      </Box>
    </TabWrapper>
  )
}

export default UserRolesTab
