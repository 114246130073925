import { v4, v5 } from "uuid"

export { validate } from "uuid"

export type Uuid = string & { __uuid: never }

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const uuid = () => v4() as Uuid

/**
 * The V5 uuid is useful for creating a stable ID based on a namespace and a string. This is
 * essentially a hash: The id will be the same if the inputs are the same.
 */
export const uuidV5 = (namespace: Uuid, name: string) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  v5(name, namespace) as Uuid
