import { initContract } from "@ts-rest/core"

import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import { CoreRoleTypePrisma, SpacePrisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const spaces = c.router({
  list: {
    method: RequestMethod.GET,
    path: HttpV2Paths.AdminUserSpaces,
    responses: {
      200: c.type<{
        spaces: SpacePrisma[]
        userRoles: { [key: Uuid]: CoreRoleTypePrisma }
      }>(),
    },
  },
})
