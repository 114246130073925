import { initContract } from "@ts-rest/core"

import { SpaceSSOSettingsPrisma } from "gather-prisma-types/dist/src/public/client"
import { HttpV2Paths, RequestMethod } from "../../../httpAPI"

const c = initContract()

export const settings = c.router({
  get: {
    method: RequestMethod.GET,
    path: HttpV2Paths.SpaceSSOSettings,
    responses: { 200: c.type<SpaceSSOSettingsPrisma | null>() },
  },
})
