import { initContract } from "@ts-rest/core"
import { z } from "zod"

import { HttpV2Paths, RequestMethod } from "../../../../httpAPI"
import { ZodBuffer, zodUuid } from "../../../../zodHelpers"

const c = initContract()

export const floorPreview = c.router({
  upload: {
    method: RequestMethod.POST,
    path: HttpV2Paths.SpaceFloorPreview,
    pathParams: z.object({ spaceId: zodUuid, floorId: zodUuid }),
    body: z.object({
      bytes: ZodBuffer,
    }),
    responses: { 200: c.noBody() },
  },
})
